import { IconsMap } from "components/ui/Icon";
import { useTheme } from "hooks/useTheme";
import { HydratedBlock } from "types";
import Social from "./Social";

type Props = {
    block: HydratedBlock;
};
export default function SocialBlock(props: Props) {
    const { block } = props;
    const theme = useTheme();
    const { properties } = block || {};

    if (!properties?.social_links) {
        return (
            <div
                className="w-full flex justify-center items-center theme-tertiary-background p-4"
                style={{
                    borderRadius: theme.block_radius,
                }}
            >
                <IconsMap.link size={48} className="theme-secondary-text" />
            </div>
        );
    }

    return (
        <div className="flex flex-col h-full">
            <Social block={block} />
        </div>
    );
}
