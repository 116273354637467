// Uses DetatchedBox

import type { Dispatch, SetStateAction, ReactNode } from "react";

import Button from "components/ui/Button";
import DetachedBox from "components/ui/DetachedBox";
import useAutoFocus from "hooks/useAutoFocus";
import { core_palette } from "utilities/palette";

type Props = {
    isOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    onSubmit: () => void;
    error?: string;
    description?: string;
    placeholder?: string;
    title?: string;
    value: string;
    onValueChange: (value: string) => void;
    loading?: boolean;
    customComponent?: ReactNode | null;
};

export default function Dialog({
    isOpen,
    setIsModalOpen,
    onSubmit,
    description,
    placeholder,
    title,
    value,
    onValueChange,
    loading,
    error,
    customComponent,
}: Props) {
    const inputCallbackRef = useAutoFocus();

    return (
        <DetachedBox
            isOpen={isOpen}
            onClickLeft={() => {
                setIsModalOpen(false);
            }}
            leftIcon="close"
            title={title}
            fixedWidth
        >
            <div className="flex flex-col self-stretch content-between h-full">
                <div className="my-2 flex flex-col">
                    <form
                        className="flex flex-col self-stretch"
                        onSubmit={e => {
                            e.preventDefault();
                            onSubmit();
                        }}
                    >
                        <p className="my-2">{description}</p>
                        <input
                            ref={inputCallbackRef}
                            autoCorrect="off"
                            autoCapitalize="none"
                            autoFocus
                            placeholder={placeholder}
                            className="flex items-center self-stretch gap-2 px-[10px] py-3 border-2 rounded-lg text-base font-normal border-black duration-200 transition-all focus:shadow-[0_0_0_1px_rgba(0,0,0,.4)] active:shadow-[0_0_0_1px_rgba(0,0,0,.4)]"
                            value={value}
                            onChange={e => {
                                onValueChange(e.target.value);
                            }}
                        />
                    </form>
                </div>
                <p className="mt-4 mb-6">
                    {error ? (
                        <span style={{ color: core_palette.critical.light }}>
                            {error}
                        </span>
                    ) : null}
                    {customComponent}
                </p>
                <Button
                    backgroundColor={core_palette.success.light}
                    color={core_palette.inverse.light}
                    className="self-stretch rounded-lg"
                    textClassName="text-base font-medium"
                    grow={false}
                    onClick={e => {
                        e.preventDefault();
                        onSubmit();
                    }}
                    title={"Submit"}
                    icon={undefined}
                    fetching={loading}
                    disabled={loading}
                />
            </div>
        </DetachedBox>
    );
}
