// lots of page navigation logic mixed in with this component (dispatching to home page etc)
// perhaps we can clean up later
// for now, consult kevin on reasoning.
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Button from "components/ui/Button";
import Dialog from "components/ui/Dialog"; // Import Dialog component
import { useTheme } from "hooks/useTheme";
import type { CSSProperties } from "react";
import { useContext, useEffect, useState } from "react"; // Import useState
import { useSelector } from "react-redux";
import {
    selectBlockTree,
    selectProfile,
    setActivePage,
} from "store/profileSlice";
import { AppType, ButtonMode, HydratedBlock, PageType } from "types";
import Dropdown, { TabActions } from "./Dropdown";
import { useTabActions } from "../../hooks/useTabActions";
import { DragHandle } from "./DragHandle";
import { useDispatch } from "react-redux";

type Props = {
    block: HydratedBlock;
    activePageBlockId?: string;
};
export default function EditPageTab(props: Props) {
    const { block, activePageBlockId } = props;
    const theme = useTheme();

    const {
        attributes,
        isDragging,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
    } = useSortable({ id: block.id });

    const isActive = block.id === activePageBlockId;
    const pageUrl = block.properties?.page_url;
    const pageName = block.properties?.page_name;

    const tree = useSelector(selectBlockTree);
    const profile = useSelector(selectProfile);
    const name = profile?.name;
    const onlyHomePage = tree.length === 1;
    // if there is one page, show the username instead of home
    const title = onlyHomePage ? name : pageName || pageUrl || "Home";
    // do not show border of only home page
    const showBorder = isActive && !onlyHomePage;
    // State for showRenameDialog
    const [showRenameDialog, setShowRenameDialog] = useState(false);
    // State for showCustomURLDialog
    const [showCustomURLDialog, setShowCustomURLDialog] = useState(false);

    const style: CSSProperties = {
        transform: CSS.Translate.toString(transform),
        transition,
        opacity: isDragging ? 0.4 : undefined,
        borderColor: showBorder ? theme.primary : "transparent",
        boxSizing: "border-box",
    };

    const { onDelete, onClickInEditMode, onUpdateBlockProperties, getHRef } =
        useTabActions();

    const [renameValue, setRenameValue] = useState("");
    const [customUrlValue, setCustomUrlValue] = useState("");
    const [newPageUrl, setNewPageUrl] = useState("");
    const dispatch = useDispatch();

    const handleEditButtonAction = (action: TabActions) => {
        switch (action) {
            case TabActions.USE_PAGE:
                onUpdateBlockProperties({
                    block,
                    properties: {
                        page_type: PageType.PAGE,
                    },
                });
                dispatch(setActivePage(block.properties?.page_url || ""));
                break;
            case TabActions.RENAME:
                setRenameValue(pageName || "");
                setShowRenameDialog(true);
                break;
            case TabActions.USE_CUSTOM_URL:
                setShowCustomURLDialog(true);
                setCustomUrlValue(block.properties?.custom_url || "");
                break;
            case TabActions.USE_ONLINE_ORDER:
                onUpdateBlockProperties({
                    block,
                    properties: {
                        page_type: PageType.APP,
                        app_type: AppType.OnlineOrdering,
                        page_url: AppType.OnlineOrdering,
                    },
                });
                dispatch(setActivePage(AppType.OnlineOrdering));
                break;
            case TabActions.USE_CATERING:
                onUpdateBlockProperties({
                    block,
                    properties: {
                        page_type: PageType.APP,
                        app_type: AppType.Catering,
                        page_url: AppType.Catering,
                    },
                });
                dispatch(setActivePage(AppType.Catering));
                break;
            case TabActions.USE_GIFT_CARDS:
                onUpdateBlockProperties({
                    block,
                    properties: {
                        page_type: PageType.APP,
                        app_type: AppType.GiftCards,
                        page_url: AppType.GiftCards,
                    },
                });
                dispatch(setActivePage(AppType.GiftCards));
                break;
            case TabActions.USE_LOCATIONS:
                onUpdateBlockProperties({
                    block,
                    properties: {
                        page_type: PageType.APP,
                        app_type: AppType.Locations,
                        page_url: AppType.Locations,
                    },
                });
                dispatch(setActivePage(AppType.Locations));
                break;

            case TabActions.DELETE:
                onDelete(block);
                break;
            default:
                break;
        }
    };
    const _submitRename = () => {
        if (renameValue) {
            onUpdateBlockProperties({
                block,
                properties: {
                    page_name: renameValue,
                    page_url: newPageUrl,
                },
            });
            setRenameValue("");
            setShowRenameDialog(false);
        }
    };

    useEffect(() => {
        if (block.properties?.page_type === PageType.APP) {
            setNewPageUrl(block.properties?.app_type || "");
            return;
        }
        setNewPageUrl(`${renameValue.toLowerCase().split(" ").join("-")}`);
    }, [renameValue, block.properties?.page_type, block.properties?.app_type]);

    const href = getHRef(block);
    return (
        <div
            className="inline-block align-middle px-2 border-b-2 py-0 pageNavItem hover:!!none"
            key={block.id}
            ref={setNodeRef}
            style={style}
        >
            <div className="flex flex-row">
                <DragHandle
                    block={block}
                    setActivatorNodeRef={setActivatorNodeRef}
                    listeners={listeners}
                    attributes={attributes}
                />
                <Button
                    title={title}
                    noHoverEffect
                    mode={ButtonMode.ghost}
                    htmlComponent={"a"}
                    href={href}
                    style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        minWidth: 48,
                    }}
                    color={isActive ? theme.primary : theme.secondary}
                    onClick={e => {
                        onClickInEditMode(e, block);
                    }}
                />
                <Dropdown block={block} onAction={handleEditButtonAction} />
            </div>
            {showRenameDialog && (
                <Dialog
                    isOpen={showRenameDialog}
                    title="Rename Page"
                    onSubmit={_submitRename}
                    value={renameValue}
                    onValueChange={setRenameValue}
                    description="New name:"
                    setIsModalOpen={isOpen => setShowRenameDialog(isOpen)}
                    customComponent={
                        <>
                            {block.properties?.page_type === PageType.APP ? (
                                <>
                                    The url for your Snackpass app is{" "}
                                    <strong>
                                        /{block.properties?.app_type}
                                    </strong>
                                </>
                            ) : (
                                <>
                                    Your new url will be{" "}
                                    <strong>/{newPageUrl}</strong>
                                </>
                            )}
                        </>
                    }
                />
            )}
            {showCustomURLDialog && (
                <Dialog
                    value={customUrlValue}
                    isOpen={showCustomURLDialog}
                    title="Set Custom URL"
                    onSubmit={() => {
                        onUpdateBlockProperties({
                            block,
                            properties: {
                                custom_url: customUrlValue,
                                page_type: PageType.URL,
                            },
                        });
                        setCustomUrlValue("");
                        setShowCustomURLDialog(false);
                        // go back to home
                        dispatch(setActivePage(""));
                    }}
                    onValueChange={setCustomUrlValue}
                    setIsModalOpen={isOpen => setShowCustomURLDialog(isOpen)}
                />
            )}
        </div>
    );
}
