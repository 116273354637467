import NextHead from "next/head";
import Script from "next/script";
import { Profile } from "types";
import {
    actions,
    CloudinaryImage,
    isCloudinaryImage,
    resize,
} from "utilities/cloudinaryUtils";

type Props = {
    profile?: Profile | null;
};

export default function Head(props: Props) {
    const { profile } = props;

    if (!profile) {
        return null;
    }
    const favIconUrl = isCloudinaryImage(profile.profile_url)
        ? CloudinaryImage(profile.profile_url)
              .roundCorners(actions.roundCorners.max())
              .resize(resize.fill().width(100).height(100))
              .format("png")
              .toURL()
        : profile.profile_url;
    const ogImageOptions = profile.cover_url || profile.profile_url;
    const ogImageUrl = isCloudinaryImage(ogImageOptions)
        ? CloudinaryImage(ogImageOptions)
              .resize(resize.fill().width(1200).height(630))
              .toURL()
        : ogImageOptions;
    return (
        <NextHead>
            <title>{profile.name}</title>
            <meta name="description" content={profile.description} />
            <meta property="og:image" content={ogImageUrl} />
            <meta property="og:title" content={profile.name} />
            <meta property="og:description" content={profile.description} />
            <link
                rel="apple-touch-icon"
                href={profile.profile_url || profile.cover_url}
            />
            <meta name="apple-mobile-web-app-title" content={profile.name} />
            <meta
                name="apple-mobile-web-app-status-bar-style"
                content="default"
            />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="mobile-web-app-capable" content="yes" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0, viewport-width=cover"
            />
            <link rel="icon" href={favIconUrl} />
            <Script
                src="https://upload-widget.cloudinary.com/global/all.js"
                strategy="lazyOnload"
            />
        </NextHead>
    );
}
