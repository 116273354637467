interface Props {
    videoId: string;
}

const YouTubeEmbed = ({ videoId }: Props) => (
    <iframe
        src={`https://youtube.com/embed/${videoId}?rel=0`}
        width="100%"
        height="100%"
    ></iframe>
);

export default YouTubeEmbed;
