import Button from "components/ui/Button";
import { IconsMap } from "components/ui/Icon";
import { useUITheme } from "hooks/useUITheme";
import { ChangeEvent, useCallback, useEffect } from "react";
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from "react-beautiful-dnd";
import { FormField, FormFieldType } from "types";
import { cleanName } from "utilities/cleanName";
import BlockEditorDialog from "../../BlockEditorDialog";
import FormFieldOptionRow from "./FormFieldOptionRow";
import UIButton from "components/ui/UIButton";

interface Props {
    show: boolean;
    onHide: () => void;
    field?: FormField;
    onChange: (field: FormField) => void;
}

const FormFieldEditor = ({ show, onHide, field, onChange }: Props) => {
    const theme = useUITheme();
    const hasOptions =
        field &&
        [
            FormFieldType.checkbox,
            FormFieldType.radio,
            FormFieldType.select,
        ].includes(field.type);

    const handleChangeFieldName = () => {
        const newFieldName = window.prompt("Field Name: ", field?.name);
        if (field && newFieldName !== null) {
            onChange({
                ...field,
                name: newFieldName,
            });
        }
    };

    const handleChangeFieldType = (e: ChangeEvent<HTMLSelectElement>) => {
        if (field) {
            const newFieldType = e.target.value as FormFieldType;
            onChange({
                ...field,
                type: newFieldType,
            });
        }
    };

    const handleAddFieldOption = () => {
        if (field) {
            onChange({
                ...field,
                options: field.options
                    ? [...field.options, `Option ${field.options.length + 1}`]
                    : ["Option 1"],
            });
        }
    };

    const handleEditFieldOption = (index: number) => (newOption: string) => {
        if (field && field.options) {
            const newOptions = [...field.options];
            newOptions[index] = newOption;

            onChange({
                ...field,
                options: newOptions,
            });
        }
    };

    const handleDeleteFieldOption = (index: number) => () => {
        if (field && field.options) {
            const newOptions = [...field.options];
            newOptions.splice(index, 1);

            onChange({
                ...field,
                options: newOptions,
            });
        }
    };

    const handleDragEnd = (result: DropResult) => {
        if (field && field.options && result.destination) {
            const newOptions = [...field.options];
            const [removed] = newOptions.splice(result.source.index, 1);
            newOptions.splice(result.destination.index, 0, removed);
            onChange({
                ...field,
                options: newOptions,
            });
        }
    };

    if (!field) {
        return null;
    }

    return (
        <BlockEditorDialog show={show} onHide={onHide}>
            <div className="w-full px-8 pt-4 flex flex-col gap-4 justify-center">
                <div className="flex flex-row items-center gap-4">
                    <p>Field Name:</p>
                    <div
                        onClick={handleChangeFieldName}
                        className={`
            text-right overflow-hidden rounded-lg p-2 border 
            flex flex-row items-center justify-between flex-1
            cursor-pointer hover:brightness-95 duration-200 border-[${theme.border_color}] bg-[${theme.background_color}]
            `}
                    >
                        <p className="overflow-hidden whitespace-nowrap flex-1 text-left w-full">
                            {field.name}
                        </p>
                        <IconsMap.chevron_right color={theme.secondary} />
                    </div>
                </div>
                <div className="flex flex-row items-center gap-4">
                    <p>Field Type:</p>
                    <select
                        className="flex-1 bg-transparent border p-2 rounded-lg"
                        style={{
                            borderColor: theme.border_color,
                        }}
                        onChange={handleChangeFieldType}
                    >
                        {Object.keys(FormFieldType)
                            .filter(type => type !== FormFieldType.file) // We do not yet support file input
                            .map(type => (
                                <option
                                    key={type}
                                    value={type}
                                    selected={field.type === type}
                                >
                                    {cleanName(type)}
                                </option>
                            ))}
                    </select>
                </div>
                {hasOptions ? (
                    <div>
                        <p className="mb-6 text-md">Form Fields</p>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="faq">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className="transition-all duration-200"
                                    >
                                        <div className="gap-4 px-4 flex flex-col">
                                            {field.options?.map((option, i) => (
                                                <Draggable
                                                    draggableId={i.toString()}
                                                    index={i}
                                                    key={i}
                                                >
                                                    {(provided, _) => (
                                                        <div
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <FormFieldOptionRow
                                                                option={option}
                                                                onDelete={handleDeleteFieldOption(
                                                                    i,
                                                                )}
                                                                onEdit={handleEditFieldOption(
                                                                    i,
                                                                )}
                                                                provided={
                                                                    provided
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                        </div>
                                        <div
                                            className={`p-4 justify-center flex flex-row opacity-${
                                                snapshot.isDraggingOver
                                                    ? "0"
                                                    : "100"
                                            }`}
                                        >
                                            <UIButton
                                                isWide
                                                title="Add New Option"
                                                onClick={handleAddFieldOption}
                                            />
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                ) : null}
            </div>
        </BlockEditorDialog>
    );
};

export default FormFieldEditor;
