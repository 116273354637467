import { HydratedBlock, Media } from "types";
import { IconsMap } from "components/ui/Icon";
import { OptimizedImage } from "./Image";
import { Video } from "./Video";

export function Content(props: { block: HydratedBlock; media: Media | null }) {
    const { block, media } = props;

    if (!media?.url) {
        return (
            <div className="flex justify-center items-center w-full h-full theme-tertiary-background">
                <IconsMap.image className="theme-secondary-text" size={48} />
            </div>
        );
    }
    if (media.type === "video") {
        return <Video block={block} media={media} />;
    }

    return <OptimizedImage block={block} media={media} />;
}
