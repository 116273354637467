import { IconsMap } from "components/ui/Icon";
import { useUITheme } from "hooks/useUITheme";
import { Property } from "types";
import { propertyMap } from "../properties";

export const AlertInput = ({
    property,
    initialValue,
    onValueChange,
}: {
    property: Property;
    initialValue: any;
    onValueChange: (value: any) => void;
}) => {
    const theme = useUITheme(true);

    const onClick = () => {
        const propertyItem = propertyMap[property];
        const name = propertyItem.name;
        const t = window.prompt(name, initialValue);
        // returns null if user cancels
        if (t !== null) {
            onValueChange(t);
        }
    };

    return (
        <div
            onClick={onClick}
            className={`
            text-right overflow-hidden rounded-lg p-2 border 
            flex flex-row items-center justify-between flex-1 
            cursor-pointer hover:brightness-90 duration-200 h-10 w-full
            `}
            style={{
                backgroundColor: theme.quinary,
                borderColor: theme.quaternary,
            }}
        >
            <p
                className="text-base overflow-hidden whitespace-nowrap flex-1 text-left"
                style={{
                    color: theme.secondary,
                }}
            >
                {initialValue}
            </p>
            <IconsMap.chevron_right color={theme.secondary} />
        </div>
    );
};
