import { clsx } from "clsx";
import { useUITheme } from "hooks/useUITheme";
import { Icon, IconsMap } from "./Icon";

interface Props {
    name: string;
    options: { value: string; label: string; icon?: Icon }[];
    onValueChange: (value: string) => void;
    value: string;
    small?: boolean;
    labelClassName?: string;
    allowDarkMode?: boolean;
}

const ToggleSlider = ({
    name,
    options,
    onValueChange,
    value,
    labelClassName,
    allowDarkMode,
}: Props) => {
    const theme = useUITheme(allowDarkMode);
    const selectedIndex = options.findIndex(option => option.value === value);

    return (
        <div
            className="rounded-lg relative flex-1"
            style={{
                backgroundColor: theme.quaternary,
            }}
        >
            {selectedIndex !== -1 ? (
                <div
                    className="p-1 h-full absolute duration-300 transition-all"
                    style={{
                        width: `${100 / options.length}%`,
                        translate: `${selectedIndex * 100}%`,
                    }}
                >
                    <div
                        className="rounded-md relative h-full shadow-sm"
                        style={{ backgroundColor: theme.background_color }}
                    />
                </div>
            ) : null}
            <div className="flex flex-row gap-1 relative py-1">
                {options.map(option => {
                    const Icon = option.icon ? IconsMap[option.icon] : null;
                    const selected = value === option.value;
                    const toggleId = `${name}_${option.value}`;
                    return (
                        <label
                            key={option.value}
                            htmlFor={toggleId}
                            className={clsx(
                                labelClassName,
                                "rounded-md",
                                "overflow-hidden",
                                "transition-all",
                                "duration-300",
                                "flex",
                                "flex-1",
                                "justify-center",
                                "relative",
                                "cursor-pointer",
                                "py-2",
                                "select-none",
                            )}
                        >
                            {Icon ? (
                                <Icon
                                    color={
                                        selected
                                            ? theme.primary
                                            : theme.secondary
                                    }
                                />
                            ) : (
                                <span
                                    className="text-sm"
                                    style={{
                                        color: selected
                                            ? theme.primary
                                            : theme.secondary,
                                    }}
                                >
                                    {option.label}
                                </span>
                            )}
                            <input
                                type="radio"
                                name={name}
                                id={toggleId}
                                value={option.value}
                                className="hidden"
                                onChange={e => onValueChange(e.target.value)}
                                style={{
                                    boxShadow: selected
                                        ? "0px 4px 24px rgba(0, 0, 0, 0.02), 0px 2px 12px rgba(0, 0, 0, 0.04), 0px 1px 4px rgba(0, 0, 0, 0.08)"
                                        : "none",
                                }}
                            />
                        </label>
                    );
                })}
            </div>
        </div>
    );
};

export default ToggleSlider;
