import { v4 as uuidv4 } from "uuid";
import Button from "components/ui/Button";
import useBlockEditor from "hooks/useBlockEditor";
import { useCallback, useEffect, useState } from "react";
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from "react-beautiful-dnd";
import { Question } from "types";
import FAQRow from "./FAQRow";
import QuestionEditor from "./QuestionEditor";
import { clsx } from "clsx";
import UIButton from "components/ui/UIButton";

interface Props {
    questions?: Question[];
}

const FAQEditor = ({ questions = [] }: Props) => {
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(-1);
    const { updateProperty } = useBlockEditor();

    const showQuestionEditor = selectedQuestionIndex !== -1;
    const handleHideQuestionEditor = () => setSelectedQuestionIndex(-1);

    const handleDragEnd = (result: DropResult) => {
        if (result.destination) {
            const newQuestions = [...questions];
            const [removed] = newQuestions.splice(result.source.index, 1);
            newQuestions.splice(result.destination.index, 0, removed);
            updateProperty("faq_questions", newQuestions);
        }
    };

    const handleDelete = (index: number) => {
        const question = questions[index].question;
        const shouldDelete = window.confirm(
            `Are you sure you want to delete the following question:\n${question}`,
        );

        if (shouldDelete) {
            const newQuestions = [...questions];
            newQuestions.splice(index, 1);
            updateProperty("faq_questions", newQuestions);
        }
    };

    const handleEdit = (index: number) => {
        setSelectedQuestionIndex(index);
    };

    const handleChangeQuestion = useCallback(
        (newQuestion: string) => {
            const newQuestions = [...questions];
            newQuestions[selectedQuestionIndex] = {
                ...newQuestions[selectedQuestionIndex],
                question: newQuestion,
            };
            updateProperty("faq_questions", newQuestions);
        },
        [selectedQuestionIndex, questions],
    );

    const handleChangeAnswer = useCallback(
        (newAnswer: string) => {
            const newQuestions = [...questions];
            newQuestions[selectedQuestionIndex] = {
                ...newQuestions[selectedQuestionIndex],
                answer: newAnswer,
            };
            updateProperty("faq_questions", newQuestions);
        },
        [selectedQuestionIndex, questions],
    );

    const handleAddQuestion = () => {
        const newQuestions = [
            ...questions,
            { id: uuidv4(), question: "Question", answer: "<p>Answer</p>" },
        ];
        updateProperty("faq_questions", newQuestions);
    };

    return (
        <div className="p-6">
            <p className="mb-6 text-sm">FAQ Questions</p>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="faq">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="transition-all duration-200"
                        >
                            <div className="gap-4 px-4 flex flex-col">
                                {questions.map((question, i) => (
                                    <Draggable
                                        draggableId={question.id.toString()}
                                        index={i}
                                        key={question.id}
                                    >
                                        {(provided, _) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <FAQRow
                                                    question={question}
                                                    onDelete={() =>
                                                        handleDelete(i)
                                                    }
                                                    onEdit={() => handleEdit(i)}
                                                    provided={provided}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            </div>
                            <div
                                className={clsx(
                                    "p-4 justify-center flex flex-row",
                                    {
                                        "opacity-0": snapshot.isDraggingOver,
                                        "opacity-100": !snapshot.isDraggingOver,
                                    },
                                )}
                            >
                                <UIButton
                                    isWide
                                    title="Add Question"
                                    onClick={handleAddQuestion}
                                    allowDarkMode
                                />
                            </div>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <QuestionEditor
                show={showQuestionEditor}
                question={questions[selectedQuestionIndex]}
                onHide={handleHideQuestionEditor}
                onChangeQuestion={handleChangeQuestion}
                onChangeAnswer={handleChangeAnswer}
            />
        </div>
    );
};

export default FAQEditor;
