import { IconsMap } from "components/ui/Icon";
import { useUITheme } from "hooks/useUITheme";

export const BlankInput = ({
    initialValue,
    onClick,
}: {
    initialValue: any;
    onClick: () => void;
}) => {
    const theme = useUITheme(true);

    return (
        <div
            onClick={onClick}
            className={`
            text-right overflow-hidden rounded-lg p-2 border 
            flex flex-row items-center justify-between flex-1 
            cursor-pointer hover:brightness-90 duration-200 h-10 w-full
            `}
            style={{
                backgroundColor: theme.quinary,
                borderColor: theme.quaternary,
            }}
        >
            <p
                className="text-base overflow-hidden whitespace-nowrap flex-1 text-left"
                style={{
                    color: theme.secondary,
                }}
            >
                {initialValue}
            </p>
            <IconsMap.chevron_right color={theme.secondary} />
        </div>
    );
};
