import { CldUploadWidget } from "next-cloudinary";
import { useSelector } from "react-redux";
import { selectProfileId } from "store/profileSlice";
import * as Sentry from "@sentry/nextjs";

// example res

// {
//     "event": "success",
//     "info": {
//         "id": "uw-file3",
//         "batchId": "uw-batch2",
//         "asset_id": "1bdf795bb84b6a44be453b921c0fa573",
//         "public_id": "mystore_content/wtrvikwq8dwfkzp3f2vd",
//         "version": 1672887924,
//         "version_id": "01b67d515c00680817b34034a8d40cc1",
//         "signature": "a5dfedfb8c201d08792598448cf8852e08568181",
//         "width": 800,
//         "height": 800,
//         "format": "jpg",
//         "resource_type": "image",
//         "created_at": "2023-01-05T03:05:24Z",
//         "tags": [],
//         "bytes": 54896,
//         "type": "upload",
//         "etag": "5511c31f403b690d51f96561055f2683",
//         "placeholder": false,
//         "url": "http://res.cloudinary.com/ddhetmqtt/image/upload/v1672887924/mystore_content/wtrvikwq8dwfkzp3f2vd.jpg",
//         "secure_url": "https://res.cloudinary.com/ddhetmqtt/image/upload/v1672887924/mystore_content/wtrvikwq8dwfkzp3f2vd.jpg",
//         "folder": "mystore_content",
//         "access_mode": "public",
//         "original_filename": "1670511694321",
//         "original_extension": "jpeg",
//         "path": "v1672887924/mystore_content/wtrvikwq8dwfkzp3f2vd.jpg",
//         "thumbnail_url": "https://res.cloudinary.com/ddhetmqtt/image/upload/c_limit,h_60,w_90/v1672887924/mystore_content/wtrvikwq8dwfkzp3f2vd.jpg"
//     }
// }

type Props = {
    children: React.ReactNode;
    onUpload: (error: any, res: any, url: string) => void;
    onClick?: () => void;
};

export default function CloudinaryWidget(props: Props) {
    const pid = useSelector(selectProfileId);
    const { children, onClick = () => {} } = props;
    function onUpload(error: any, res: any) {
        const url = res?.info?.secure_url;
        if (error) {
            Sentry.captureException(error);
            alert("Error uploading image");
        }

        // WARNING: becareful any function passed into cloudinary widget onupload will not update it's variables. see media editor for work around
        props.onUpload(error, res, url);
    }
    return (
        <CldUploadWidget
            signatureEndpoint="/api/v0.1/admin/cloudinary-signature"
            onUpload={onUpload}
            options={{
                sources: [
                    "local",
                    "url",
                    "camera",
                    "facebook",
                    "instagram",
                    "dropbox",
                    "google_drive",
                    "unsplash",
                ],
                context: `profile_id=${pid}`,
            }}
            uploadPreset={process.env.NEXT_PUBLIC_CLOUDINARY_PRESET}
            inlineContainer
        >
            {({ open }: { open: () => void }) => {
                function handleOnClick(e: any) {
                    if (e.cancelable) {
                        e.preventDefault();
                    }
                    if (onClick) {
                        onClick();
                    }
                    open();
                }
                return <div onClick={handleOnClick}>{children}</div>;
            }}
        </CldUploadWidget>
    );
}
