import { IconsMap } from "components/ui/Icon";
import { useUITheme } from "hooks/useUITheme";
import { BlockType } from "types";
import { cleanName } from "utilities/cleanName";
import { BLOCK_OPTIONS } from "utilities/constants";

const pro = [BlockType.text, BlockType.carousel];
const demo = [BlockType.form];

type Props = {
    value?: string;
    onChangeValue: (value: BlockType) => void;
};

const sortPlan = (a: { value: BlockType }, b: { value: BlockType }) => {
    if (demo.includes(a.value) && !demo.includes(b.value)) {
        return 1;
    }
    if (demo.includes(b.value) && !demo.includes(a.value)) {
        return -1;
    }
    return 0;
};

const blockOptionComponents = BLOCK_OPTIONS.map(option => {
    const icon = option.icon;
    const Icon = IconsMap[icon] || IconsMap["text"];
    return {
        value: option.value as BlockType,
        label: (
            <div className="flex flex-row items-center">
                <Icon size={"1em"} />{" "}
                <span className="ml-4">{cleanName(option.label)}</span>
            </div>
        ),
    };
}).sort(sortPlan);

export default function BlockSelector(props: Props) {
    const { onChangeValue, value } = props;
    const theme = useUITheme();

    return (
        <div
            className="grid gap-2 p-4"
            style={{
                gridTemplateColumns: "1fr 1fr",
            }}
        >
            {blockOptionComponents.sort(sortPlan).map(option => {
                const selected = value === option.value;
                return (
                    <div
                        key={option.value}
                        className="flex flex-row gap-4 cursor-pointer rounded-lg p-4 items-center border duration-200 transition-all relative hover:brightness-90"
                        onClick={() => onChangeValue(option.value)}
                        style={{
                            backgroundColor: theme.background_color,
                            borderColor: selected
                                ? theme.primary
                                : theme.quaternary,
                        }}
                    >
                        <div>
                            <div
                                className="font-semibold"
                                style={{
                                    color: theme.primary,
                                }}
                            >
                                {option.label}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
