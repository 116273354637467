import { useTheme } from "hooks/useTheme";
import { clsx } from "clsx";
import StoreLogo from "./StoreLogo";
import { autoQualityImage } from "utilities/cloudinaryUtils";
import { IconsMap } from "components/ui/Icon";
import { Settings } from "types";
import { BioData } from "./types";

type Props = {
    hideCover: boolean;
    hideBio: boolean;
    hideLogo: boolean;
    hideName: boolean;
    mobileView: boolean;
    data: BioData;
};

export default function BioBlockTwo({
    hideCover,
    hideBio,
    hideLogo,
    hideName,
    mobileView,
    data,
}: Props) {
    const theme = useTheme();

    const coverStyle = hideCover
        ? {}
        : {
              backgroundImage: `url(${autoQualityImage(data.cover_url)})`,
              borderRadius: theme.block_radius,
          };

    return (
        <div
            className={clsx("flex-1 justify-center flex flex-col px-1", {
                "py-2": !hideBio,
            })}
        >
            <div
                className={clsx({
                    "w-full bg-cover bg-center relative": !hideCover,
                    "h-[180px] mb-4": !hideCover && !mobileView,
                    "h-[144px] mb-2": !hideCover && mobileView,
                })}
                style={coverStyle}
            >
                {!data.cover_url && !hideCover && (
                    <div
                        className={clsx(
                            "flex justify-center items-center w-full theme-tertiary-background",
                            {
                                "h-[180px]": !hideCover && !mobileView,
                                "h-[144px]": !hideCover && mobileView,
                            },
                        )}
                        style={{
                            borderRadius: theme.block_radius,
                        }}
                    >
                        <IconsMap.image
                            className="theme-secondary-text"
                            size={48}
                        />
                    </div>
                )}
            </div>

            {!hideLogo && (
                <div className="flex p-1">
                    <StoreLogo
                        imageUrl={data.profile_url}
                        borderRadius={theme.button_radius}
                    />
                </div>
            )}
            <div className={clsx("flex flex-col px-2 pb-1")}>
                {!hideName && (
                    <p className="text-2xl font-bold my-2 theme-primary-text">
                        {data.name}
                    </p>
                )}
                {!hideBio && (
                    <p className="text-sm theme-secondary-text">
                        {data.description}
                    </p>
                )}
            </div>
        </div>
    );
}
