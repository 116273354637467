import Button from "components/ui/Button";
import { useTheme } from "hooks/useTheme";
import type { CSSProperties } from "react";
import { useSelector } from "react-redux";
import { selectBlockTree, selectProfile } from "store/profileSlice";
import { ButtonMode, HydratedBlock, PageType } from "types";
import { useTabActions } from "./hooks/useTabActions";

type Props = {
    block: HydratedBlock;
    activePageBlockId?: string;
};
export default function Tab(props: Props) {
    const { block, activePageBlockId } = props;
    const theme = useTheme();
    const { onClickInEditMode, getHRef } = useTabActions();

    const isActive = block.id === activePageBlockId;
    const pageUrl = block.properties?.page_url;
    const pageName = block.properties?.page_name;

    const tree = useSelector(selectBlockTree);
    const profile = useSelector(selectProfile);
    const name = profile?.name;
    const onlyHomePage = tree.length === 1;
    // if there is one page, show the username instead of home

    const title = onlyHomePage ? name : pageName || pageUrl || "Home";
    // do not show border of only home page
    const showBorder = isActive && !onlyHomePage;

    const style: CSSProperties = {
        opacity: undefined,
        borderColor: showBorder ? theme.primary : "transparent",
        boxSizing: "border-box",
    };

    const href = getHRef(block);
    return (
        <div
            className="inline-block align-middle px-2 border-b-2 py-0 pageNavItem hover:!!none"
            key={block.id}
            style={style}
        >
            <div className="flex flex-row">
                <Button
                    title={title}
                    noHoverEffect
                    mode={ButtonMode.ghost}
                    htmlComponent={"a"}
                    href={href}
                    newTab={block.properties?.page_type === PageType.URL}
                    style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        minWidth: 48,
                    }}
                    color={isActive ? theme.primary : theme.secondary}
                    onClick={e => {
                        onClickInEditMode(e, block);
                    }}
                />
            </div>
        </div>
    );
}
