import { CaptionType, HydratedBlock } from "types";
import { LargeCaption } from "./LargeCaption";
import { MediumCaption } from "./MediumCaption";
import { SmallCaption } from "./SmallCaption";

type Props = {
    block: HydratedBlock;
};

export function Caption(props: Props) {
    const { block } = props;

    const properties = block.properties;

    if (!properties) {
        return null;
    }

    if (
        !properties.caption_title_text &&
        !properties.caption_subtitle_text &&
        !properties.button_text
    ) {
        return null;
    }

    switch (properties.caption_type) {
        case CaptionType.big: // Headline
            return <LargeCaption properties={properties} />;
        case CaptionType.medium: // Caption
            return <MediumCaption properties={properties} />;
        case CaptionType.small: // Footer
            return <SmallCaption properties={properties} />;
        default:
            return <MediumCaption properties={properties} />;
    }
}
