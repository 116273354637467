import apiClient from "apiClient";
import Visitor from "components/profile/Visitor";
import { IconsMap } from "components/ui/Icon";
import Loading from "components/ui/Loading";
import Modal from "components/ui/Modal";
import ModalDetachedBox from "components/ui/ModalDetachedBox";
import { useModal } from "hooks/useModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalType } from "store/modalsSlice";
import { selectProfileState } from "store/profileSlice";
import { Profile } from "types";

function sortAlphabetical(a: Profile, b: Profile) {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}

const TemplatePreview = ({
    profile,
    onClick,
}: {
    profile: Profile;
    onClick: (profile: Profile) => void;
}) => {
    if (!profile) {
        return <p>loading..</p>;
    }
    return (
        <div
            className="rounded-lg bg-slate-100 p-4 hover:brightness-90 transition-all duration-200 flex-row flex items-center gap-4 hover:scale-[.98]"
            onClick={() => {
                onClick(profile);
            }}
        >
            <div
                style={{
                    backgroundImage: `url(${profile.profile_url})`,
                }}
                className="rounded-full bg-center bg-no-repeat bg-cover w-[56px] h-[56px]"
            />
            <p className="font-semibold">{profile.name}</p>
        </div>
    );
};

export default function TemplateModal() {
    const [profiles, setProfiles] = useState<Profile[]>([]);
    const [selectedProfile, setSelectedProfile] = useState<Profile | null>(
        null,
    );
    const dispatch = useDispatch();
    const profileState = useSelector(selectProfileState);
    const pid = profileState?.id;
    const { modalState, setModal } = useModal();
    const isOpen = modalState.profileTemplate;

    const _setsIsOpen = (visible: boolean) => {
        setModal(ModalType.ProfileTemplate, visible);
    };

    useEffect(() => {
        if (!isOpen) return;
        const fetchProfiles = async () => {
            const { templateProfiles: _profiles } = await apiClient.get<{
                templateProfiles: Profile[];
            }>("profiles/templates");

            setProfiles(
                Object.values(_profiles)
                    .filter(p => p.template)
                    .sort(sortAlphabetical),
            );
        };
        fetchProfiles();
    }, [isOpen]);

    const _useTemplate = async () => {
        const response = await fetch(`/api/v0.1/profiles/${pid}/use-template`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                templatePid: selectedProfile?.id,
            }),
        });

        const data = await response.json();
        if (data.error) {
            alert(data.error);
            return;
        }

        _setsIsOpen(false);
        setSelectedProfile(null);
    };

    const _onCloseTemplateModal = () => {
        _setsIsOpen(false);
    };

    const _onClickPreview = (profile: Profile) => {
        setSelectedProfile(profile);
    };

    const _onCancelPreview = () => {
        setSelectedProfile(null);
    };

    return (
        <>
            <ModalDetachedBox
                isOpen={isOpen}
                leftIcon="close"
                onClickLeft={_onCloseTemplateModal}
                setIsOpen={_setsIsOpen}
            >
                <div className="p-4 sm:p-16 flex flex-col gap-4">
                    <p className="text-3xl font-bold flex flex-row items-center gap-2">
                        <IconsMap.template /> Templates
                    </p>
                    <div className="flex flex-col gap-2 sm:gap-8 my-4 cursor-pointer max-w-xl pb-16">
                        {profiles?.map(profile => (
                            <TemplatePreview
                                profile={profile}
                                key={profile.id}
                                onClick={_onClickPreview}
                            />
                        ))}
                        {profiles.length === 0 && <Loading />}
                    </div>
                </div>
            </ModalDetachedBox>
            <Modal
                isOpen={!!selectedProfile}
                leftTitle="Cancel"
                onClickLeft={_onCancelPreview}
                rightTitle="Use Template"
                onClickRight={_useTemplate}
                backgroundColor={selectedProfile?.background_color}
            ></Modal>
        </>
    );
}
