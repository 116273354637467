import ToggleSlider from "components/ui/ToggleSlider";
import { Property } from "types";
import { propertyMap, PropertyOption } from "../properties";

type Props = {
    options: PropertyOption[];
    value: any;
    property: Property;
    onValueChange: (value: any) => void;
    allowDarkMode?: boolean;
};

export function InlineToggle({
    value,
    property,
    onValueChange,
    allowDarkMode,
}: Props) {
    const propertyItem = propertyMap[property];
    return (
        <div className="flex flex-row items-center flex-[3] justify-end h-10 w-full">
            <ToggleSlider
                name={property}
                options={propertyItem.options || []}
                value={value}
                onValueChange={value => {
                    onValueChange(value);
                }}
                allowDarkMode={allowDarkMode}
            />
        </div>
    );
}
