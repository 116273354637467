import UIButton from "components/ui/UIButton";
import useCellEditor from "hooks/useCellEditor";
import { useUITheme } from "hooks/useUITheme";
import { ButtonMode } from "types";

export default function AdjustHeight() {
    const uiTheme = useUITheme(true);
    const { incrementHeight, decrementHeight } = useCellEditor();

    return (
        <div className="flex flex-[2] justify-around w-full">
            <div className="flex flex-col items-center">
                <p className="font-medium text-sm pb-3">Shrink</p>
                <UIButton
                    icon="compress"
                    iconSize={22}
                    mode={ButtonMode.outline}
                    onClick={decrementHeight}
                    borderRadius="20px"
                    padding="p-6"
                    allowDarkMode
                    style={{
                        borderColor: uiTheme.primary,
                    }}
                />
            </div>
            <div className="flex flex-col items-center">
                <p className="font-medium text-sm pb-3">Grow</p>
                <UIButton
                    icon="expand"
                    iconSize={22}
                    mode={ButtonMode.outline}
                    onClick={incrementHeight}
                    borderRadius="20px"
                    padding="p-6"
                    allowDarkMode
                    style={{
                        borderColor: uiTheme.primary,
                    }}
                />
            </div>
        </div>
    );
}
