import useBreakpoints from "hooks/useBreakpoints";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { selectBlockEditorLoading } from "store/blockEditor";
import { useTheme } from "hooks/useTheme";
import { IconsMap } from "components/ui/Icon";
import Image from "next/image";
import { clsx } from "clsx";

const HEIGHT_BREAKPOINT = 250;

interface Props {
    height: number;
    url: string;
    title?: string;
    description?: string;
    faviconUrl?: string;
    imageUrl?: string;
    imageColor?: string;
    isSubtleBackground?: boolean;
    blockRadius: number;
}

const _getDomain = (url: string) => {
    try {
        return new URL(url).hostname.replace("www.", "");
    } catch {
        return url;
    }
};

const WebsiteEmbed = ({
    height,
    url,
    title,
    description,
    faviconUrl,
    imageUrl,
    isSubtleBackground,
    blockRadius,
}: Props) => {
    const { isMobileView } = useBreakpoints();
    const router = useRouter();
    const { edit } = router.query;
    const domain = _getDomain(url);
    const isLoading = useSelector(selectBlockEditorLoading);
    const theme = useTheme();

    const decode = (str: string) => {
        const s = "<b>" + str + "</b>";
        let e = document.createElement("decodeIt");
        e.innerHTML = s;
        return e.innerText;
    };

    if (isLoading) {
        return (
            <div className="p-5 flex items-center justify-center">
                <IconsMap.spinner
                    className="animate-spin"
                    color={theme.primary}
                />
            </div>
        );
    }

    return (
        <a
            className={clsx(
                "flex h-full w-full hover:brightness-90 duration-200 transition-all relative",
                {
                    "flex-col": height > HEIGHT_BREAKPOINT,
                    "flex-row": height <= HEIGHT_BREAKPOINT,
                    "pointer-events-none": edit,
                },
            )}
            rel="noreferrer"
            target="_blank"
            href={url}
        >
            {imageUrl ? (
                <div
                    className={clsx("min-h-0", {
                        "flex-1 w-full relative": height > HEIGHT_BREAKPOINT,
                        "w-1/3": isMobileView && height <= HEIGHT_BREAKPOINT,
                        "w-1/4": !isMobileView && height <= HEIGHT_BREAKPOINT,
                    })}
                >
                    <div className="w-full h-full relative">
                        <div className="w-full h-full absolute" />
                        <Image
                            className="object-cover brightness-95 w-full h-full !relative"
                            fill
                            src={imageUrl}
                            alt={title || domain}
                        />
                    </div>
                    <div className="flex flex-row absolute top-2 left-2 items-center">
                        {faviconUrl ? (
                            <div className="bg-white rounded-full p-1.5 mr-1.5 h-6 w-6">
                                <Image
                                    src={faviconUrl}
                                    fill
                                    alt="favorite icon"
                                    className="object-contain !relative"
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : null}
            <div
                className={clsx(
                    `flex flex-col p-3 overflow-hidden relative`,
                    { "w-full": height > HEIGHT_BREAKPOINT },
                    { "flex-1": height <= HEIGHT_BREAKPOINT },
                    { "theme-tertiary-background": isSubtleBackground },
                )}
                style={{
                    borderRadius: `0 0 ${blockRadius}px ${blockRadius}px`,
                }}
            >
                <div className="flex-1">
                    <div>
                        <h1
                            className={clsx(
                                "text-sm font-bold overflow-hidden text-ellipsis theme-primary-text",
                                {
                                    "line-clamp-5 whitespace-normal":
                                        isMobileView,
                                    "whitespace-nowrap": !isMobileView,
                                },
                            )}
                        >
                            {title ? decode(title) : ""}
                        </h1>
                    </div>

                    {!isMobileView ? (
                        <div className="flex-1 text-sm mt-1 overflow-hidden">
                            <p className="overflow-hidden text-ellipsis line-clamp-2 theme-secondary-text">
                                {description ? decode(description) : ""}
                            </p>
                        </div>
                    ) : null}
                </div>
                <div className="flex flex-row top-2 left-2 items-center mt-2">
                    {!imageUrl && faviconUrl ? (
                        <div className="rounded-full p-1.5 mr-1.5 h-6 w-6">
                            <Image
                                src={faviconUrl}
                                fill
                                alt="favorite icon"
                                className="object-contain !relative"
                            />
                        </div>
                    ) : null}
                    <p className="text-xs whitespace-nowrap text-ellipsis overflow-hidden self-center theme-secondary-text">
                        {domain}
                    </p>
                </div>
            </div>
        </a>
    );
};

export default WebsiteEmbed;
