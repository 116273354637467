import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    selectActivePageBlock,
    selectPinnedBlockId,
    selectProfile,
    selectProfileId,
} from "store/profileSlice";
import { BlockType } from "types";

import { useBlockHeight } from "hooks/useBlockHeight";
import { ModalType, setModal } from "store/modalsSlice";
import {
    clearBlockEditor,
    selectBlockEditor,
    setBlockEditor,
} from "store/blockEditor";
import { HEIGHT_INCREMENT } from "utilities/constants";
import useBreakpoints from "./useBreakpoints";
import useBlockEditor from "./useBlockEditor";
import { setSettingsEditor } from "store/settingsEditor";

// Currently, we take advantage of the fact that firebase has real time updating.
// therefore we do not need to update redux state after we update a block
export default function useCellEditor() {
    const pid = useSelector(selectProfileId);
    const block = useSelector(selectBlockEditor);

    const dispatch = useDispatch();
    const pinned_block_id = useSelector(selectPinnedBlockId);
    const activePageBlock = useSelector(selectActivePageBlock);
    const profile = useSelector(selectProfile);
    const { isMobileView } = useBreakpoints();
    const { baseHeight } = useBlockHeight(block || undefined);

    const { updateProperty } = useBlockEditor();

    const moveUp = useCallback(() => {
        fetch(`/api/v0.1/profiles/${pid}/blocks/${block?.id}/move`, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify({ direction: "up" }),
        });
    }, [block, pid]);

    const moveDown = useCallback(() => {
        fetch(`/api/v0.1/profiles/${pid}/blocks/${block?.id}/move`, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify({ direction: "down" }),
        });
    }, [block, pid]);

    const incrementHeight = useCallback(async () => {
        const newHeight = baseHeight + HEIGHT_INCREMENT;
        updateProperty(
            isMobileView ? "height_mobile" : "height_desktop",
            newHeight,
        );
    }, [baseHeight, updateProperty, isMobileView]);

    const decrementHeight = useCallback(async () => {
        const newHeight = baseHeight - HEIGHT_INCREMENT;
        updateProperty(
            isMobileView ? "height_mobile" : "height_desktop",
            newHeight,
        );
    }, [baseHeight, updateProperty, isMobileView]);

    const duplicate = useCallback(() => {
        fetch(`/api/v0.1/profiles/${pid}/blocks/${block?.id}/duplicate`, {
            method: "POST",
            headers: { "content-type": "application/json" },
        });
    }, [block, pid]);

    const edit = useCallback(() => {
        dispatch(
            setModal({
                modal: ModalType.BlockEditor,
                visible: true,
            }),
        );
        if (block) dispatch(setBlockEditor(block));
        dispatch(setSettingsEditor(profile));
    }, [dispatch, block, profile]);

    const trash = useCallback(() => {
        const result = confirm("Are you sure you want to delete this block?");
        if (result) {
            fetch(`/api/v0.1/profiles/${pid}/blocks/${block?.id}`, {
                method: "DELETE",
                headers: { "content-type": "application/json" },
            });
        }
        dispatch(
            setModal({
                modal: ModalType.BlockEditor,
                visible: false,
            }),
        );
        setTimeout(() => {
            dispatch(clearBlockEditor());
        }, 500); // Block editor collapse animation
    }, [block?.id, pid, dispatch]);

    const pin = useCallback(async () => {
        const response = await fetch(
            `/api/v0.1/profiles/${pid}/blocks/${activePageBlock?.id}`,
            {
                method: "PUT",
                headers: { "content-type": "application/json" },
                body: JSON.stringify({
                    updates: {
                        properties: {
                            pinned_block_id:
                                pinned_block_id === block?.id ? "" : block?.id,
                        },
                    },
                }),
            },
        );
        await response.json();
    }, [activePageBlock?.id, block?.id, pid, pinned_block_id]);

    return {
        moveUp,
        moveDown,
        incrementHeight,
        decrementHeight,
        duplicate,
        edit,
        trash,
        pin,
        isPinned: pinned_block_id === block?.id,
        showExpand: [
            BlockType.carousel,
            BlockType.media,
            BlockType.embed,
        ].includes(block?.type as BlockType),
    };
}
