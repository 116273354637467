import { useUITheme } from "hooks/useUITheme";
import { core_palette } from "utilities/palette";
import { ChromePicker, ColorResult } from "react-color";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import { IconsMap } from "components/ui/Icon";

enum ImgBackgroundColorType {
    default = "default",
    black = "black",
    white = "white",
    custom = "custom",
    transparent = "transparent",
}

const pickerStyles = {
    default: {
        saturation: {
            borderRadius: "12px",
        },
        picker: {
            backgroundColor: "inherit",
            borderRadius: "12px",
            border: "none",
            width: "100%",
            boxShadow: "none",
        },
    },
};

type Props = {
    value: any;
    onValueChange: (value: any) => void;
};

type OptionsProps = {
    name: ImgBackgroundColorType;
    color: string;
    borderColor?: string;
    onClick: (value: string, name: ImgBackgroundColorType) => void;
    active: boolean;
};

const Options = ({
    name,
    color,
    borderColor,
    onClick,
    active,
}: OptionsProps) => {
    return (
        <div
            style={{
                background: color,
                borderRadius: "50%",
                border: `1px solid ${borderColor}`,
                width: "32px",
                height: "32px",
                cursor: "pointer",
                outline: active
                    ? `2px solid ${core_palette.success.light}`
                    : "none",
                position: "relative",
            }}
            onClick={() => onClick(color, name)}
        >
            {name === ImgBackgroundColorType.transparent && (
                <div className="after:content-[''] border-t absolute top-3.5 right-0 left-0 origin-center rotate-45"></div>
            )}
        </div>
    );
};

export function ImgBackgroundColor({ onValueChange, value }: Props) {
    const uiTheme = useUITheme(true);
    const [isOpen, setIsOpen] = useState(false);
    const [activeImgBgColor, setActiveImgBgColor] =
        useState<ImgBackgroundColorType>(ImgBackgroundColorType.default);

    const onOpenChange = () => {
        setActiveImgBgColor(ImgBackgroundColorType.custom);
        setIsOpen(!isOpen);
    };

    const onClick = (value: string, name: ImgBackgroundColorType) => {
        setActiveImgBgColor(name);
        onValueChange(value);
    };

    return (
        <div className="flex flex-row gap-4">
            <Options
                name={ImgBackgroundColorType.black}
                color={core_palette.inverse.dark}
                borderColor={
                    uiTheme.dark_mode ? uiTheme.primary : uiTheme.border_color
                }
                onClick={onClick}
                active={activeImgBgColor === ImgBackgroundColorType.black}
            />
            <Options
                name={ImgBackgroundColorType.white}
                color={core_palette.inverse.light}
                borderColor={
                    uiTheme.dark_mode ? uiTheme.primary : uiTheme.border_color
                }
                onClick={onClick}
                active={activeImgBgColor === ImgBackgroundColorType.white}
            />

            <Popover.Root open={isOpen} onOpenChange={onOpenChange}>
                <Popover.Trigger>
                    <Options
                        name={ImgBackgroundColorType.custom}
                        color="conic-gradient(from 0deg, #f00, #ff0, #0f0, #0ff, #00f, #f0f, #f00)"
                        borderColor={core_palette.inverse.light}
                        onClick={onOpenChange}
                        active={
                            activeImgBgColor === ImgBackgroundColorType.custom
                        }
                    />
                </Popover.Trigger>
                <Popover.Portal>
                    <AnimatePresence>
                        <motion.div
                            initial={{ opacity: 0, x: 10 }}
                            animate={{ opacity: 1, x: 10 }}
                            exit={{ opacity: 0, x: 10 }}
                            transition={{ duration: 0.2 }}
                            className="p-8 flex flex-col items-center justify-end fixed right-0 left-0 top-0 bottom-0 h-full w-full"
                        >
                            <Popover.Content
                                id="media-block-background-color-picker"
                                className="outline-none shadow-lg disable-scrollbars overflow-auto rounded-[12px] px-2 pt-8 w-[240px] will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
                                side="left"
                                sideOffset={-125}
                                align="end"
                                alignOffset={-165}
                                style={{
                                    background: uiTheme.background_color,
                                    borderColor: uiTheme.border_color,
                                }}
                            >
                                <ChromePicker
                                    color={value}
                                    styles={pickerStyles}
                                    onChange={(color: ColorResult) =>
                                        onClick(
                                            color.hex,
                                            ImgBackgroundColorType.custom,
                                        )
                                    }
                                    disableAlpha={true}
                                />
                                <Popover.Close
                                    className="rounded-full h-[25px] w-[25px] inline-flex items-center justify-center text-violet11 absolute top-[5px] right-[5px] hover:bg-violet4 focus:shadow-[0_0_0_2px] focus:shadow-violet7 outline-none cursor-default"
                                    aria-label="Close"
                                >
                                    <IconsMap.close />
                                </Popover.Close>
                            </Popover.Content>
                        </motion.div>
                    </AnimatePresence>
                </Popover.Portal>
            </Popover.Root>

            <Options
                name={ImgBackgroundColorType.transparent}
                color={core_palette.quinary.light}
                borderColor={
                    uiTheme.dark_mode ? uiTheme.primary : uiTheme.border_color
                }
                onClick={onClick}
                active={activeImgBgColor === ImgBackgroundColorType.transparent}
            />
        </div>
    );
}
