// FYI - Use InputSelect instead of Select.
// Only use Select if you need custom options.
import { useUITheme } from "hooks/useUITheme";
import { clsx } from "clsx";
import ReactSelect from "react-select";

type Props = {
    options: { value: string; label: JSX.Element }[];
    value: string;
    onChangeValue: (value: string) => void;
    maxWidth?: number | string;
    borderRadius?: number;
    fontSize?: number;
    allowDarkMode?: boolean;
    maxMenuHeight?: number;
};

export default function Select({
    options,
    onChangeValue,
    value,
    maxWidth = 300,
    borderRadius,
    fontSize,
    allowDarkMode,
    maxMenuHeight,
}: Props) {
    const theme = useUITheme(allowDarkMode);

    return (
        <ReactSelect
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    minWidth: maxWidth,
                    backgroundColor: theme.background_color,
                    color: theme.primary,
                    cursor: "pointer",
                    ...(borderRadius && {
                        borderRadius: borderRadius,
                    }),
                    ...(fontSize && {
                        fontSize: fontSize,
                    }),
                }),
                input: (baseStyles, state) => ({
                    ...baseStyles,
                    color: theme.primary,
                }),
                singleValue: (baseStyles, state) => ({
                    ...baseStyles,
                    color: theme.primary,
                }),
                option: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: theme.background_color,
                    cursor: "pointer",
                    color: state.isSelected ? theme.primary : theme.primary,
                    ...(fontSize && {
                        fontSize: fontSize,
                    }),
                    ...(theme.dark_mode && {
                        backgroundColor: theme.quaternary,
                    }),
                }),
                menu: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: theme.background_color,
                }),
            }}
            classNames={{
                option: state =>
                    clsx("duration-200 transition-all hover:brightness-95", {
                        "bg-[#272F3624] hover:bg-opacity-30 hover:bg-[#aeacac4d]":
                            theme.dark_mode,
                    }),
                menuList: state => clsx("disable-scrollbars"),
            }}
            defaultValue={options.find(o => o.value === value)}
            value={options.find(o => o.value === value)}
            onChange={(e: any) => {
                onChangeValue(e.value);
            }}
            options={options}
            maxMenuHeight={maxMenuHeight ?? undefined}
        />
    );
}
