import BlockSelector from "components/profile/BlockEditor/PropertyInput/BlockSelector";
import Button from "components/ui/Button";
import { Icon } from "components/ui/Icon";
import ModalDetachedBox from "components/ui/ModalDetachedBox";
import { useModal } from "hooks/useModal";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ModalType } from "store/modalsSlice";
import { selectActivePageBlock, selectProfileId } from "store/profileSlice";
import {
    BlockType,
    ButtonMode,
    DEFAULT_BLOCK_PROPERTIES,
    Properties,
    DEFAULT_BLOCK_PROPERTIES_GLOBAL,
} from "types";
import SelectBlockTemplates from "./SelectBlockTemplates";
import UIButton from "components/ui/UIButton";

enum ModalPage {
    SelectBlock = "selectBlock",
    SelectTemplate = "selectTemplate",
}

function Page(props: { page: ModalPage; setPage: (page: ModalPage) => void }) {
    const { setModal } = useModal();
    const pid = useSelector(selectProfileId);
    const pageBlock = useSelector(selectActivePageBlock);
    const _onSelectBlock = async (blockType: BlockType) => {
        setModal(ModalType.AddBlock, false);

        const defaultProperties: Properties = {
            ...DEFAULT_BLOCK_PROPERTIES[blockType],
            ...DEFAULT_BLOCK_PROPERTIES_GLOBAL,
        };

        const response = await fetch(`/api/v0.1/profiles/${pid}/blocks`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                parent: pageBlock?.id,
                type: blockType,
                properties: defaultProperties,
            }),
        });
        const data = await response.json();
        if (data.error) {
            alert(data.error);
        }
    };

    switch (props.page) {
        case ModalPage.SelectBlock:
            return (
                <div>
                    <BlockSelector onChangeValue={_onSelectBlock} />
                    {/* <div className="p-4 w-full flex flex-col items-center">
                        <UIButton
                            mode={ButtonMode.glass}
                            icon="block"
                            title="Block Templates"
                            className="px-8"
                            style={{ borderRadius: 4 }}
                            onClick={() => {
                                props.setPage(ModalPage.SelectTemplate);
                            }}
                        />
                    </div> */}
                </div>
            );
        case ModalPage.SelectTemplate:
            return <SelectBlockTemplates />;
        default:
            return null;
    }
}

export default function BlockTemplateModal() {
    const [page, setPage] = useState(ModalPage.SelectBlock);
    const { setModal, modalState } = useModal();

    const pages: {
        [key in ModalPage]: {
            title: string;
            leftIcon?: Icon;
            onClickLeft?: () => void;
        };
    } = {
        selectBlock: {
            title: "Add Block",
            leftIcon: "close",
            onClickLeft: () => {
                setModal(ModalType.AddBlock, false);
            },
        },
        selectTemplate: {
            title: "Block Templates",
            leftIcon: "arrow_left",
            onClickLeft: () => {
                setPage(ModalPage.SelectBlock);
            },
        },
    };

    return (
        <ModalDetachedBox
            isOpen={modalState.addBlock}
            setIsOpen={isOpen => {
                setModal(ModalType.AddBlock, isOpen);
            }}
            onClickLeft={pages[page].onClickLeft}
            leftIcon={pages[page].leftIcon}
            title={pages[page].title}
        >
            <Page page={page} setPage={setPage} />
        </ModalDetachedBox>
    );
}
