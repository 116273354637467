import { NextFont } from "@next/font/dist/types";
import {
    inter,
    anekLatin,
    literata,
    monaSans,
    pilcrowRounded,
    satoshi,
    zodiak,
    unbounded,
    bitter,
    vollkorn,
    antonio,
    splineSans,
    supreme,
    alpino,
    bevellier,
    plusJakartaSans,
    teko,
    gambetta,
} from "utilities/fonts";
import { Font } from "types";

const FONT_OPTIONS: { value: Font; label: string; className: string }[] = [
    {
        value: Font.literata,
        label: "Literata",
        className: literata.className,
    },
    {
        value: Font.zodiak,
        label: "Zodiak",
        className: zodiak.className,
    },
    {
        value: Font.inter,
        label: "Inter",
        className: inter.className,
    },
    {
        value: Font.anekLatin,
        label: "Anek Latin",
        className: anekLatin.className,
    },
    {
        value: Font.monaSans,
        label: "Mona Sans",
        className: monaSans.className,
    },
    {
        value: Font.pilcrowRounded,
        label: "Pilcrow Rounded",
        className: pilcrowRounded.className,
    },
    {
        value: Font.satoshi,
        label: "Satoshi",
        className: satoshi.className,
    },
    {
        value: Font.unbounded,
        label: "Unbounded",
        className: unbounded.className,
    },
    {
        value: Font.bitter,
        label: "Bitter",
        className: bitter.className,
    },
    {
        value: Font.vollkorn,
        label: "Vollkorn",
        className: vollkorn.className,
    },
    {
        value: Font.antonio,
        label: "Antonio",
        className: antonio.className,
    },
    {
        value: Font.splineSans,
        label: "Spline Sans",
        className: splineSans.className,
    },
    {
        value: Font.supreme,
        label: "Supreme",
        className: supreme.className,
    },
    {
        value: Font.alpino,
        label: "Alpino",
        className: alpino.className,
    },
    {
        value: Font.bevellier,
        label: "Bevellier",
        className: bevellier.className,
    },
    {
        value: Font.plusJakartaSans,
        label: "Plus Jakarta Sans",
        className: plusJakartaSans.className,
    },
    {
        value: Font.teko,
        label: "Teko",
        className: teko.className,
    },
    {
        value: Font.gambetta,
        label: "Gambetta",
        className: gambetta.className,
    },
];

export const fontOptions = FONT_OPTIONS.map(option => ({
    value: option.value,
    label: (
        <div className="flex flex-row items-center text-sm">
            <span className={option.className}>{option.label}</span>
        </div>
    ),
})).sort((a, b) => a.value.localeCompare(b.value));

export const fontMap: { [key in Font]: NextFont } = {
    literata: literata,
    satoshi: satoshi,
    zodiak: zodiak,
    pilcrowRounded: pilcrowRounded,
    monaSans: monaSans,
    inter: inter,
    anekLatin: anekLatin,
    unbounded: unbounded,
    bitter: bitter,
    vollkorn: vollkorn,
    antonio: antonio,
    splineSans: splineSans,
    supreme: supreme,
    alpino: alpino,
    bevellier: bevellier,
    plusJakartaSans: plusJakartaSans,
    teko: teko,
    gambetta: gambetta,
};

export const DEFAULT_FONT_CLASS = inter.className;

export function getProfileFont(font: Font) {
    return fontMap[font]?.className || inter.className;
}

export const getRandomFont = (): Font => {
    const randomIndex = Math.floor(Math.random() * FONT_OPTIONS.length);
    return FONT_OPTIONS[randomIndex].value;
};
