import {
    InputType,
    propertyMap,
} from "components/profile/BlockEditor/properties";
import { useState } from "react";
import { Property } from "types";
import useBlockEditor from "hooks/useBlockEditor";
import LinkEditor from "../PropertyInput/LinkEditor";
import SocialLinkEditor from "../PropertyInput/SocialLinkEditor";
import { DragAndDropList } from "../CarouselEditor/DragAndDrop";
import BlockEditorDialog from "../BlockEditorDialog";
import SpecialPropertyInput from "../PropertyInput/SpecialPropertyInput";
import InputPreview from "../PropertyInput/InputPreview";
import FAQEditor from "../PropertyInput/FAQEditor";
import { useUITheme } from "hooks/useUITheme";
import { useSelector } from "react-redux";
import { selectBlockEditorLoading } from "store/blockEditor";
import { IconsMap } from "components/ui/Icon";
import FormFieldsEditor from "../PropertyInput/FormFieldsEditor.tsx";

type PropertyNameAndDescriptionProps = {
    name: string;
    description?: string;
    isLoading: boolean;
    color: string;
    hidden?: boolean;
};

const PropertyNameAndDescription = ({
    name,
    description,
    isLoading,
    color,
    hidden,
}: PropertyNameAndDescriptionProps) => {
    const uiTheme = useUITheme(true);
    if (hidden) return null;
    return (
        <div className="flex flex-col flex-1">
            <p
                className="font-semibold text-sm text-uiPrimary"
                style={{
                    color: uiTheme.primary,
                }}
            >
                {name}
            </p>
            {description && (
                <p
                    className="text-xs text-uiSecondary"
                    style={{
                        color: uiTheme.secondary,
                    }}
                >
                    {description}
                </p>
            )}
            {isLoading ? (
                <IconsMap.spinner className="animate-spin" color={color} />
            ) : null}
        </div>
    );
};

type Props = {
    property: Property;
    value: any;
};

// TODO — unify this and InputPreview
export default function PropertyRow({ property, value }: Props) {
    const { updateProperty } = useBlockEditor();
    const propertyItem = propertyMap[property];
    const [showEditProperty, setShowEditProperty] = useState(false);
    const theme = useUITheme(true);
    const isLoading = useSelector(selectBlockEditorLoading);
    const _onClick = () => {
        setShowEditProperty(true);
    };

    if (!propertyItem) {
        return null;
    }

    if (propertyItem.type === InputType.Links) {
        return <LinkEditor links={value} />;
    }

    if (propertyItem.type === InputType.SocialLinks) {
        return <SocialLinkEditor links={value} />;
    }
    if (propertyItem.type === InputType.FAQ) {
        return <FAQEditor questions={value} />;
    }

    if (propertyItem.type === InputType.Dnd) {
        return <DragAndDropList childrenBlocks={value} />;
    }

    if (propertyItem.type === InputType.FormFields) {
        return <FormFieldsEditor fields={value} />;
    }

    return (
        <>
            <div key={property} className="flex flex-col px-4 gap-4 my-2">
                <PropertyNameAndDescription
                    name={propertyItem.name}
                    description={propertyItem.description}
                    isLoading={isLoading}
                    color={theme.secondary}
                />
                <InputPreview
                    property={property}
                    value={value}
                    updateProperty={updateProperty}
                    onClick={_onClick}
                />
            </div>
            <BlockEditorDialog
                show={showEditProperty}
                onHide={() => {
                    setShowEditProperty(false);
                }}
            >
                <SpecialPropertyInput
                    property={property}
                    value={value}
                    setShow={setShowEditProperty}
                />
            </BlockEditorDialog>
        </>
    );
}
