import type { Dispatch, DragEvent, ReactNode, SetStateAction } from "react";
import { shallowEqual } from "fast-equals";
import { useCustomCompareEffect } from "use-custom-compare";
import { toast } from "sonner";
import useFileUploader from "./hooks/useFileUploader";
import { ReducerActionType } from "./state/reducer";

type FileUploaderBlockProps = {
    changeInputFile: Dispatch<SetStateAction<Partial<Blob> | undefined>>;
    children: ReactNode;
};

export default function FileUploaderBlock(props: FileUploaderBlockProps) {
    const { changeInputFile, children } = props;

    const { createMediaBlock, data, error, dispatch } = useFileUploader();

    useCustomCompareEffect(
        () => {
            if (data.fileList.length === 0) return;

            if (data.fileList[0]) {
                const latestImage = data.fileList[data.fileList.length - 1];
                const name = latestImage.name;

                const reader = new FileReader();
                reader.readAsDataURL(latestImage);
                reader.onloadend = function () {
                    const base64data = reader.result;
                    const file = {
                        name,
                        file: base64data,
                    } as Partial<Blob>;

                    changeInputFile(file);
                    void toast.promise(createMediaBlock, {
                        loading: "Creating a new media block...",
                        success: "Media block created",
                        error,
                    });

                    dispatch({ type: ReducerActionType.clearFileList });
                };
            }
        },
        [data, changeInputFile, createMediaBlock, error, dispatch],
        (prev, next) => shallowEqual(prev, next),
    );

    const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        dispatch({ type: ReducerActionType.addToDropZone, inDropZone: true });
    };

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (!event.dataTransfer) return;

        event.dataTransfer.dropEffect = "move";
        dispatch({ type: ReducerActionType.addToDropZone, inDropZone: true });
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (!event.dataTransfer) return;

        const files = [...event.dataTransfer.files];

        if (files) {
            dispatch({ type: ReducerActionType.addToFileList, files });
            dispatch({
                type: ReducerActionType.addToDropZone,
                inDropZone: false,
            });
        }
    };

    return (
        <div
            id="fileuploaderdnd-container"
            className="fileuploaderdnd-container"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
        >
            {children}
        </div>
    );
}
