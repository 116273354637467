import { useState, useEffect, useRef } from "react";

export default function useIsScrolling(): boolean {
    const [isScrolling, setIsScrolling] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        function handleScroll() {
            setIsScrolling(true);
            if (timeoutRef.current) clearTimeout(timeoutRef.current);

            timeoutRef.current = setTimeout(() => {
                setIsScrolling(false);
            }, 500);
        }

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        };
    }, []);

    return isScrolling;
}
