import { useTheme } from "hooks/useTheme";
import { autoQualityImage } from "utilities/cloudinaryUtils";
import { clsx } from "clsx";
import StoreLogo from "./StoreLogo";
import { IconsMap } from "components/ui/Icon";
import { Settings } from "types";
import { BioData } from "./types";

const getResponsiveFontSize = (
    text: string,
    mobileView: boolean,
    hideCover: boolean,
) => {
    const textLength = text.length;
    const baseFontSize = "text-2xl self-center pt-4";
    let fontSize = mobileView ? "text-xl self-center" : baseFontSize;
    if (!hideCover) {
        if (textLength > 38 && mobileView) {
            fontSize = "text-base self-center pt-3";
        }
        if (textLength > 50) {
            fontSize = mobileView
                ? "text-base self-end leading-5"
                : "text-2xl self-center pt-4";
        }
        if (textLength > 60 && mobileView) {
            fontSize = "text-sm self-end";
        }
        if (textLength > 84) {
            fontSize = mobileView
                ? "text-xs self-end pb-1"
                : "text-xl self-center pt-2";
        }
    }
    if (hideCover && mobileView) {
        if (textLength > 58) {
            fontSize = "text-xs self-end";
        }
    }
    return fontSize;
};

type Props = {
    hideCover: boolean;
    hideBio: boolean;
    hideLogo: boolean;
    hideName: boolean;
    mobileView: boolean;
    data: BioData;
};

export default function StyleThree({
    hideCover,
    hideBio,
    hideLogo,
    hideName,
    mobileView,
    data,
}: Props) {
    const theme = useTheme();

    const coverStyle = hideCover
        ? {}
        : {
              backgroundImage: `url(${autoQualityImage(data.cover_url)})`,
              borderRadius: theme.block_radius,
          };

    return (
        <div
            className={clsx("flex flex-1 flex-col pt-4", {
                "text-center justify-center": !hideCover,
            })}
        >
            <div
                className={clsx({
                    "w-full bg-cover bg-center relative": !hideCover,
                    "h-[360px]": !hideCover && !mobileView,
                    "h-[180px]": !hideCover && mobileView,
                    "flex mx-2": hideCover,
                })}
                style={coverStyle}
            >
                {!data.cover_url && !hideCover && (
                    <div
                        className={clsx(
                            "flex justify-center items-center w-full theme-tertiary-background",
                            {
                                "h-[360px]": !hideCover && !mobileView,
                                "h-[180px]": !hideCover && mobileView,
                            },
                        )}
                        style={{
                            borderRadius: theme.block_radius,
                        }}
                    >
                        <IconsMap.image
                            className="theme-secondary-text"
                            size={48}
                        />
                    </div>
                )}

                {!hideLogo && (
                    <div
                        className={clsx("flex", {
                            "absolute bottom-0 left-3 translate-y-[70%]":
                                !hideCover,
                        })}
                    >
                        <div className="mr-4">
                            <StoreLogo
                                borderRadius={theme.button_radius}
                                imageUrl={data.profile_url}
                            />
                        </div>
                        {!hideName && (
                            <p
                                className={clsx(
                                    `break-words mt-2 font-bold theme-primary-text text-center w-inherit ${getResponsiveFontSize(
                                        data.name,
                                        mobileView,
                                        hideCover,
                                    )}`,
                                    {
                                        "self-center text-2xl": hideCover,
                                    },
                                )}
                            >
                                {data.name}
                            </p>
                        )}
                    </div>
                )}
            </div>
            <div
                className={clsx("text-start mx-4 mb-6", {
                    "mt-4": hideLogo,
                    "mt-20": !hideCover && !hideLogo && mobileView,
                    "mt-28": !hideCover && !hideLogo && !mobileView,
                })}
            >
                {hideLogo && !hideName && (
                    <p className="text-2xl font-bold theme-primary-text">
                        {data.name}
                    </p>
                )}
                {!hideBio && (
                    <p className="text-sm theme-secondary-text mt-3">
                        {data.description}
                    </p>
                )}
            </div>
        </div>
    );
}
