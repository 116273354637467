import { v4 as uuidv4 } from "uuid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useState } from "react";
import Button from "components/ui/Button";
import { Link } from "types";
import useBlockEditor from "hooks/useBlockEditor";
import SocialLinkRow from "./SocialLinkEditorRow";
import InnerModal from "../../BlockEditorDialog";
import { socialIconOptions } from "../../properties";
import Select from "components/ui/Select";
import UIButton from "components/ui/UIButton";

function deleteFromArray(array: any[], index: number) {
    const newArray = [...array];
    newArray.splice(index, 1);
    return newArray;
}

function updateElementInArray(array: any[], index: number, newValue: any) {
    const newArray = [...array];
    newArray[index] = newValue;
    return newArray;
}

type Props = {
    links?: Link[];
};

export default function SocialLinkEditor(props: Props) {
    const { links = [] } = props;

    const [showIconPicker, setShowIconPicker] = useState(false);
    const [selectedLinkIndex, setSelectedLinkIndex] = useState(-1);
    const { updateProperty } = useBlockEditor();

    const _onUrlLink = (index: number) => {
        const t = window.prompt("URL", links[index].url);
        if (t !== null) {
            const newLinks = updateElementInArray(links, index, {
                ...links[index],
                url: t,
            });
            updateProperty("social_links", newLinks);
        }
    };

    const _onClickDelete = (index: number) => {
        const name = links[index].name;
        const res = window.confirm(
            `Are you sure you want to delete link for ${name}?`,
        );
        if (!res) return;
        const newLinks = deleteFromArray(links, index);
        updateProperty("social_links", newLinks);
    };

    const _addLink = () => {
        const newLinks = [
            ...links,
            { id: uuidv4(), name: "Link Name", url: "", icon: "link" },
        ];
        updateProperty("social_links", newLinks);
    };

    const _onChangeIcon = (index: number, icon: string) => {
        const newLinks = updateElementInArray(links, index, {
            ...links[index],
            icon: icon,
        });
        updateProperty("social_links", newLinks);
    };

    function onDragEnd(result: any) {
        const newLinks = [...links];
        const [removed] = newLinks.splice(result.source.index, 1);
        newLinks.splice(result.destination.index, 0, removed);
        updateProperty("social_links", newLinks);
    }

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="carousel">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="transition-all duration-200"
                        >
                            <span style={{ display: "none" }}>
                                {provided.placeholder}
                            </span>
                            <div className="gap-4 px-4 flex flex-col">
                                {links.map((item, i) => (
                                    <Draggable
                                        draggableId={item.id.toString()}
                                        index={i}
                                        key={item.id}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <SocialLinkRow
                                                    index={i}
                                                    link={item}
                                                    linksLength={links.length}
                                                    onClickDelete={() =>
                                                        _onClickDelete(i)
                                                    }
                                                    onClickEditUrl={() =>
                                                        _onUrlLink(i)
                                                    }
                                                    onShowIconPicker={value => {
                                                        setShowIconPicker(
                                                            value,
                                                        );
                                                    }}
                                                    setSelectedLinkIndex={index => {
                                                        setSelectedLinkIndex(
                                                            index,
                                                        );
                                                    }}
                                                    provided={provided}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            </div>
                            <div
                                className="p-4 justify-center flex flex-row"
                                style={{
                                    opacity: snapshot.isDraggingOver ? 0 : 1,
                                }}
                            >
                                <UIButton
                                    isWide
                                    title="Add Social"
                                    onClick={_addLink}
                                    allowDarkMode
                                />
                            </div>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <InnerModal
                show={showIconPicker}
                onHide={() => {
                    setShowIconPicker(false);
                }}
            >
                <div style={{ minHeight: "35vh" }}>
                    <Select
                        options={socialIconOptions}
                        onChangeValue={value => {
                            setShowIconPicker(false);
                            _onChangeIcon(selectedLinkIndex, value);
                        }}
                        value={links[selectedLinkIndex]?.icon}
                    />
                </div>
            </InnerModal>
        </>
    );
}
