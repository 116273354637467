import CloudinaryWidget from "components/profile/CloudinaryWidget";
import { IconsMap } from "components/ui/Icon";
import { useUITheme } from "hooks/useUITheme";

interface Props {
    imageUrl?: string;
    onImageUrlChange: (imageUrl?: string) => void;
}

const ImageInput = ({ imageUrl, onImageUrlChange }: Props) => {
    const theme = useUITheme();

    const handleUpload = (error: any, res: any, url: string) => {
        onImageUrlChange(url);
    };

    const handleClearImage = () => {
        onImageUrlChange(undefined);
    };

    return (
        <div className="relative">
            <CloudinaryWidget onUpload={handleUpload}>
                <div
                    className="h-32 w-32 cursor-pointer rounded-full bg-cover bg-center bg-no-repeat relative overflow-hidden transition-all duration-200 flex justify-center items-center"
                    style={{
                        backgroundColor: theme.quaternary,
                        backgroundImage: `url(${imageUrl})`,
                    }}
                >
                    {imageUrl ? null : (
                        <IconsMap.image
                            className="w-8 h-8"
                            color={theme.secondary}
                        />
                    )}
                </div>
            </CloudinaryWidget>
            {imageUrl ? (
                <div
                    onClick={handleClearImage}
                    className="p-2 absolute rounded-full right-0 top-0 cursor-pointer brightness-95"
                    style={{ backgroundColor: theme.background_color }}
                >
                    <IconsMap.close className="w-4 h-4" color={theme.primary} />
                </div>
            ) : null}
        </div>
    );
};

export default ImageInput;
