import { fromLegacy, toLegacy } from "@snackpass/time";
import { DateTime } from "luxon";
import {
    IDateTimeRangeSchema,
    IHoursSchema,
    ITimeRangeSchema,
} from "@snackpass/snackpass-types";
import _ from "lodash";

type ResolveStoreHoursOptions = {
    /**
     * Set specific dates to resolve hours. If not set, hours are resolved for this week.
     * Dates should be corrected to UTC.
     */
    forDates: Date[];
};

type ResolveStoreHoursReturn = {
    hours: ITimeRangeSchema[];
    dateTimeHours: IDateTimeRangeSchema[];
};

/**
 * Initializes a datetime using the local timezone and converts to UTC for manipulation
 * @param zone
 */
function localUTCDateTime(zone: string) {
    return DateTime.local({ zone }).setZone("utc", { keepLocalTime: true });
}

/**
 * Gets all the dates for this ISO week
 * @param zone
 */
const datesThisWeek = (zone: string): Date[] =>
    _.range(0, 7).map(index =>
        localUTCDateTime(zone)
            .startOf("week")
            .set({ weekday: index + 1 })
            .toJSDate(),
    );

/**
 * Converts minute offsets with respect to the date into minute offsets with respect to the week
 * @param {Date} date
 * @param {Number} minute
 */
function dayMinutes2WeekMinutes(date: Date, minute: number) {
    // To ignore the effects of DST shift, the zone is set to UTC such that calculating
    // the minute offsets when DST occurs in the middle of the week
    // does not affect the start-day offsets
    const dt = DateTime.fromJSDate(date, { zone: "utc" });

    const startOfWeek = dt.startOf("week");
    const startOfDay = dt.startOf("day");

    const startDayMinutes = Math.floor(
        // `.as("minutes")` can return a non-integer
        startOfDay.diff(startOfWeek).as("minutes"),
    );

    return startDayMinutes + minute;
}

/**
 * Creates a map of dates to its respective ranges from an array of ranges.
 *
 * ```typescript
 * [{ date: Date("2023-10-12"), start: 1, end: 2 }, { date: Date("2023-10-12"), start: 3, end: 4 }] ->
 * { "2023-10-12": [{ start: 1, end: 2 }, { start: 3, end: 4 }] }
 * ```
 * @param {IDateTimeRangeSchema[]} hours
 * @param zone
 */
const groupByDate = (hours: IDateTimeRangeSchema[], zone = "utc") =>
    _.groupBy(hours, hour => dateKeyFrom(hour.date, zone));

/**
 * Converts an array of ranges into a list of formatted dates (YYYY-MM-DD)
 * @param {IDateTimeRangeSchema[]} hours
 * @param zone
 */
const getDateList = (hours: IDateTimeRangeSchema[], zone = "utc") =>
    hours.map(range => dateKeyFrom(range.date, zone));

/**
 * Converts minute offsets with respect to the date's week into minute offsets with respect to the date
 * @param {Date} date
 * @param {Number} minute
 */
function weekMinutes2DayMinutes(date: Date, minute: number) {
    const dt = DateTime.fromJSDate(date, { zone: "utc" });

    const startOfDay = dt.startOf("week").set({ minute }).startOf("day");
    const startOfWeek = dt.startOf("week");

    const startOfDayMinutes = startOfDay.diff(startOfWeek).as("minutes");

    return Math.floor(minute - startOfDayMinutes);
}

/**
 * Converts minute offsets with respect to the week into the 1-indexed weekday
 * @param {Number} minutes
 * @param {String} zone
 */
const weekMinutesToWeekday = (minutes: number) =>
    Math.floor((minutes % (60 * 24 * 7)) / (60 * 24)) + 1;

const yearMonthDayFormat = "yyyy-MM-dd";
const dateKeyFrom = (date: Date, zone = "utc") =>
    DateTime.fromJSDate(date, { zone }).toFormat(yearMonthDayFormat);

/**
 * Given specific dates to resolve hours for, return selected special hours overrides and generate regular hours
 * for each specific dates.
 * @param storeHours
 * @param options
 */
export function filterSelectedHoursByDates(
    storeHours: IHoursSchema,
    options: Required<ResolveStoreHoursOptions>,
) {
    const { local, special = [] } = storeHours;

    const { forDates } = options;

    const selectedDates = forDates.map(date => dateKeyFrom(date));
    const dateKeys = new Set(selectedDates);

    // filter special hours based on selected dates
    const selectedSpecialHours = special.filter(hour =>
        dateKeys.has(dateKeyFrom(hour.date)),
    );

    // for each weekday, group the regular hours assigned to it
    const regularHoursByWeekday = _.groupBy(local, hours =>
        weekMinutesToWeekday(hours.start),
    );
    const availableRegularWeekdays = new Set(
        local.map(hour => weekMinutesToWeekday(hour.start)),
    );

    const selectedRegularHours: IDateTimeRangeSchema[] = [];

    // generate/duplicate regular hours time ranges for selected dates
    for (const date of dateKeys) {
        const dt = DateTime.fromFormat(date, yearMonthDayFormat, {
            zone: "utc",
        });
        const weekday = dt.weekday;

        if (availableRegularWeekdays.has(weekday)) {
            // assign date to weekday
            const dateTimeRangeForWeekday: IDateTimeRangeSchema[] =
                regularHoursByWeekday[weekday].map(hour => ({
                    date: dt.toJSDate(),
                    start: weekMinutes2DayMinutes(dt.toJSDate(), hour.start),
                    end: weekMinutes2DayMinutes(dt.toJSDate(), hour.end),
                }));

            selectedRegularHours.push(...dateTimeRangeForWeekday);
        }
    }

    return {
        regularHours: selectedRegularHours,
        specialHours: selectedSpecialHours,
        selectedDates,
    };
}

/**
 * Resolves special store hours overrides into regular store hours. `resolveStoreHours` is used to generate
 * the `local` field of the `StoreHoursSchema`, to be consumed by clients. `resolveStoreHours` should ignore
 * any special hours outside the current week, while adding, removing and overriding hours within the current
 * week.
 * @param {IHoursSchema} storeHours
 * @param {ResolveStoreHoursOptions} options if not set, it defaults to the current week
 */
export function _resolveStoreHours(
    storeHours: IHoursSchema,
    options?: ResolveStoreHoursOptions,
): ResolveStoreHoursReturn {
    const { zone } = storeHours;

    const hoursOptions: ResolveStoreHoursOptions = {
        forDates: datesThisWeek(zone),
    };

    // All dates after this point are all in UTC

    if (options && options.forDates.length > 0) {
        hoursOptions.forDates = options.forDates;
    }

    // Both special and regular hours are filtered by the selected dates
    const { selectedDates, specialHours, regularHours } =
        filterSelectedHoursByDates(storeHours, hoursOptions);

    // range.start === range.end indicates that a store is closed
    // this is handled within RDB and Snackface
    const [openSpecialHours, closedSpecialHours] = _.partition(
        specialHours,
        range => range.start !== range.end,
    );

    const regularDates = getDateList(regularHours);

    const specialOpenDates = new Set(getDateList(openSpecialHours));
    const specialClosedDates = new Set(getDateList(closedSpecialHours));

    let regularOpenDates = new Set(regularDates);

    if (specialClosedDates.size > 0) {
        regularOpenDates = new Set(
            regularDates.filter(date => !specialClosedDates.has(date)),
        );
    }

    const regularHoursByDate = groupByDate(regularHours);
    const specialHoursByDate = groupByDate(specialHours);

    const dateTimeRangeHours: IDateTimeRangeSchema[] = [];

    for (const date of selectedDates) {
        if (specialOpenDates.has(date)) {
            dateTimeRangeHours.push(...specialHoursByDate[date]);
        } else if (regularOpenDates.has(date)) {
            dateTimeRangeHours.push(...regularHoursByDate[date]);
        }
    }

    const timeRangeHours: ITimeRangeSchema[] = dateTimeRangeHours.map(hour => ({
        start: dayMinutes2WeekMinutes(hour.date, hour.start),
        end: dayMinutes2WeekMinutes(hour.date, hour.end),
    }));

    return {
        hours: timeRangeHours,
        dateTimeHours: dateTimeRangeHours,
    };
}

export const formatLegacySpecialHours = (hours: IHoursSchema) => {
    const special = parseSpecialHours(hours?.special);

    // Remove this hack when fully migrated to @snackpass/time
    return {
        ...hours,
        special: special.map(hour => ({
            ...hour,
            date: DateTime.fromJSDate(hour.date)
                // legacy hours are saved in UTC from RDB
                .setZone("utc")
                // @snackpass/time expects time in store's TZ
                .setZone(hours.zone, {
                    keepLocalTime: true,
                })
                .toJSDate(),
        })),
    };
};

export function weekDayFrom(date: Date): number {
    return DateTime.fromJSDate(date, { zone: "utc" }).weekday;
}

export const parseSpecialHours = (
    special?: IDateTimeRangeSchema[],
): IDateTimeRangeSchema[] =>
    special?.map(hour => ({
        ...hour,
        date: new Date(hour.date),
    })) ?? [];

export function resolveStoreHours(
    hours: IHoursSchema,
    dates?: Date[],
): IHoursSchema {
    const _hours = toLegacy(fromLegacy(formatLegacySpecialHours(hours)));

    const { hours: local } = _resolveStoreHours(_hours, {
        forDates: dates ?? [],
    });

    return {
        ..._hours,
        local,
    };
}

export type WeekdayNumbers = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const FULL_WEEK_RANGE = {
    start: 0,
    end: 24 * 60 * 7 - 1,
};

export type HourRange = {
    day: string;
    time: string;
    isSpecial: boolean;
};

export function getHourRanges(hours: IHoursSchema): HourRange[] {
    const { local, zone } = hours;

    const dateTimeFromWeekMinute = (minute: number) =>
        DateTime.now().startOf("week").set({ minute });

    const toTimeFromWeekMinute = (minute: number) =>
        dateTimeFromWeekMinute(minute).toFormat("h:mm a");

    const dayNameFrom = (weekday: WeekdayNumbers) =>
        DateTime.now().startOf("week").set({ weekday }).toFormat("ccc");

    const isDateInThisWeek = (date: Date) =>
        DateTime.now().setZone("utc").startOf("week") <=
            DateTime.fromJSDate(date, { zone: "utc" }) &&
        DateTime.fromJSDate(date, { zone: "utc" }) <=
            DateTime.now().setZone("utc").endOf("week");

    const toTimeFromDayMinute = (day: WeekdayNumbers, minute: number) =>
        DateTime.now()
            .startOf("week")
            .set({ weekday: day, minute })
            .toFormat("h:mm a");

    if (
        local.length === 1 &&
        local[0].start === FULL_WEEK_RANGE.start &&
        local[0].end === FULL_WEEK_RANGE.end
    ) {
        return [
            {
                day: "Everyday",
                time: `${toTimeFromWeekMinute(
                    local[0].start,
                )} - ${toTimeFromWeekMinute(local[0].end)}`,
                isSpecial: false,
            },
        ];
    }

    const resolvedHours = resolveStoreHours({
        zone,
        special: hours.special,
        local,
    });

    const special = parseSpecialHours(hours.special).filter(hour =>
        isDateInThisWeek(hour.date),
    );

    const specialDays = new Set(special.map(hour => weekDayFrom(hour.date)));

    const localDays = new Set(
        resolvedHours.local.map(
            hour => dateTimeFromWeekMinute(hour.start).weekday,
        ),
    );
    const specialByWeekday = _.groupBy(special, hour => weekDayFrom(hour.date));
    const localByWeekday = _.groupBy(
        resolvedHours.local,
        hour => dateTimeFromWeekMinute(hour.start).weekday,
    );

    const result = _.range(1, 8).flatMap(w => {
        const weekday = w as WeekdayNumbers;
        if (specialDays.has(weekday)) {
            return specialByWeekday[weekday].map(hour => {
                const isClosed = hour.start === hour.end;

                return {
                    day: dayNameFrom(weekday),
                    time: !isClosed
                        ? `${toTimeFromDayMinute(
                              weekday,
                              hour.start,
                          )} - ${toTimeFromDayMinute(weekday, hour.end)}`
                        : "Closed",
                    isSpecial: true,
                };
            });
        } else if (localDays.has(weekday)) {
            const localHours = localByWeekday[weekday];
            const timeRanges = localHours.map(
                hour =>
                    `${toTimeFromWeekMinute(
                        hour.start,
                    )} - ${toTimeFromWeekMinute(hour.end)}`,
            );
            return [
                {
                    day: dayNameFrom(weekday),
                    time: timeRanges.join(", "),
                    isSpecial: false,
                },
            ];
        } else {
            return [
                {
                    day: dayNameFrom(weekday),
                    time: "Closed",
                    isSpecial: false,
                },
            ];
        }
    });

    return result;
}
