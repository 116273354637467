const fetcher = async (url: string) => {
    const response = await fetch(url);

    if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Unknown Error.");
    }

    return response.json();
};

export default fetcher;
