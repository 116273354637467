import { HydratedBlock } from "types";
import { isChildOfParentBlock } from "utilities/blockTypeUtils";

export default function Title(props: {
    block: HydratedBlock;
    className?: string;
}) {
    const { block, className } = props;
    const title = block.properties?.title;
    const _isChildOfParentBlock = isChildOfParentBlock(block);

    if (!title) return null;
    if (_isChildOfParentBlock) return null;

    return <p className={`${className} theme-primary-text`}>{title}</p>;
}
