import Button from "components/ui/Button";
import { IconsMap } from "components/ui/Icon";
import { useUITheme } from "hooks/useUITheme";
import { ButtonMode } from "types";

interface Props {
    option: string;
    onDelete: () => void;
    onEdit: (newOption: string) => void;
    provided: any;
}

const FormFieldOptionRow = ({ option, onDelete, onEdit, provided }: Props) => {
    const theme = useUITheme();

    return (
        <div className="flex flex-row items-center flex-1 gap-4 cursor-default">
            <div
                className="flex flex-row gap-4 !cursor-auto"
                data-rbd-drag-handle-context-id={
                    provided.dragHandleProps?.[
                        "data-rbd-drag-handle-context-id"
                    ]
                }
            >
                <Button
                    icon="trash"
                    iconSize={16}
                    mode={ButtonMode.outline}
                    onClick={onDelete}
                    color={theme.primary}
                />
            </div>
            <input
                type="text"
                value={option}
                onChange={e => onEdit(e.target.value)}
                className="p-2 border rounded-xl grid grid-cols-4 justify-between items-center gap-4 flex-1 overflow-hidden !cursor-auto"
                style={{
                    backgroundColor: theme.background_color,
                    borderColor: theme.tertiary,
                }}
                data-rbd-drag-handle-context-id={
                    provided.dragHandleProps?.[
                        "data-rbd-drag-handle-context-id"
                    ]
                }
            />
            <IconsMap.drag size={24} className="cursor-grab" />
        </div>
    );
};

export default FormFieldOptionRow;
