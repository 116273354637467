import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
    useCustomCompareCallback,
    useCustomCompareEffect,
} from "use-custom-compare";
import { shallowEqual } from "fast-equals";
import usePrevious from "./usePrevious";
import { HydratedBlock } from "types";
import { selectActivePageBlock, selectProfileId } from "store/profileSlice";

type BlockReorderReturn = {
    move: (newOrder: HydratedBlock[]) => void;
    localBlocksState?: HydratedBlock[];
    blockIds?: string[];
    blockInitialIndex: number;
    setBlockInitialIndex: Dispatch<SetStateAction<number>>;
};

export default function useBlockReorder(): BlockReorderReturn {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const page = useSelector(selectActivePageBlock);
    const pid = useSelector(selectProfileId);

    const filteredBlocks = page?.children;
    const [blockInitialIndex, setBlockInitialIndex] = useState(-1);
    const [localBlocksState, setLocalBlocksState] = useState(filteredBlocks);
    const [moveFnCalled, setMoveFnCalled] = useState(false);
    const blockIds = localBlocksState?.map(b => b.id);
    const previousLocalBlocks = usePrevious(localBlocksState);

    useCustomCompareEffect(
        () => {
            // this useEffect should only be run to keep local blocks state in sync when
            // block actions other than "move" are run (e.g. creating, deleting)
            if (moveFnCalled) return;

            // prevent this hook + reorder animations from re-running after a reorder
            if (shallowEqual(filteredBlocks, previousLocalBlocks)) return;

            // sync any changes to filteredBlocks (e.g. creating, deleting, etc.) with local blocks state
            setLocalBlocksState(filteredBlocks);
        },
        [filteredBlocks, moveFnCalled, previousLocalBlocks],
        (prev, next) => shallowEqual(prev, next),
    );

    const move = useCustomCompareCallback(
        async (newOrder: HydratedBlock[]) => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
            if (shallowEqual(filteredBlocks, newOrder)) return;
            setLocalBlocksState(newOrder);
            setMoveFnCalled(true);

            const block = filteredBlocks?.[blockInitialIndex];
            const blockTargetIndex = newOrder.findIndex(
                b => b.id === block?.id,
            );

            await fetch(`/api/v0.1/profiles/${pid}/blocks/${block?.id}/move`, {
                method: "POST",
                headers: { "content-type": "application/json" },
                body: JSON.stringify({
                    targetParentId: page?.id,
                    targetIndex: blockTargetIndex,
                }),
            });

            timeoutRef.current = setTimeout(() => {
                setMoveFnCalled(false);
            }, 50);
        },
        [pid, page, blockInitialIndex, filteredBlocks],
        (prev, next) => shallowEqual(prev, next),
    );

    return {
        move,
        localBlocksState,
        blockIds,
        blockInitialIndex,
        setBlockInitialIndex,
    };
}
