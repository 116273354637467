import { useEffect, useRef, useState } from "react";
import { Media } from "types";
import { SLIDE_DURATION } from "utilities/constants";

export function useStories(media_list: Media[]) {
    const [activeIndex, setActiveIndex] = useState(0);
    const slideTimer = useRef<NodeJS.Timeout | null>(null);
    const [slideStartTime, setProgressStartTime] = useState(0);
    // TODO: move this to a hook
    //setting first slide duration if first slide exist to prevent directly jumping to the second slide
    const firstSlide = media_list[0];
    const firstSlideDuration =
        firstSlide && firstSlide.type === "video"
            ? Math.ceil((firstSlide.duration || 1) * 1000)
            : SLIDE_DURATION;
    const [duration, setDuration] = useState(firstSlideDuration);

    // TODO: move this to a hook
    const currHeight = useEffect(() => {
        // no timer if there's only one media
        if (media_list.length <= 1) {
            setActiveIndex(0);
            clearTimeout(slideTimer.current as NodeJS.Timeout);
            return;
        }

        clearTimeout(slideTimer.current as NodeJS.Timeout);

        const newActiveIndex = (activeIndex + 1) % media_list.length;
        const media = media_list[newActiveIndex];
        const newDuration =
            media.type === "video"
                ? Math.ceil(media.duration || 1) * 1000
                : SLIDE_DURATION;

        slideTimer.current = setTimeout(() => {
            setActiveIndex(newActiveIndex);
            setDuration(newDuration);
        }, duration);

        return () => {
            clearTimeout(slideTimer.current as NodeJS.Timeout);
        };
    }, [activeIndex, media_list]);

    useEffect(() => {
        // trigger start time when new slide is set
        setProgressStartTime(Date.now());
    }, [activeIndex]);
    return { activeIndex, setActiveIndex, slideStartTime, duration };
}
