import { useSelector } from "react-redux";
import { selectSnackpassStore } from "store/profileSlice";

export function MenuBlock() {
    const snackpassStore = useSelector(selectSnackpassStore);
    const productCategories = snackpassStore?.productCategories;
    return null;
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
            {productCategories?.map((category, i) => {
                // @ts-ignore
                if (!category.products) {
                    return null;
                }
                // @ts-ignore
                const firstProductWithImage = category.products.find(
                    // @ts-ignore
                    product => product.image,
                );

                return (
                    <div
                        key={i}
                        className="border border-gray-300 rounded-md overflow-hidden transform transition-transform hover:scale-105 hover:shadow-lg"
                    >
                        <h3 className="text-center bg-gray-200 p-4 m-0">
                            {category.name}
                        </h3>
                        {firstProductWithImage && (
                            <img
                                src={firstProductWithImage.image}
                                alt={firstProductWithImage.name}
                                className="w-full h-auto block"
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
}
