import classNames from "classnames";
import { PropertyOption } from "../properties";
import { Theme, UITheme } from "types";

type Props = {
    value: any;
    onValueChange: (value: any) => void;
    options: PropertyOption[];
    className?: string;
    theme: UITheme;
};
export function InputSelect({
    onValueChange,
    value,
    options,
    className,
    theme,
}: Props) {
    return (
        <select
            className={classNames(
                "border border-uiBorderColor p-2 text-sm cursor-pointer rounded-lg w-full hover:brightness-90 duration-200 transition-brightness",
                className,
            )}
            style={{
                backgroundColor: "transparent",
                color: theme.secondary,
            }}
            value={value}
            onChange={e => {
                onValueChange(e.target.value);
            }}
        >
            {options.map(option => {
                return (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                );
            })}
        </select>
    );
}
