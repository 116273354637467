import {
    propertyMap,
    PropertyTab,
} from "components/profile/BlockEditor/properties";
import { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    updateBlockEditor,
    updateBlockEditorTopLevel,
    setBlockEditorLoading,
    setBlockEditor,
    selectFromCarousel,
    setActivePropertyTab,
    selectBlockEditor,
    resetBlockEditor,
    clearBlockEditor,
} from "store/blockEditor";
import prependHttp from "utilities/prependHttp";
import { selectProfileId } from "store/profileSlice";
import {
    Block,
    BlockType,
    CaptionType,
    HydratedBlock,
    Property,
    TopLevelProperty,
} from "types";
import { getRichEmbedType } from "utilities/getRichEmbedType";
import { resetSettingsEditor, selectNewSettings } from "store/settingsEditor";
import { toast } from "sonner";
import { ModalType, setModal } from "store/modalsSlice";

export default function useBlockEditor() {
    const dispatch = useDispatch();
    const newBlock = useSelector(selectBlockEditor);
    const newSettings = useSelector(selectNewSettings);
    const fromCarousel = useSelector(selectFromCarousel);
    const pid = useSelector(selectProfileId);
    const [fetching, setFetching] = useState(false);

    const updateProperty = useCallback(
        async (property: Property | TopLevelProperty, newValue: any) => {
            if (propertyMap[property] && propertyMap[property].isTopLevel) {
                dispatch(
                    updateBlockEditorTopLevel({ property, value: newValue }),
                );
                return;
            }

            if (property === "embed_url") {
                newValue = prependHttp(newValue);
            }

            dispatch(
                updateBlockEditor({
                    property: property as Property,
                    value: newValue,
                }),
            );

            // special property handling
            if (property === "embed_url" && newValue) {
                dispatch(
                    updateBlockEditor({
                        property: "rich_embed_type",
                        value: getRichEmbedType(newValue),
                    }),
                );

                try {
                    dispatch(setBlockEditorLoading(true));
                    const response = await fetch(
                        `/api/v0.1/embed?url=${newValue}`,
                    );
                    const {
                        title,
                        description,
                        faviconUrl,
                        imageUrl,
                        color,
                        error,
                    } = await response.json();
                    if (!response.ok) {
                        dispatch(setBlockEditorLoading(false));
                        console.log(error);
                        alert(error.message);
                    }
                    dispatch(
                        updateBlockEditor({
                            property: "embed_title",
                            value: title,
                        }),
                    );
                    dispatch(
                        updateBlockEditor({
                            property: "embed_description",
                            value: description,
                        }),
                    );
                    dispatch(
                        updateBlockEditor({
                            property: "embed_favicon_url",
                            value: faviconUrl,
                        }),
                    );
                    // Make sure imageURL is set even if uploading embed to cloudinary errors
                    dispatch(
                        updateBlockEditor({
                            property: "embed_image_url",
                            value: imageUrl,
                        }),
                    );
                    dispatch(
                        updateBlockEditor({
                            property: "embed_image_color",
                            value: color,
                        }),
                    );
                    dispatch(setBlockEditorLoading(false));
                } catch (e) {
                    dispatch(setBlockEditorLoading(false));
                    alert(e);
                    return;
                }
            }

            if (property === "caption_type") {
                if (newValue === CaptionType.big) {
                    dispatch(
                        updateBlockEditor({
                            property: "brightness",
                            value: 75,
                        }),
                    );
                } else {
                    dispatch(
                        updateBlockEditor({
                            property: "brightness",
                            value: 100,
                        }),
                    );
                }
            }
        },
        [dispatch],
    );

    const returnToCarousel = () => {
        if (!fromCarousel) {
            return;
        }
        refetchBlockFromServer(fromCarousel);
    };

    const refetchBlockFromServer = async (bid: string) => {
        const response = await fetch(
            `/api/v0.1/profiles/${pid}/blocks/${bid}?hydrate=true`,
            {
                method: "GET",
            },
        );
        const data = await response.json();
        const { block }: { block: HydratedBlock } = data;
        dispatch(setBlockEditor(block));
        dispatch(setActivePropertyTab(PropertyTab.Carousel));
    };

    const reset = () => {
        dispatch(resetBlockEditor());
        dispatch(resetSettingsEditor({}));
    };

    const save = async () => {
        setFetching(true);
        // need to remove restricted fields from update and convert to non hydrated block
        const updates: Partial<Block> = {
            ...newBlock,
            parent: newBlock?.parent?.id || "",
            children: newBlock?.children.map(c => c.id),
        };
        delete updates.id;
        delete updates.profile;
        delete updates.parent;

        dispatch(setModal({ modal: ModalType.BlockEditor, visible: false }));

        const response = await fetch(
            `/api/v0.1/profiles/${pid}/blocks/${newBlock?.id}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    updates,
                }),
            },
        );
        setFetching(false);
        const data = await response.json();
        const { error }: { block: Block; error: any } = data;

        if (error) {
            toast.error(error);
            return;
        }

        if (fromCarousel) {
            returnToCarousel();
            return;
        }
        const blockEditorTimer = setTimeout(() => {
            dispatch(clearBlockEditor());
        }, 300);
        return () => clearTimeout(blockEditorTimer);
    };

    return {
        updateProperty,
        properties: newBlock?.properties,
        returnToCarousel,
        refetchBlockFromServer,
        fetching,
        reset,
        save,
    };
}
