import { uuidv4 } from "@firebase/util";
import * as Sentry from "@sentry/nextjs";
import { useCallback, useReducer, useState } from "react";
import type { Dispatch } from "react";
import { useSelector } from "react-redux";
import type { AnyAction } from "redux";
import { Block, BlockType, HydratedBlock, Media } from "types";
import {
    selectActivePageBlock,
    selectProfileId,
    selectUsername,
} from "store/profileSlice";
import fileUploadReducer from "components/profile/Block/FileUploaderBlock/state/reducer";

type UseFileUploaderReturn = {
    createMediaBlock: () => Promise<void>;
    error?: string;
    data: { fileList: Blob[] };
    dispatch: Dispatch<AnyAction>;
};

export default function useFileUploader(): UseFileUploaderReturn {
    const [error, setError] = useState("");
    const pid = useSelector(selectProfileId);
    const pageBlock = useSelector(selectActivePageBlock);
    const profileUsername = useSelector(selectUsername);

    const initialState = { inDropZone: false, fileList: [] };
    const [data, dispatch] = useReducer(fileUploadReducer, initialState);

    const _createBlock = useCallback(async () => {
        const response = await fetch(`/api/v0.1/profiles/${pid}/blocks`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                parent: pageBlock?.id,
                type: BlockType.media,
            }),
        });

        const data = await response.json();
        let block = null;

        if (data.error) {
            setError("Error creating a media block");
        } else {
            block = data.block;
        }

        return block;
    }, [pageBlock, pid]);

    const _updateBlock = useCallback(
        async (newBlock: HydratedBlock) => {
            // need to remove restricted fields from update and convert to non hydrated block
            const updates: Partial<Block> = {
                ...newBlock,
                parent: newBlock?.parent?.id || "",
                children: newBlock?.children.map(c => c.id),
            };
            delete updates.id;
            delete updates.profile;
            delete updates.parent;

            const response = await fetch(
                `/api/v0.1/profiles/${pid}/blocks/${newBlock?.id}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        updates,
                    }),
                },
            );
            const data = await response.json();
            const { error }: { block: Block; error: any } = data;

            if (error) {
                setError("Error creating a media block");
                return;
            }
        },
        [pid, profileUsername],
    );

    const _onUpload = async (error: any, res: any) => {
        const newMedia: Media = {
            id: uuidv4(),
            url: res.info.secure_url,
            type: res.info.resource_type,
            format: res.info.format,
            active: true,
            thumbnail_url: res.info.thumbnail_url,
        };

        if (res.info.duration) {
            newMedia.duration = res.info.duration;
        }

        try {
            const response = await fetch(
                `/api/v0.1/cloudinary/metadata?resourceId=${res.info.public_id}`,
            );
            const metadata = await response.json();
            newMedia.color = metadata.data.colors[0][0];
        } catch (e) {
            if (e) setError("Error uploading file");
        } // Do not set color if color could not be fetched

        return newMedia;
    };

    const _handleFileUpload = useCallback(async () => {
        if (data.fileList.length === 0) return;

        const formData = new FormData();
        formData.append("file", data.fileList[0]);

        const response = await fetch(`/api/v0.1/cloudinary/upload?pid=${pid}`, {
            method: "POST",
            body: formData,
        });

        const res = await response.json();

        if (!res.info?.secure_url) {
            setError("Error uploading file");
            Sentry.captureException(res.error);
        }

        return res;
    }, [data.fileList, pid]);

    const createMediaBlock = useCallback(async () => {
        let block = await _createBlock();

        const uploadResponse = await _handleFileUpload();
        let newMedia = null;

        if (uploadResponse.info) {
            newMedia = await _onUpload(null, uploadResponse);
            block = { ...block, properties: { media_list: [newMedia] } };
            await _updateBlock(block);
        }
    }, [_handleFileUpload, _createBlock, _updateBlock]);

    return {
        createMediaBlock,
        error,
        data,
        dispatch,
    };
}
