export default function parseColor(color: string) {
    if (color.startsWith("#")) {
        // hex format
        color = color.slice(1);
        if (color.length === 3) {
            // short hex format
            color = color
                .split("")
                .map(c => c + c)
                .join("");
        }
        return {
            r: parseInt(color.slice(0, 2), 16),
            g: parseInt(color.slice(2, 4), 16),
            b: parseInt(color.slice(4, 6), 16),
        };
    } else if (color.startsWith("rgb")) {
        // rgb or rgba format
        const match = color.match(
            /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)/,
        );
        if (match) {
            return {
                r: parseInt(match[1]),
                g: parseInt(match[2]),
                b: parseInt(match[3]),
                a: !match[4]
                    ? 1
                    : parseFloat(match[4]) < 1
                    ? parseFloat(match[4])
                    : 1,
            };
        }
    }
    return { r: 0, g: 0, b: 0 };
}
