import { IconsMap } from "components/ui/Icon";
import useBreakpoints from "hooks/useBreakpoints";
import useEditMode from "hooks/useEditMode";
import { useHostname } from "hooks/useHostname";
import { useSettings } from "hooks/useSettings";
import { useTheme } from "hooks/useTheme";
import { useRouter } from "next/router";
import { clsx } from "clsx";
import { core_palette } from "utilities/palette";
import { useSelector } from "react-redux";
import { selectProfile } from "store/profileSlice";

const Popup = ({ onClick }: { onClick: () => void }) => {
    const theme = useTheme();
    const { isMobileView } = useBreakpoints();
    const { editMode } = useEditMode();
    const { hostname } = useHostname();
    const settings = useSettings();

    if (settings?.hide_branding) return null;

    // only show when live
    if (editMode) {
        return null;
    }

    return (
        <div
            className={clsx(
                "w-full py-8 pb-16 flex justify-center items-center mt-4",
                {
                    "flex-col": isMobileView,
                    "flex-row": !isMobileView,
                },
            )}
        >
            <div
                style={{
                    borderColor: core_palette.quaternary[theme.mode],
                }}
                onClick={onClick}
                className={clsx(
                    "p-3 rounded-xl flex flex-col justify-center items-center max-w-xs cursor-pointer hover:brightness-90 duration-200 transition-all",
                    {
                        // slightly more noticeable on mobile than desktop
                        // because mobile it's all the way at the bottom of the scroll and clashes with less stuff
                        "border-2": isMobileView,
                        border: !isMobileView,
                    },
                )}
            >
                <div className="flex flex-row gap-2 items-center">
                    <IconsMap.snackpass_logo_square
                        size={20}
                        color={theme.secondary}
                        className={"theme-secondary-text"}
                    />
                    <p
                        className={clsx(
                            "text-sm text-center theme-secondary-text",
                        )}
                    >
                        Powered By Snackpass
                    </p>
                </div>
            </div>
        </div>
    );
};

// only show this if they do not have a profile
export default function SnackBranding() {
    const router = useRouter();
    const profile = useSelector(selectProfile);
    if (!profile) return null;

    const _onClick = () => {
        router.push("/");
    };

    // for now, only show the popup
    return <Popup onClick={_onClick} />;
}
