import LinkTile from "./Link";
import { HydratedBlock } from "types";
import { AnimatePresence, motion } from "framer-motion";
import { IconsMap } from "components/ui/Icon";
import { useTheme } from "hooks/useTheme";
import classNames from "classnames";
import useBreakpoints from "hooks/useBreakpoints";

type Props = {
    block: HydratedBlock;
};
export default function LinksBlock(props: Props) {
    const { block } = props;
    const theme = useTheme();
    const { properties } = block || {};
    const links = properties?.links;
    const { isMobileView } = useBreakpoints();

    if (!properties?.links) {
        return (
            <div
                className="w-full flex justify-center items-center theme-tertiary-background p-4"
                style={{
                    borderRadius: theme.block_radius,
                }}
            >
                <IconsMap.link size={48} className="theme-secondary-text" />
            </div>
        );
    }

    return (
        <div className="flex h-full">
            {links?.length ? (
                <div
                    className={classNames(
                        "flex-1 w-full flex flex-row flex-wrap",
                        { "flex-col": isMobileView, "flex-row": !isMobileView },
                    )}
                    style={{
                        gridGap: "1rem",
                    }}
                >
                    <AnimatePresence>
                        {links?.map((l, i) => (
                            <motion.div
                                layout
                                key={l.id}
                                className="flex-1 w-full"
                            >
                                <LinkTile link={l} block={block} />
                            </motion.div>
                        ))}
                    </AnimatePresence>
                </div>
            ) : null}
        </div>
    );
}
