import { RichEmbedType } from "types";

export const getRichEmbedType = (
    embed_url: string,
): RichEmbedType | undefined => {
    const youtubeExp = new RegExp("youtu.?be(.com)?/");
    const videoIdMatching = new RegExp(/(watch\?v=)[a-zA-Z0-9]*/);
    const shortenedVideoIdMatching = new RegExp(/(youtu\.be\/)[a-zA-Z0-9]*/);
    const tiktokExp = new RegExp("tiktok.com");

    const isYouTubeEmbeddable =
        youtubeExp.test(embed_url) &&
        (videoIdMatching.test(embed_url) ||
            shortenedVideoIdMatching.test(embed_url));

    const isTikTokEmbeddable = tiktokExp.test(embed_url);

    if (isYouTubeEmbeddable) return RichEmbedType.YouTube;
    else if (isTikTokEmbeddable) return RichEmbedType.TikTok;
    else return undefined;
};
