import { BlockType, HydratedBlock } from "types";
import {
    isChildOfBlockInEdit,
    isChildOfParentBlock,
} from "utilities/blockTypeUtils";
import {
    CAROUSEL_CONTROLS_HEIGHT,
    DEFAULT_BLOCK_HEIGHT,
} from "utilities/constants";
import useBreakpoints from "./useBreakpoints";
import { useSelector } from "react-redux";
import { selectBlockEditor } from "store/blockEditor";

export function useBlockHeight(block?: HydratedBlock, maxChildHeight?: number) {
    const { isMobileView } = useBreakpoints();
    const isCarousel = block?.type === BlockType.carousel;
    const blockInEdit = useSelector(selectBlockEditor);

    if (!block) {
        return {
            height: DEFAULT_BLOCK_HEIGHT,
            baseHeight: DEFAULT_BLOCK_HEIGHT,
        };
    }

    // e.g. is child of a carousel
    const _isChildOfParentBlock = isChildOfParentBlock(block);

    const baseHeight = isMobileView
        ? block.properties?.height_mobile
        : block.properties?.height_desktop;
    let currHeight: string | number | undefined = baseHeight;

    if (_isChildOfParentBlock) {
        currHeight = isMobileView
            ? block.parent?.properties?.height_mobile
            : block.parent?.properties?.height_desktop;

        // if block parent doesn't have a height set, fall back to the child with max height. Otherwise
        // fall back to the block's height or the DEFAULT_BLOCK_HEIGHT in the case where that's not set either
        if (currHeight === undefined) {
            currHeight = maxChildHeight || baseHeight || DEFAULT_BLOCK_HEIGHT;
        }
        if (isChildOfBlockInEdit(block, blockInEdit)) {
            // if carousel is in edit, use local state instead of data fetched from server
            currHeight = isMobileView
                ? blockInEdit?.properties?.height_mobile
                : blockInEdit?.properties?.height_desktop;
        }
    }

    if (isCarousel) {
        // use carousel block's height if set, otherwise fall back to the child with max height
        // if no child with max height fall back to the DEFAULT_BLOCK_HEIGHT
        currHeight = baseHeight || maxChildHeight || DEFAULT_BLOCK_HEIGHT;

        currHeight += CAROUSEL_CONTROLS_HEIGHT;
    }

    return {
        height: currHeight || DEFAULT_BLOCK_HEIGHT,
        baseHeight: baseHeight || DEFAULT_BLOCK_HEIGHT,
    };
}
