import React from "react";
import * as R from "@radix-ui/react-radio-group";
import { useTheme } from "hooks/useTheme";
import { useUITheme } from "hooks/useUITheme";

interface Props {
    options: {
        id: string;
        value: string;
        label: string;
    }[];
    name?: string;
    onValueChange?: (value: string) => void;
    disabled?: boolean;
    isUI?: boolean;
    inputProps?: any;
}

const RadioGroup = ({ options, name, disabled, isUI, inputProps }: Props) => {
    const pageTheme = useTheme();
    const uiTheme = useUITheme();
    const theme = isUI ? uiTheme : pageTheme;

    return (
        <R.Root
            className="flex flex-col gap-2"
            defaultValue="default"
            aria-label="View density"
            name={name}
            disabled={disabled}
        >
            {options.map(option => (
                <div key={option.value} className="flex items-center gap-2">
                    <R.Item value={option.value} asChild>
                        <label
                            className="outline w-4 h-4 rounded-lg flex justify-center items-center cursor-pointer"
                            style={{
                                backgroundColor: disabled
                                    ? theme.secondary
                                    : "transparent",
                            }}
                        >
                            <R.Indicator
                                className="rounded-full w-2 h-2"
                                style={{
                                    backgroundColor: theme.primary,
                                }}
                            />
                            <input
                                type="radio"
                                name={name}
                                value={option.value}
                                disabled={disabled}
                                {...inputProps}
                                className="hidden"
                            />
                        </label>
                    </R.Item>
                    <label>{option.label}</label>
                </div>
            ))}
        </R.Root>
    );
};

export default RadioGroup;
