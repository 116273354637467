import classNames from "classnames";
import { useDevice } from "hooks/useDevice";
import useEditMode from "hooks/useEditMode";
import { useUITheme } from "hooks/useUITheme";

type Props = {
    children: React.ReactNode;
};

export default function PreviewWrapper(props: Props) {
    const { mobilePreviewMode, editMode } = useEditMode();
    const { isMobileDevice } = useDevice();
    const theme = useUITheme();

    if (!mobilePreviewMode || isMobileDevice) {
        return <>{props.children}</>;
    }

    return (
        <div
            className="min-h-screen flex flex-col items-center justify-center py-8"
            style={{
                backgroundColor: theme.background_color,
            }}
        >
            <div
                className={classNames(
                    "w-full max-w-[388px] 2xl:max-w-[420px] h-[90vh] max-h-[1000px] rounded-xl shadow-xl disable-scrollbars",
                    "relative overflow-y-auto overflow-x-hidden MobilePreviewWrapper",
                )}
                style={{
                    // this is needed to make border radius and overflow work on safari
                    // https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
                    isolation: "isolate",
                }}
            >
                {props.children}
            </div>
        </div>
    );
}
