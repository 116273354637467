import { clsx } from "clsx";
import { useDevice } from "hooks/useDevice";
import useEditMode from "hooks/useEditMode";
import { useSelector } from "react-redux";
import { selectProfile } from "store/profileSlice";
import { BLOCK_WIDTH_CLASS, NAVBAR_HEIGHT } from "utilities/constants";
import Tabs from "./Tabs";
import { useRouter } from "next/router";

function Logo({ logo }: { logo: string | undefined }) {
    const { editMode } = useEditMode();
    const router = useRouter();
    const { query } = router;
    const { username } = query;

    const _onClickSettings = () => {
        router.push(`/admin/${username}/settings`);
    };
    return (
        <div
            className={clsx(
                "h-12 w-12 min-w-12 rounded-full bg-cover bg-center mr-2",
                {
                    "transition-all duration-200 hover:opacity-60 cursor-pointer":
                        editMode,
                },
            )}
            style={{
                backgroundImage: `url(${logo})`,
            }}
            onClick={editMode ? _onClickSettings : undefined}
        />
    );
}

export default function Navbar() {
    const { mobilePreviewMode } = useEditMode();
    const { isMobileDevice } = useDevice();
    const profileData = useSelector(selectProfile);
    const router = useRouter();
    const { hideNavbar } = router.query;

    const logo = profileData?.profile_url || profileData?.cover_url;
    const mobile = isMobileDevice || mobilePreviewMode;
    // the only way to hide navbar without flash is it's own url
    // because query params happens on the client side
    const isReservedApp = router.pathname === "/[username]/reserved-app"; // meaning snack site being web viewed on custom branded app
    if (hideNavbar || isReservedApp) {
        return null;
    }

    return (
        <>
            <div
                className={clsx(
                    "w-full z-20 flex flex-row items-center transition-[border-radius] max-w-xl",
                )}
                style={{
                    maxWidth: "inherit",
                    height: NAVBAR_HEIGHT,
                }}
            >
                <div
                    className={clsx(
                        "flex flex-row items-end",
                        BLOCK_WIDTH_CLASS,
                        { "px-4": !mobile, "px-0": mobile },
                    )}
                    style={{
                        margin: "0 auto",
                    }}
                >
                    <div className="px-4 py-2 flex flex-row items-center justify-between w-full flex-1 overflow-x-auto disable-scrollbars whitespace-nowrap">
                        <Logo logo={logo} />
                        <Tabs />
                    </div>
                </div>
            </div>
        </>
    );
}
