import { InputType, propertyMap } from "../properties";
import { IconsMap } from "components/ui/Icon";
import useBlockEditor from "hooks/useBlockEditor";
import { Property } from "types";
import TextEditor from "./TextEditor";
import BlockSelector from "./BlockSelector";
import ColorPicker from "./ColorPicker";
import CloudinaryWidget from "components/profile/CloudinaryWidget";
import { useUITheme } from "hooks/useUITheme";

const Cloud = ({ onUpload }: { onUpload: (url: string) => void }) => (
    <CloudinaryWidget
        onUpload={(error, res) => {
            const url = res?.info?.secure_url;
            onUpload(url);
        }}
    >
        <div className="p-4 px-16 border-dashed border-2 rounded-xl cursor-pointer hover:brightness-90 transition-all duration-200">
            <IconsMap.upload size={48} />
            <p className="text-center">Upload</p>
        </div>
    </CloudinaryWidget>
);

type Props = {
    property: Property;
    value: any;
    setShow: (show: boolean) => void;
};

export default function SpecialPropertyInput(props: Props) {
    const { property, value, setShow } = props;
    const { updateProperty } = useBlockEditor();
    const propertyItem = propertyMap[property];
    const uiTheme = useUITheme();
    const type = propertyItem.type;

    switch (type) {
        case InputType.BlockSelector:
            return (
                <BlockSelector
                    value={value}
                    onChangeValue={value => updateProperty(property, value)}
                />
            );

        case InputType.ColorSelect:
            return (
                <ColorPicker
                    value={value}
                    onValueChange={value => {
                        updateProperty(property, value);
                    }}
                    property={property}
                />
            );
        case InputType.Upload:
            return (
                <Cloud
                    onUpload={value => {
                        updateProperty(property, value);
                        setShow(false);
                    }}
                />
            );
        case InputType.TextEditor:
            return (
                <TextEditor
                    value={value}
                    style={{
                        color: uiTheme.primary,
                    }}
                    onValueChange={(value: string) => {
                        updateProperty(property, value);
                    }}
                />
            );
        default:
            return <div />;
    }
}
