import { HorizontalAlignment, HydratedBlock } from "types";
import React from "react";
import { horizAlign, textAlignMap, vertAlign } from "./lib";
import parseHTML from "utilities/parseHTML";
import {
    DEFAULT_HORIZONTAL_ALIGNMENT,
    DEFAULT_VERTICAL_ALIGNMENT,
} from "utilities/constants";

type Props = {
    block: HydratedBlock;
};

export default function TextBlock(props: Props) {
    const { block } = props;
    const { properties } = block;

    // replace function to add https protocol
    const parsedHtml = parseHTML(properties?.text_body || "");
    let content = null;

    if (parsedHtml.constructor === String) {
        content = <p>{parsedHtml}</p>;
    }

    if (parsedHtml.constructor === (Object || Element)) {
        // @ts-ignore
        content = React.cloneElement(parsedHtml);
    }
    if (parsedHtml.constructor === Array) {
        content = parsedHtml.map(c => {
            return React.cloneElement(c);
        });
    }

    const alignHorizontal =
        properties?.text_body_alignment_horizontal ||
        DEFAULT_HORIZONTAL_ALIGNMENT;
    const alignItems = horizAlign[alignHorizontal];
    // const alignVertical =
    //     properties?.text_body_alignment_vertical || DEFAULT_VERTICAL_ALIGNMENT;
    // const justifyContent = vertAlign[alignVertical];
    const textAlign = textAlignMap[alignHorizontal] as HorizontalAlignment;

    return (
        <div
            // todo make padding have max width
            className="p-6 text-block text-block-remove-span-color items-center"
            style={{
                display: "flex",
                // justifyContent,
                flexDirection: "column",
                flexGrow: 1,
            }}
        >
            <div
                className="theme-secondary-text max-w-4xl "
                style={{
                    alignItems,
                    textAlign,
                }}
            >
                {content}
            </div>
        </div>
    );
}
