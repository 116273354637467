import clsx from "clsx";
import Block from "components/profile/Block";
import useBlockReorder from "hooks/useBlockReorder";
import useEditMode from "hooks/useEditMode";
import { BLOCK_WIDTH_CLASS } from "utilities/constants";
import { BlocksEditMode } from "./BlocksEditMode";
import { useRouter } from "next/router";
import { AppType } from "types";
import OnlineOrdering from "components/apps/onlineorder";
import Catering from "components/apps/catering";
import GiftCards from "components/apps/giftcards";
import Locations from "components/apps/locations";
import { useSelector } from "react-redux";
import {
    selectActivePageBlock,
    selectIsActivePageIsApp,
} from "store/profileSlice";

export default function BlocksLayout() {
    const { editMode } = useEditMode();
    const { localBlocksState } = useBlockReorder();

    const router = useRouter();
    const { page } = router.query;
    const activePage = useSelector(selectActivePageBlock);
    // if page url is app, then we need to render the app layout
    // alternatively to app type, just set page type

    const isApp = useSelector(selectIsActivePageIsApp);
    // if SnackOS app, check both query (visitor mode) and active page (admin mode)
    const appType = isApp ? page || activePage?.properties?.app_type : null;

    switch (appType) {
        case AppType.OnlineOrdering:
            return <OnlineOrdering />;
        case AppType.Catering:
            return <Catering />;
        case AppType.GiftCards:
            return <GiftCards />;
        case AppType.Locations:
            return <Locations />;
        // if not app, return snack.site page
        default:
            return (
                <div className="flex justify-center">
                    <div
                        className={clsx(
                            "relative flex flex-col",
                            BLOCK_WIDTH_CLASS,
                        )}
                    >
                        {editMode ? (
                            <BlocksEditMode />
                        ) : (
                            localBlocksState?.map(block => (
                                <div key={block.id}>
                                    <Block block={block} />
                                </div>
                            ))
                        )}
                    </div>
                </div>
            );
    }
}
