import { clsx } from "clsx";
import { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BlockType, HydratedBlock } from "types";
import { useBlockStyle } from "hooks/useBlockStyle";
import { useDevice } from "hooks/useDevice";
import useEditMode from "hooks/useEditMode";
import { ModalType, selectModalState, setModal } from "store/modalsSlice";
import {
    clearBlockEditor,
    selectBlockEditor,
    setBlockEditor,
} from "store/blockEditor";
import { resetSettingsEditor, setSettingsEditor } from "store/settingsEditor";
import { selectProfile } from "store/profileSlice";

import MediaBlock from "./MediaBlock";
import LinksBlock from "./LinksBlock";
import BioBlock from "./BioBlock";
import TextBlock from "./TextBlock";
import Carousel from "../Carousel";
import EmbedBlock from "./EmbedBlock";
import FAQBlock from "./FAQBlock";
import FormBlock from "./FormBlock";
import Title from "./Title";
import CellEditor from "./CellEditor";
import SocialBlock from "./SocialBlock";
import { HoursBlock } from "./HoursBlock";
import { AddressBlock } from "./AddressBlock";
import { MenuBlock } from "./MenuBlock";

function BlockSub(props: { block?: HydratedBlock }) {
    const { block } = props;

    if (!block) {
        return null;
    }

    switch (block.type) {
        case BlockType.media:
            return <MediaBlock block={block} />;
        case BlockType.links:
            return <LinksBlock block={block} />;
        case BlockType.bio:
            return <BioBlock block={block} />;
        case BlockType.text:
            return <TextBlock block={block} />;
        case BlockType.carousel:
            return <Carousel block={block} />;
        case BlockType.embed:
            return <EmbedBlock block={block} />;
        case BlockType.social:
            return <SocialBlock block={block} />;
        case BlockType.form:
            return <FormBlock block={block} />;
        case BlockType.faq:
            return <FAQBlock properties={block.properties || {}} />;
        case BlockType.hours:
            return <HoursBlock />;
        case BlockType.address:
            return <AddressBlock />;
        case BlockType.menu:
            return <MenuBlock />;
        default:
            return <div />;
    }
}

type Props = {
    block?: HydratedBlock;
    fromCarousel?: boolean;
    maxChildHeight?: number;
};

function Block(props: Props) {
    const { block, fromCarousel, maxChildHeight } = props;
    const style = useBlockStyle(block, fromCarousel, maxChildHeight);
    const { editMode } = useEditMode();
    const editingBlock = useSelector(selectBlockEditor);
    const modalState = useSelector(selectModalState);
    const dispatch = useDispatch();
    const blockEditorTimer = useRef<NodeJS.Timeout | null>(null);
    const ref = useRef<HTMLDivElement>(null);
    const profile = useSelector(selectProfile);
    const [showCellEditor, setShowCellEditor] = useState(false);
    const isEditing =
        editingBlock &&
        block &&
        editingBlock.id === block.id &&
        modalState.blockEditor;
    const toggleEdit = useCallback(() => {
        if (editMode) {
            if (isEditing) {
                if (blockEditorTimer.current) {
                    clearTimeout(blockEditorTimer.current);
                }

                dispatch(
                    setModal({
                        modal: ModalType.BlockEditor,
                        visible: false,
                    }),
                );
                dispatch(resetSettingsEditor({}));
                blockEditorTimer.current = setTimeout(() => {
                    dispatch(clearBlockEditor());
                }, 300);
            } else {
                // since Toolbar with edit icon shows only on mobile, toggle the desktop block
                // editor when not on a mobile
                dispatch(
                    setModal({
                        modal: ModalType.BlockEditor,
                        visible: true,
                    }),
                );

                if (block) dispatch(setBlockEditor(block));
                dispatch(setSettingsEditor(profile));
            }
        }
    }, [editMode, isEditing, dispatch, block, profile]);

    if (!block) {
        return null;
    }

    return (
        <div
            id={block.id}
            className={clsx(style.parentClassname, "")}
            ref={ref}
            onMouseEnter={() => setShowCellEditor(true)}
            onMouseLeave={() => setShowCellEditor(false)}
        >
            {/* Important Note: <Title> must stay outside of the block component
             **  due to how parent/child relationships of blocks work and how that
             **  can affect styling (i.e. recursive styling of blocks within blocks)
             */}
            <Title block={block} className={style.titleClassname} />
            <div
                style={style.style}
                className={clsx(
                    style.classname,
                    // disable ability to click/press links/buttons when in editMode in favor of dragging
                    {
                        "pointer-events-none select-none": editMode,
                        "theme-highlight p-1 transition-shadow transition-padding duration-100 !rounded-inherit":
                            isEditing,
                    },
                )}
            >
                <div
                    style={style.style}
                    className={`${style.classname} ${
                        // disable ability to click/press links/buttons when in editMode in favor of dragging
                        editMode && "pointer-events-none"
                    }`}
                >
                    <BlockSub {...props} />
                </div>
            </div>

            <CellEditor
                showCellEditor={showCellEditor}
                toggleEdit={toggleEdit}
                block={block}
            />
        </div>
    );
}

export default Block;
