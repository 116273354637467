import type { DragEndEvent, DragStartEvent } from "@dnd-kit/core";
import {
    DndContext,
    DragOverlay,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    SortableContext,
    arrayMove,
    sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";

import usePageReorder from "hooks/usePageReorder";
import EditPageTab from "./EditTab";
import { useSelector } from "react-redux";
import { selectActivePageBlock } from "store/profileSlice";

export function DnDPages() {
    const {
        move,
        localPagesState,
        pageIds,
        pageInitialIndex,
        setPageInitialIndex,
    } = usePageReorder();

    const sensors = useSensors(
        useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
        useSensor(TouchSensor, {
            activationConstraint: { delay: 250, tolerance: 5 },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const tree = localPagesState;
    const activePageBlock = useSelector(selectActivePageBlock);

    if (!tree || !pageIds) return null;

    const handleDragStart = ({ active }: DragStartEvent) => {
        const activeIndex = tree.findIndex(({ id }) => id === active.id);
        setPageInitialIndex(activeIndex);
    };

    const handleDragEnd = ({ active, over }: DragEndEvent) => {
        if (over && active.id !== over?.id) {
            const activeIndex = tree.findIndex(({ id }) => id === active.id);
            const overIndex = tree.findIndex(({ id }) => id === over.id);

            // don't allow dragging the Home page, or dragging other pages to index 0
            if (activeIndex === 0 || overIndex === 0) return;

            move(arrayMove(tree, activeIndex, overIndex));
        }
    };

    return (
        <DndContext
            sensors={sensors}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
        >
            <SortableContext items={pageIds}>
                {pageIds.map(pageId => {
                    const block = tree.find(b => b.id === pageId);

                    if (!block) return null;

                    return (
                        <span key={pageId}>
                            <EditPageTab
                                block={block}
                                activePageBlockId={activePageBlock?.id || ""}
                            />
                        </span>
                    );
                })}
            </SortableContext>
            <DragOverlay>
                <EditPageTab block={tree[pageInitialIndex || 0]} />
            </DragOverlay>
        </DndContext>
    );
}
