import { v4 as uuidv4 } from "uuid";
import Button from "components/ui/Button";
import useBlockEditor from "hooks/useBlockEditor";
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from "react-beautiful-dnd";
import { FormField, FormFieldType } from "types";
import FormFieldEditorRow from "./FormFieldEditorRow";
import { useState } from "react";
import FormFieldEditor from "./FormFieldEditor";
import UIButton from "components/ui/UIButton";

interface Props {
    fields?: FormField[];
}

const FormFieldsEditor = ({ fields = [] }: Props) => {
    const { updateProperty } = useBlockEditor();
    const [selectedFieldIndex, setSelectedFieldIndex] = useState(-1);

    const showFieldEditor = selectedFieldIndex !== -1;
    const handleHideFieldEditor = () => setSelectedFieldIndex(-1);

    const handleDragEnd = (result: DropResult) => {
        if (result.destination) {
            const newFields = [...fields];
            const [removed] = newFields.splice(result.source.index, 1);
            newFields.splice(result.destination.index, 0, removed);
            updateProperty("form_fields", newFields);
        }
    };

    const handleDelete = (index: number) => {
        const field = fields[index];
        const shouldDelete = window.confirm(
            `Are you sure you want to delete the field "${field.name}"?`,
        );

        if (shouldDelete) {
            const newFields = [...fields];
            newFields.splice(index, 1);
            updateProperty("form_fields", newFields);
        }
    };

    const handleEdit = (index: number) => () => setSelectedFieldIndex(index);

    const handleFieldChange = (index: number) => (newField: FormField) => {
        const newFields = [...fields];
        newFields[index] = newField;
        updateProperty("form_fields", newFields);
    };

    const handleToggleRequired = (index: number) => (required: boolean) => {
        const newFields = [...fields];
        newFields[index] = {
            ...newFields[index],
            required,
        };
        updateProperty("form_fields", newFields);
    };

    const handleAddField = () => {
        const newFields = [
            ...fields,
            {
                id: uuidv4(),
                type: FormFieldType.text,
                name: `Field ${fields.length}`,
                required: false,
            },
        ];
        updateProperty("form_fields", newFields);
    };

    return (
        <div className="p-6">
            <p className="mb-6 text-sm">Form Fields</p>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="faq">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="transition-all duration-200"
                        >
                            <div className="gap-4 px-4 flex flex-col">
                                {fields.map((field, i) => (
                                    <Draggable
                                        draggableId={field.id.toString()}
                                        index={i}
                                        key={field.id}
                                    >
                                        {(provided, _) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <FormFieldEditorRow
                                                    field={field}
                                                    onDelete={() =>
                                                        handleDelete(i)
                                                    }
                                                    onEdit={handleEdit(i)}
                                                    onToggleRequired={handleToggleRequired(
                                                        i,
                                                    )}
                                                    provided={provided}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            </div>
                            <div
                                className={`p-4 justify-center flex flex-row opacity-${
                                    snapshot.isDraggingOver ? "0" : "100"
                                }`}
                            >
                                <UIButton
                                    isWide
                                    title="Add New Field"
                                    onClick={handleAddField}
                                />
                            </div>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <FormFieldEditor
                show={showFieldEditor}
                field={fields[selectedFieldIndex]}
                onHide={handleHideFieldEditor}
                onChange={handleFieldChange(selectedFieldIndex)}
            />
        </div>
    );
};

export default FormFieldsEditor;
