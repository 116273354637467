import Switch from "components/ui/Switch";
import { useUITheme } from "hooks/useUITheme";
import { Property } from "types";
import { InputType, propertyMap } from "../properties";
import AdjustHeight from "./AdjustHeight";
import DateTimeInput from "./DateTimeInput";
import IconInput from "./IconInput";
import ImageInput from "./ImageInput";
import { ImgBackgroundColor } from "./ImgBackgroundColor";
import { InlineToggle } from "./InlineToggle";
import { InputSelect } from "./InputSelect";
import MediaEditor from "./MediaEditor";
import { Slider } from "./Slider";
import TextInput from "./TextInput";
import { AlertInput } from "./AlertInput";
import { BlankInput } from "./BlankInput";
import { NumberInput } from "./NumberInput";

const InputPreview = ({
    property,
    value,
    updateProperty,
    onClick,
}: {
    property: Property;
    value: any;
    onClick?: () => void;
    updateProperty: (property: Property, value: any) => void;
}) => {
    const theme = useUITheme(true);
    const propertyItem = propertyMap[property];

    if (propertyItem.type === InputType.Links) {
        return null;
    }

    if (propertyItem.type === InputType.Switch) {
        return (
            <Switch
                property={property}
                checked={value}
                onCheckChange={() => {
                    updateProperty(property, !value);
                }}
                allowDarkMode
            />
        );
    }

    if (propertyItem.type === InputType.Toggle) {
        return (
            <InlineToggle
                property={property}
                value={value}
                options={propertyItem.options || []}
                onValueChange={value => {
                    updateProperty(property, value);
                }}
                allowDarkMode
            />
        );
    }

    if (propertyItem.type === InputType.Select) {
        return (
            <InputSelect
                value={value}
                onValueChange={value => {
                    updateProperty(property, value);
                }}
                options={propertyItem.options || []}
                theme={theme}
            />
        );
    }

    if (propertyItem.type === InputType.Icon) {
        return (
            <IconInput
                icon={value}
                onIconChange={icon => {
                    updateProperty(property, icon);
                }}
            />
        );
    }

    if (propertyItem.type == InputType.Image) {
        return (
            <ImageInput
                imageUrl={value}
                onImageUrlChange={imageUrl => {
                    updateProperty(property, imageUrl);
                }}
            />
        );
    }

    if (propertyItem.type == InputType.DateTime) {
        return (
            <DateTimeInput
                datetime={value}
                onDateTimeChange={(datetime: string) =>
                    updateProperty(property, datetime)
                }
            />
        );
    }

    // TODO: unify these custom inputs with
    // PropertyRow/index.tsx custom inputs
    if (propertyItem.type === InputType.Slider) {
        return (
            <Slider
                value={value}
                onValueChange={(value: number) => {
                    updateProperty(property, value);
                }}
            />
        );
    }

    if (propertyItem.type === InputType.MediaBackgroundColor) {
        return (
            <ImgBackgroundColor
                value={value}
                onValueChange={value => {
                    updateProperty(property, value);
                }}
            />
        );
    }

    if (propertyItem.type === InputType.AdjustHeight) {
        return <AdjustHeight />;
    }

    if (propertyItem.type === InputType.Text) {
        return (
            <TextInput
                initialValue={value}
                placeholder={propertyItem.name}
                onValueChange={value => updateProperty(property, value)}
            />
        );
    }

    if (propertyItem.type === InputType.Numeric) {
        return (
            <NumberInput
                initialValue={value}
                placeholder={propertyItem.name}
                onValueChange={value => updateProperty(property, value)}
            />
        );
    }

    if (propertyItem.type === InputType.MediaContent) {
        return <MediaEditor media_list={value} />;
    }

    if (propertyItem.type === InputType.Alert) {
        return (
            <AlertInput
                property={property}
                initialValue={value}
                onValueChange={value => updateProperty(property, value)}
            />
        );
    }
    return <BlankInput initialValue={value} onClick={onClick || (() => {})} />;
};

export default InputPreview;
