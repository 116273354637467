import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { selectProfileId, setActivePage } from "store/profileSlice";
import { AppType, BlockType, HydratedBlock, PageType, Properties } from "types";
import { useTabPath } from "./useTabPath";
import useEditMode from "hooks/useEditMode";

export function useTabActions() {
    const dispatch = useDispatch();
    const router = useRouter();
    const pid = useSelector(selectProfileId);
    const { isAdmin } = useEditMode();
    const getTabPath = useTabPath();

    const onClickInEditMode = (e: any, block: HydratedBlock) => {
        if (isAdmin) {
            if (e.cancelable) {
                e.preventDefault();
            }

            if (block.properties?.page_type === PageType.URL) {
                window.open(block.properties?.custom_url, "_blank");
                return;
            }
            if (block.properties?.page_type === PageType.APP) {
                switch (block.properties?.app_type) {
                    case AppType.OnlineOrdering:
                        dispatch(setActivePage(AppType.OnlineOrdering));
                        return;
                    case AppType.GiftCards:
                        dispatch(setActivePage(AppType.GiftCards));
                        return;
                    case AppType.Catering:
                        dispatch(setActivePage(AppType.Catering));
                        return;
                    case AppType.Locations:
                        dispatch(setActivePage(AppType.Locations));
                        return;
                    default:
                        return;
                }
            }
            dispatch(setActivePage(block.properties?.page_url));
        }
    };

    const onUpdateBlockProperties = async ({
        block,
        properties,
    }: {
        block: HydratedBlock;
        properties: Partial<Properties>;
    }) => {
        try {
            const response = await fetch(
                `/api/v0.1/profiles/${pid}/blocks/${block.id}`,
                {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        updates: {
                            properties: properties,
                        },
                    }),
                },
            );

            const data = await response.json();
            const { block: updatedBlock, error: _error } = data;
            if (_error) throw new Error(_error);
        } catch (e: unknown) {
            if (e instanceof Error) {
                throw e;
            } else {
                throw new Error("Error updating block properties");
            }
        }
    };

    const onCreate = async ({
        pageName,
        pageUrl,
    }: {
        pageName?: string;
        pageUrl?: string;
    }) => {
        try {
            const response = await fetch(`/api/v0.1/profiles/${pid}/blocks`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    type: BlockType.page,
                    properties: {
                        page_url: pageUrl,
                        page_name: pageName,
                        page_type: PageType.PAGE,
                    },
                }),
            });

            const data = await response.json();
            const { block: updatedBlock, error: _error } = data;
            if (_error) throw new Error(_error);
            dispatch(setActivePage(pageUrl));
        } catch (e: unknown) {
            if (e instanceof Error) {
                throw e;
            } else {
                throw new Error("Error creating page");
            }
        }
    };

    const onDelete = async (block: HydratedBlock) => {
        const res = window.confirm(
            `Are you sure you want to delete this page "/${block.properties?.page_url}"? All blocks on this page will be deleted.`,
        );
        if (!res) {
            return;
        }

        await fetch(`/api/v0.1/profiles/${pid}/blocks/${block.id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const newPath = getTabPath("");
        router.push(newPath);
    };

    const getHRef = (block: HydratedBlock) => {
        if (block.properties?.page_type === PageType.URL) {
            return block.properties?.custom_url;
        }
        if (block.properties?.page_type === PageType.APP) {
            return getTabPath(block.properties?.app_type);
        }
        return getTabPath(block.properties?.page_url);
    };

    return {
        onClickInEditMode,
        onCreate,
        onDelete,
        onUpdateBlockProperties,
        getHRef,
    };
}
