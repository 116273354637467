import React from "react";

const Locations = () => {
    return (
        <div>
            <h1>Locations Component</h1>
        </div>
    );
};

export default Locations;
