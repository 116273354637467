import apiClient from "apiClient";
import classNames from "classnames";
import Block from "components/profile/Block";
import Button from "components/ui/Button";
import { getTree } from "controllers/blockTree";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import {
    selectActivePageBlock,
    selectProfileId,
    selectUsername,
} from "store/profileSlice";
import { ModalType } from "store/modalsSlice";
import { ButtonMode, HydratedBlock, Profile } from "types";
import { BLOCK_TEMPLATE_ID, BLOCK_WIDTH_CLASS } from "utilities/constants";
import { core_palette } from "utilities/palette";
import UIButton from "components/ui/UIButton";

type BlockPreviewProps = {
    block: HydratedBlock;
    onClick: (block: HydratedBlock) => void;
};
const BlockPreview = ({ block, onClick }: BlockPreviewProps) => {
    if (!block) {
        return <p>loading..</p>;
    }

    return (
        <div
            className={classNames(
                "flex flex-col gap-2 pb-4 w-full bg-white items-center",
            )}
        >
            <div className={BLOCK_WIDTH_CLASS}>
                <Block block={block} />
            </div>
            <div className="px-4">
                <Button
                    title="Use"
                    className="px-8"
                    mode={ButtonMode.solid}
                    onClick={() => {
                        onClick(block);
                    }}
                />
            </div>
        </div>
    );
};

const PageCategory = ({
    block,
    onClick,
}: {
    block: HydratedBlock;
    onClick: (block: HydratedBlock) => void;
}) => {
    return (
        <div
            className="flex flex-col justify-center"
            style={{
                background: core_palette.quaternary.light,
            }}
        >
            <div className="flex flex-col gap-2 items-center w-full justify-center">
                {block.children.map((block: HydratedBlock) => (
                    <BlockPreview
                        key={block.id}
                        block={block}
                        onClick={onClick}
                    />
                ))}
            </div>
        </div>
    );
};

export default function SelectBlockTemplates() {
    const [profile, setProfile] = useState<Profile | null>(null);

    const activePage = useSelector(selectActivePageBlock);
    const pid = useSelector(selectProfileId);
    const [selectedPage, setSelectedPage] = useState<string>("basic");

    useEffect(() => {
        const fetchProfile = async () => {
            const { profile: _profile } = await apiClient.get<{
                profile: Profile;
            }>("profiles/" + BLOCK_TEMPLATE_ID);

            setProfile(_profile);
        };
        fetchProfile();
    }, []);

    const _useTemplate = async (templateBlockId: string) => {
        const response = await fetch(
            `/api/v0.1/profiles/${pid}/blocks/use-template`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    templatePid: BLOCK_TEMPLATE_ID,
                    templateBlockId,
                    targetParentId: activePage?.id,
                }),
            },
        );

        const { block, error } = await response.json();

        if (error) {
            alert(error.error);
            return;
        }
        toast.success("Block added");
    };

    const onClickBlock = (block: HydratedBlock) => {
        _useTemplate(block.id);
    };

    const tree = getTree(profile?.blocks || []).filter(
        b => !!b.properties?.page_url,
    );
    console.log("tree", tree);

    return (
        <div
            className="flex flex-col gap-2 pt-4 pb-24 transition-all duration-200"
            style={{
                backgroundColor: core_palette.inverse.light,
            }}
        >
            <div className="flex flex-row gap-2 flex-wrap px-4 justify-center">
                {tree.map(block => {
                    const selected =
                        block.properties?.page_url === selectedPage;
                    return (
                        <div key={block.id}>
                            <UIButton
                                title={block.properties?.page_url}
                                mode={
                                    selected
                                        ? ButtonMode.solid
                                        : ButtonMode.outline
                                }
                                className="p-2"
                                onClick={() => {
                                    setSelectedPage(
                                        block.properties?.page_url || "",
                                    );
                                }}
                            />
                        </div>
                    );
                })}
            </div>
            {tree
                .filter(b => {
                    if (!selectedPage) {
                        return true;
                    }
                    return b.properties?.page_url === selectedPage;
                })
                .map(block => (
                    <PageCategory
                        block={block}
                        key={block.id}
                        onClick={onClickBlock}
                    />
                ))}
        </div>
    );
}
