import useEditMode from "hooks/useEditMode";

export default function Footer() {
    const { isAdmin } = useEditMode();

    if (isAdmin)
        return (
            <>
                <br />
                <br />
                <br />
                <br />
                <br />
            </>
        );
    return (
        <>
            <br />
        </>
    );
}
