import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { motion } from "framer-motion";

function getProgress(startTime: number, denom: number) {
    const duration = Date.now() - startTime;
    const progress = (duration / denom) * 100;

    if (progress > 99.5) {
        return 100;
    }

    return progress;
}

export function StoryBars({
    length,
    activeIndex,
    slideStartTime,
    duration,
    setActiveIndex,
}: {
    length: number;
    activeIndex: number;
    slideStartTime: number;
    duration: number;
    setActiveIndex: Dispatch<SetStateAction<number>>;
}) {
    // this refreshes the progress bar every 50ms
    const [nowTime, setNowTime] = useState(0);
    useEffect(() => {
        if (length <= 1) {
            return;
        }
        const intervalId = setInterval(() => {
            setNowTime(Date.now());
        }, 50);
        return () => clearInterval(intervalId);
    }, [length]);

    const progress = getProgress(slideStartTime, duration);

    if (length <= 1) {
        return null;
    }

    return (
        <div
            className="absolute h-[2px] top-4 left-0 right-0 w-full px-8 flex flex-row max-w-md gap-2"
            style={{ margin: "0 auto" }}
        >
            {Array.from({ length }).map((_, index) => (
                <div
                    key={index}
                    className="flex-1 h-full rounded-full cursor-pointer hover:brightness-90 duration-200 transition-all"
                    style={{
                        backgroundColor: "rgba(255,255,255,.5)",
                    }}
                    onClick={() => {
                        setActiveIndex(index);
                    }}
                >
                    {activeIndex === index ? (
                        <motion.div
                            animate={{ width: progress + "%" }}
                            className="h-full bg-white rounded-md"
                            exit={{ width: 0 }}
                            initial={{ width: 0 }}
                            transition={{ duration: 0.05 }}
                        />
                    ) : null}
                </div>
            ))}
        </div>
    );
}
