import { IconsMap } from "components/ui/Icon";
import { useUITheme } from "hooks/useUITheme";
import CloudinaryWidget from "../CloudinaryWidget";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectNewSettings, updateSettingsEditor } from "store/settingsEditor";
import { BlockType, SettingsProperty } from "types";
import { selectActivePropertyTab, selectBlockEditor } from "store/blockEditor";
import { PropertyTab } from "./properties";

interface ImageUploadInputProps {
    imageUrl: string;
    onUpload: (error: any, res: any, url: string) => void;
    clearImage: () => void;
    className?: string;
}

const ImageUploadInput = ({
    imageUrl,
    className,
    onUpload,
    clearImage,
}: ImageUploadInputProps) => {
    const theme = useUITheme(true);

    return (
        <div className="flex-1 flex flex-col items-center gap-4">
            <div
                className={clsx(
                    "bg-cover bg-center bg-no-repeat relative overflow-hidden transition-all duration-200 flex justify-center items-center",
                    className,
                )}
                style={{
                    backgroundColor: theme.quaternary,
                    backgroundImage: `url(${imageUrl})`,
                }}
            >
                {imageUrl ? null : (
                    <IconsMap.image
                        className="w-12 h-12"
                        color={theme.secondary}
                    />
                )}
            </div>
            <div className="flex flex-row w-full gap-2">
                <div
                    className="text-sm flex-1 border cursor-pointer rounded-md hover:backdrop-brightness-95 transition-all duration-200"
                    style={{
                        borderColor: theme.border_color,
                    }}
                >
                    <CloudinaryWidget onUpload={onUpload}>
                        <span
                            className="text-center w-full block my-2"
                            style={{
                                color: theme.secondary,
                            }}
                        >
                            {imageUrl ? "Change Image" : "Add Image"}
                        </span>
                    </CloudinaryWidget>
                </div>
                {imageUrl ? (
                    <div
                        className="cursor-pointer flex justify-center items-center p-2 border rounded-md hover:backdrop-brightness-95 transition-all duration-200"
                        style={{
                            color: theme.secondary,
                            borderColor: theme.border_color,
                        }}
                        onClick={clearImage}
                    >
                        <IconsMap.cross />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

const BioBlockEditor = () => {
    const dispatch = useDispatch();
    const uiTheme = useUITheme(true);
    const block = useSelector(selectBlockEditor);
    const activePropertyTab = useSelector(selectActivePropertyTab);
    const newSettings = useSelector(selectNewSettings);

    const updateProperty = (property: SettingsProperty, value: any) => {
        dispatch(updateSettingsEditor({ property, value }));
    };

    if (
        block?.type !== BlockType.bio ||
        activePropertyTab !== PropertyTab.Bio
    ) {
        return null;
    }

    // if (block.properties?.bio_use_custom_fields) {
    //     return null;
    // }
    return null;

    return (
        <div className="flex flex-col gap-4 my-4">
            <div className="flex flex-col px-4 gap-4">
                <div className="flex flex-row flex-1">
                    <div className="flex-1">
                        <p
                            className="text-sm"
                            style={{ color: uiTheme.primary }}
                        >
                            Site Name
                        </p>
                    </div>
                </div>

                <div className="flex-1 text-sm">
                    <input
                        className="border p-2 w-full rounded-md resize-none"
                        placeholder="Name..."
                        value={newSettings?.name}
                        onChange={e => {
                            updateProperty("name", e.target.value);
                        }}
                        style={{
                            backgroundColor: uiTheme.quinary,
                            borderColor: uiTheme.quaternary,
                            color: uiTheme.secondary,
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-col px-4 gap-4">
                <div className="flex flex-row flex-1">
                    <div className="flex-1">
                        <p
                            className="text-sm"
                            style={{ color: uiTheme.primary }}
                        >
                            Site Description
                        </p>
                    </div>
                </div>

                <div className="flex-1 text-sm">
                    <textarea
                        className="border p-2 w-full rounded-md resize-none"
                        placeholder="Write something about your brand..."
                        value={newSettings?.description}
                        rows={3}
                        onChange={e => {
                            updateProperty("description", e.target.value);
                        }}
                        style={{
                            backgroundColor: uiTheme.quinary,
                            borderColor: uiTheme.quaternary,
                            color: uiTheme.secondary,
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-col px-4 gap-4">
                <div className="flex flex-row flex-1">
                    <div className="flex-1">
                        <p
                            className="text-sm"
                            style={{ color: uiTheme.primary }}
                        >
                            Site Profile Picture
                        </p>
                    </div>
                </div>
                <ImageUploadInput
                    imageUrl={newSettings?.profile_url || ""}
                    className="w-[68px] h-[68px] rounded-full"
                    onUpload={(_, __, url) => {
                        updateProperty("profile_url", url);
                    }}
                    clearImage={() => {
                        updateProperty("profile_url", "");
                    }}
                />
            </div>
            <div className="flex flex-col px-4 gap-4">
                <div className="flex flex-row flex-1">
                    <div className="flex-1">
                        <p
                            className="text-sm"
                            style={{ color: uiTheme.primary }}
                        >
                            Site Cover Photo
                        </p>
                    </div>
                </div>
                <ImageUploadInput
                    imageUrl={newSettings?.cover_url || ""}
                    className="w-full h-[68px] rounded-xl"
                    onUpload={(_, __, url) => {
                        updateProperty("cover_url", url);
                    }}
                    clearImage={() => {
                        updateProperty("cover_url", "");
                    }}
                />
            </div>
        </div>
    );
};

export default BioBlockEditor;
