import { useSelector } from "react-redux";
import { selectActivePropertyTab, selectBlockEditor } from "store/blockEditor";
import { Property, TopLevelProperty } from "types";
import BioBlockEditor from "./BioBlockEditor";
import { propertyList } from "./properties";
import PropertyRow from "./PropertyRow";

export const PropertyRows = () => {
    const block = useSelector(selectBlockEditor);
    const activePropertyTab = useSelector(selectActivePropertyTab);
    if (!block) return null;
    if (!activePropertyTab)
        return (
            <div>
                <br />
            </div>
        );

    const properties = propertyList
        .filter(propertyItem => {
            // filter not in block
            const isBlockProperty = propertyItem.blocks.includes(block.type);
            const inAll = propertyItem.blocks.includes("all");
            return isBlockProperty || inAll;
        })
        .filter(propertyItem => propertyItem.tab === activePropertyTab);

    return (
        <div className="flex flex-col gap-2 mt-4 pb-32">
            <BioBlockEditor />
            {/* manually add block type as property */}
            {properties.map(propertyItem => {
                const property = propertyItem.key as Property;

                // parent property is a property of the top level block object
                const value = propertyItem.isTopLevel
                    ? block[property as TopLevelProperty]
                    : block.properties?.[property];
                return (
                    <PropertyRow
                        key={property}
                        property={property}
                        value={value}
                    />
                );
            })}
        </div>
    );
};
