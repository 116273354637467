import useCellEditor from "hooks/useCellEditor";
import { HydratedBlock } from "types";
import useEditMode from "hooks/useEditMode";
import { IconsMap } from "components/ui/Icon";
import { useUITheme } from "hooks/useUITheme";
import { motion } from "framer-motion";
import { clsx } from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { setBlockEditor } from "store/blockEditor";
import { setSettingsEditor } from "store/settingsEditor";
import { selectProfile } from "store/profileSlice";
import { useEffect, useState } from "react";

type Props = {
    showCellEditor: boolean;
    toggleEdit: () => void;
    block: HydratedBlock;
};

export default function CellEditor(props: Props) {
    const { showCellEditor, toggleEdit, block } = props;
    const { editMode } = useEditMode();
    const uiTheme = useUITheme();
    const { duplicate, trash } = useCellEditor();
    const profile = useSelector(selectProfile);
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);

    const handleClickMenu = () => {
        setExpanded(!expanded);
        if (block) dispatch(setBlockEditor(block));
        dispatch(setSettingsEditor(profile));
    };

    useEffect(() => {
        !showCellEditor && setExpanded(false);
    }, [showCellEditor]);

    if (!editMode) {
        return null;
    }

    return (
        <div
            id="cell-editor"
            className={clsx(
                "absolute top-4 right-6 flex border rounded-md backdrop-blur-sm transition-opacity duration-300",
                {
                    "opacity-100": showCellEditor,
                    "opacity-0": !showCellEditor,
                },
            )}
            style={{
                background: uiTheme.background_color,
            }}
        >
            <div className="flex flex-row">
                {!expanded && (
                    <div
                        className="flex border-r theme-break w-full p-2 flex-row items-center  rounded-l-md hover:bg-mauve2 cursor-pointer transition-backdrop duration-200"
                        onClick={toggleEdit}
                    >
                        <IconsMap.pen_outline
                            size={18}
                            color={uiTheme.secondary}
                        />
                    </div>
                )}
                {expanded && (
                    <motion.div
                        className="flex items-start"
                        initial={{ x: 10, opacity: 0.5 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.2 }}
                    >
                        <div
                            className="flex border-r theme-break w-full p-2 flex-row items-center  rounded-l-md hover:bg-mauve2 cursor-pointer transition-backdrop duration-200"
                            onClick={toggleEdit}
                        >
                            <IconsMap.pen_outline
                                size={18}
                                color={uiTheme.secondary}
                            />
                        </div>
                        <div
                            className="flex border-r theme-break w-full p-2 flex-row items-center hover:bg-mauve2 cursor-pointer transition-backdrop duration-200"
                            onClick={duplicate}
                        >
                            <IconsMap.duplicate
                                size={18}
                                color={uiTheme.secondary}
                            />
                        </div>
                        <div
                            className="flex  border-r theme-break w-full p-2 flex-row items-center  hover:bg-mauve2 cursor-pointer transition-backdrop duration-200"
                            onClick={trash}
                        >
                            <IconsMap.trash
                                size={18}
                                color={uiTheme.secondary}
                            />
                        </div>
                    </motion.div>
                )}
                <div
                    className="flex w-full p-2 flex-row items-center rounded-r-md hover:bg-mauve2 cursor-pointer transition-backdrop duration-200"
                    onClick={handleClickMenu}
                >
                    <IconsMap.dots_horizontal
                        size={18}
                        color={uiTheme.secondary}
                    />
                </div>
            </div>
        </div>
    );
}
