import { BackgroundType, FAQBlock as FAQBlockType, Properties } from "types";
import * as Accordion from "@radix-ui/react-accordion";
import React from "react";
import { IconsMap } from "components/ui/Icon";
import { useTheme } from "hooks/useTheme";
import { clsx } from "clsx";

interface Props {
    properties: FAQBlockType & Properties;
}

const FAQBlock = ({ properties }: Props) => {
    const { faq_title, faq_questions } = properties;
    const theme = useTheme();

    return (
        <div>
            <h1 className="text-xl p-4 font-semibold theme-primary-text">
                {faq_title || "Frequently Asked Questions"}
            </h1>
            {faq_questions?.length ? <hr className="theme-break" /> : null}
            <div>
                <Accordion.Root type="multiple">
                    {faq_questions?.map(({ id, question, answer }, index) => (
                        <Accordion.Item
                            key={id}
                            value={index.toString()}
                            className={clsx(
                                "overflow-hidden first:mt-0 first:rounded-t last:rounded-b focus-within:relative focus-within:z-10",
                            )}
                        >
                            <Accordion.Header className="flex">
                                <Accordion.Trigger className="font-semibold cursor-pointer group flex p-4 flex-1 items-center justify-between leading-none gap-2">
                                    <h4 className="flex-1 theme-primary-text text-left">
                                        {question}
                                    </h4>
                                    <IconsMap.chevron_down
                                        className="ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-200 group-data-[state=open]:rotate-180 w-4"
                                        aria-hidden
                                        color={theme.secondary}
                                    />
                                </Accordion.Trigger>
                            </Accordion.Header>
                            <Accordion.Content className="px-4 data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden text-sm">
                                <div
                                    className="theme-secondary-text mb-4 break-words text-block"
                                    dangerouslySetInnerHTML={{ __html: answer }}
                                />
                            </Accordion.Content>
                            {index !== faq_questions.length - 1 ? (
                                <hr className="theme-break" />
                            ) : null}
                        </Accordion.Item>
                    ))}
                </Accordion.Root>
            </div>
        </div>
    );
};

export default FAQBlock;
