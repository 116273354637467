import parse from "html-react-parser";

const parseHTML = (html: string) =>
    parse(html, {
        replace: domNode => {
            // @ts-ignore: Unreachable code error
            if (domNode.name) {
                // @ts-ignore: Unreachable code error
                const tagName = domNode.name.toLowerCase();
                // @ts-ignore: Unreachable code error
                domNode.attribs = {
                    // @ts-ignore: Unreachable code error
                    ...domNode.attribs,
                    class: `text-color-${tagName}`,
                };
            }
            // @ts-ignore: Unreachable code error
            if (domNode.attribs && domNode.attribs.href) {
                // @ts-ignore: Unreachable code error
                let url = domNode.attribs.href;
                if (!/^https?:\/\//i.test(url)) {
                    url = "https://" + url;
                }
                // @ts-ignore: Unreachable code error
                domNode.attribs.href = url;
                return domNode;
            }
        },
    });

export default parseHTML;
