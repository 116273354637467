import AddPageButton from "./AddPageButton";
import { DnDPages } from "./DnDPages";

export default function EditPageTabs() {
    return (
        <div className="flex flex-row gap-4 items-center">
            <DnDPages />
            <AddPageButton />
        </div>
    );
}
