import * as Dialog from "@radix-ui/react-dialog";
import Button from "components/ui/Button";
import { useTheme } from "hooks/useTheme";
import { useUITheme } from "hooks/useUITheme";
import { Particles } from "./Particles";

interface Props {
    title: string;
    message?: string;
    confetti_image_url?: string;
    isOpen: boolean;
    onClose: () => void;
    isUI?: boolean;
}

const ConfirmationDialog = ({
    title,
    message,
    confetti_image_url,
    isOpen,
    onClose,
    isUI,
}: Props) => {
    const uiTheme = useUITheme();
    const pageTheme = useTheme();
    const theme = isUI ? uiTheme : pageTheme;

    return (
        <Dialog.Root open={isOpen}>
            <Dialog.Portal>
                <Dialog.Overlay className="bg-blackA9 data-[state=open]:animate-fadeIn fixed inset-0" />
                <Dialog.Content
                    className="data-[state=open]:animate-dialogContentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none"
                    style={{
                        backgroundColor: theme.background_color,
                    }}
                >
                    <div>
                        <Dialog.Title className="m-0 text-3xl font-bold">
                            {title}
                        </Dialog.Title>
                        {message ? (
                            <Dialog.Description
                                className="mt-4 mb-4 leading-normal"
                                style={{
                                    color: theme.secondary,
                                }}
                            >
                                {message}
                            </Dialog.Description>
                        ) : null}
                    </div>
                    <div className="mt-8 flex justify-end">
                        <Dialog.Close asChild>
                            <Button title="Close" onClick={onClose} />
                        </Dialog.Close>
                    </div>
                </Dialog.Content>
                {isOpen ? (
                    <Particles confetti_image_url={confetti_image_url} />
                ) : null}
            </Dialog.Portal>
        </Dialog.Root>
    );
};

export default ConfirmationDialog;
