import { Icon } from "../../../ui/Icon";
import { useTheme } from "hooks/useTheme";
import { ButtonMode, HydratedBlock } from "types";
import { cleanUrl } from "../lib";
import Button from "components/ui/Button";

type Props = {
    icon: Icon;
    url: string;
    block: HydratedBlock;
};

export default function SocialLink(props: Props) {
    const { icon, url, block } = props;
    const { properties } = block;

    const DEFAULT = ButtonMode.solid;

    const buttonMode = properties?.button_mode || DEFAULT;

    return (
        <Button
            icon={icon}
            mode={buttonMode}
            animateScale
            href={cleanUrl(url)}
            className=""
            borderRadius="300px"
        />
    );
}
