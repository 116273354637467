import { IconsMap } from "components/ui/Icon";
import { useTheme } from "hooks/useTheme";
import { useUITheme } from "hooks/useUITheme";
import { ChangeEvent, useState } from "react";
import { FormField, FormFieldType } from "types";
import RadioGroup from "components/ui/RadioGroup";
import { v4 as uuidv4 } from "uuid";
import { UseFormRegister, FieldValues } from "react-hook-form";

interface Props {
    field: FormField;
    registerFormField?: UseFormRegister<FieldValues>;
    isUI?: boolean;
    preview?: boolean;
}

const FormFieldInput = ({ field, isUI, preview, registerFormField }: Props) => {
    const pageTheme = useTheme();
    const uiTheme = useUITheme();
    const theme = isUI ? uiTheme : pageTheme;
    const [focus, setFocus] = useState(false);
    const [displayValue, setDisplayValue] = useState<string>("");

    const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setDisplayValue(e.target.files[0].name);
        }
    };

    const getPreviewValue = (type: FormFieldType) => {
        switch (type) {
            case FormFieldType.email:
                return "preview@preview.com";
            case FormFieldType.url:
                return "https://preview.com";
            case FormFieldType.tel:
                return "(500) 555-5555";
            case FormFieldType.textarea:
            case FormFieldType.text:
            default:
                return "Preview Input Text";
        }
    };

    const previewValue = getPreviewValue(field.type);

    const formFieldProps = registerFormField
        ? registerFormField(field.id, {
              onChange:
                  field.type === FormFieldType.file
                      ? handleChangeFile
                      : undefined,
              onBlur: () => setFocus(false),
              required: field.required ?? `Please enter ${field.name}`,
          })
        : {};

    if (field.type === FormFieldType.file) {
        return (
            <div
                className={
                    "w-full overflow-hidden text-ellipsis border p-2 rounded-lg"
                }
                style={{
                    borderColor: focus ? theme.secondary : theme.border_color,
                    backgroundColor: preview ? theme.quaternary : "transparent",
                }}
            >
                <label
                    htmlFor={field.id}
                    className="flex flex-row items-center"
                >
                    <p className="flex-1">
                        {displayValue ? displayValue : "Upload File"}
                    </p>
                    <IconsMap.upload size="1.5em" />
                    <input
                        className="hidden"
                        id={field.id}
                        style={{
                            borderColor: focus
                                ? theme.secondary
                                : theme.border_color,
                        }}
                        type={field.type}
                        onFocus={() => setFocus(true)}
                        disabled={preview}
                        {...formFieldProps}
                    />
                </label>
            </div>
        );
    } else if (field.type === FormFieldType.textarea) {
        return (
            <textarea
                id={field.id}
                className={
                    "w-full resize-none bg-transparent border p-2 rounded-md outline-none"
                }
                style={{
                    borderColor: focus ? theme.secondary : theme.border_color,
                    backgroundColor: preview ? theme.quaternary : "transparent",
                }}
                rows={4}
                onFocus={() => setFocus(true)}
                placeholder={preview ? previewValue : ""}
                disabled={preview}
                {...formFieldProps}
            />
        );
    } else if (field.type === FormFieldType.checkbox) {
        return (
            <div className="flex flex-col gap-2">
                {field.options
                    ? field.options.map((option, i) => (
                          <label
                              key={i}
                              className="CheckboxContainer flex flex-row items-center gap-2"
                          >
                              <input
                                  type="checkbox"
                                  value={option}
                                  {...(registerFormField
                                      ? registerFormField(field.id, {
                                            onBlur: () => setFocus(false),
                                            required:
                                                field.required ??
                                                `Please enter ${field.name}`,
                                        })
                                      : {})}
                                  disabled={preview}
                              />
                              <div className="Checkbox border p-1 rounded-md">
                                  <IconsMap.check
                                      className="Indicator"
                                      size={12}
                                  />
                              </div>
                              <span>{option}</span>
                          </label>
                      ))
                    : null}
            </div>
        );
    } else if (field.type === FormFieldType.radio) {
        return (
            <RadioGroup
                options={
                    field.options?.map(option => ({
                        id: uuidv4(),
                        label: option,
                        value: option,
                    })) || []
                }
                name={field.id}
                disabled={preview}
                isUI
                inputProps={formFieldProps}
            />
        );
    } else if (field.type === FormFieldType.select) {
        return (
            <select
                className={
                    "w-full bg-transparent border p-2 rounded-lg theme-border"
                }
                style={{
                    backgroundColor: preview ? theme.quaternary : "transparent",
                }}
                {...formFieldProps}
                disabled={preview}
            >
                {field.options
                    ? field.options.map((option, i) => (
                          <option key={option} selected={i === 0}>
                              {option}
                          </option>
                      ))
                    : null}
            </select>
        );
    } else {
        return (
            <input
                className={
                    "duration-300 transition-all w-full items-center justify-center rounded-md p-2 leading-none outline-none border"
                }
                id={field.id}
                style={{
                    borderColor: focus ? theme.secondary : theme.border_color,
                    backgroundColor: preview ? theme.quaternary : "transparent",
                }}
                type={field.type}
                required={field.required}
                onFocus={() => setFocus(true)}
                placeholder={preview ? previewValue : ""}
                disabled={preview}
                {...formFieldProps}
            />
        );
    }
};

const FormFieldRow = ({ field, isUI, preview, registerFormField }: Props) => {
    const pageTheme = useTheme();
    const uiTheme = useUITheme();
    const theme = isUI ? uiTheme : pageTheme;

    return (
        <>
            <p className="text-right p-2 col-span-1">
                {field.name}
                {field.required ? "*" : ""}
            </p>
            <div
                className={"col-span-3"}
                style={{
                    color: preview ? theme.secondary : theme.primary,
                }}
            >
                <FormFieldInput
                    field={field}
                    isUI={isUI}
                    preview={preview}
                    registerFormField={registerFormField}
                />
            </div>
        </>
    );
};

export default FormFieldRow;
