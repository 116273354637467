import SocialButton from "./SocialLink";
import { HydratedBlock } from "types";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { Icon } from "components/ui/Icon";

type Props = {
    block: HydratedBlock;
};

export default function Social(props: Props) {
    const { block } = props;
    return (
        <div className="flex flex-row flex-wrap justify-center gap-8">
            <AnimatePresence>
                {(block.properties?.social_links || []).map((s, i) => (
                    <motion.div layout key={s.id}>
                        <SocialButton
                            icon={s.icon as Icon}
                            url={s.url}
                            block={block}
                        />
                    </motion.div>
                ))}
            </AnimatePresence>
        </div>
    );
}
