import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StatusCodes } from "http-status-codes";

import { selectProfile, selectProfileId } from "store/profileSlice";

import { toast } from "sonner";

const ArchivedBanner = () => {
    const profile = useSelector(selectProfile);
    const pid = useSelector(selectProfileId);

    const [archived, setArchived] = useState(false);

    useEffect(() => {
        if (profile) {
            setArchived(profile.archived);
        }
    }, [profile]);

    const _handleError = (error: any) => {
        toast.error(
            `${error.name}: Unable to restore this profile - ${error.message}. Please try again.`,
        );
    };

    const _restoreProfile = async () => {
        try {
            if (
                window.confirm("Are you sure you want to restore this profile?")
            ) {
                const response = await fetch(`/api/v0.1/profiles/${pid}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        pid,
                        updates: { archived: false },
                    }),
                });

                const data = await response.json();

                if (response.status !== StatusCodes.OK) {
                    _handleError(new Error(data.message));
                    return;
                }

                setArchived(false);
            }
        } catch (err: any) {
            _handleError(err);
        }
    };

    if (!archived) return null;
    return (
        <div className="flex flex-wrap justify-center items-center gap-3 h-12 bg-red-500">
            <text className="text-white text-sm">
                This profile is archived.
            </text>
            <label
                className="flex justify-center items-center cursor-pointer hover:opacity-80 transition-all text-sm text-white w-fit h-8 p-2 rounded border-[1px] border-white"
                onClick={_restoreProfile}
            >
                Restore profile
            </label>
        </div>
    );
};

export default ArchivedBanner;
