export default function Background() {
    return (
        <>
            <style jsx global>
                {`
                    body {
                        background: var(--background);
                        overscroll-behavior: none;
                    }
                    .MobilePreviewWrapper {
                        background: var(--background);
                    }
                `}
            </style>
        </>
    );
}
