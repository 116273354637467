import { MediaBlock, Properties } from "types";

import { core_palette } from "utilities/palette";

import { cleanUrl } from "components/profile/Block/lib";
import Button from "components/ui/Button";
import { BiBorderRadius } from "react-icons/bi";
import { useTheme } from "hooks/useTheme";

type Props = {
    properties: Properties & MediaBlock;
};

export function SmallCaption(props: Props) {
    const { properties } = props;
    const theme = useTheme();

    return (
        <div
            className="w-full overflow-hidden absolute left-0 bottom-0 right-0 p-4 flex flex-row justify-between items-center transform-gpu z-20"
            style={{
                backgroundColor: "rgba(0,0,0,.5)",
                backdropFilter: "blur(12px)",
                borderBottomLeftRadius: "inherit",
                borderBottomRightRadius: "inherit",
            }}
        >
            <div>
                <p className="font-semibold text-white text-left">
                    {properties.caption_title_text}
                </p>
                <p className="text-sm text-white opacity-50 text-left">
                    {properties.caption_subtitle_text}
                </p>
            </div>
            {properties.button_text ? (
                <div className="pl-4">
                    <Button
                        backgroundColor={core_palette.primary.dark}
                        color={core_palette.primary.light}
                        title={properties.button_text}
                        style={{
                            minWidth: 56,
                        }}
                        href={cleanUrl(properties.button_url || "")}
                        htmlComponent="a"
                        newTab
                    />
                </div>
            ) : null}
        </div>
    );
}
