import Button from "components/ui/Button";
import { IconsMap } from "components/ui/Icon";
import UIButton from "components/ui/UIButton";
import { useUITheme } from "hooks/useUITheme";
import { ButtonMode, Question } from "types";

interface Props {
    question: Question;
    onDelete: () => void;
    onEdit: () => void;
    provided: any;
}

const FAQRow = ({ question, onDelete, onEdit, provided }: Props) => {
    const theme = useUITheme(true);

    return (
        <>
            <div className="flex flex-row items-center flex-1 gap-4 cursor-default">
                <div
                    className="flex flex-row gap-4 !cursor-auto"
                    data-rbd-drag-handle-context-id={
                        provided.dragHandleProps?.[
                            "data-rbd-drag-handle-context-id"
                        ]
                    }
                >
                    <UIButton
                        icon="trash"
                        iconSize={16}
                        mode={ButtonMode.glass}
                        onClick={onDelete}
                        allowDarkMode
                    />
                </div>
                <div
                    className="p-2 border rounded-xl flex flex-row justify-between items-center gap-4 flex-1 overflow-hidden !cursor-auto"
                    style={{
                        backgroundColor: theme.background_color,
                        borderColor: theme.tertiary,
                    }}
                    data-rbd-drag-handle-context-id={
                        provided.dragHandleProps?.[
                            "data-rbd-drag-handle-context-id"
                        ]
                    }
                >
                    <div>
                        <UIButton
                            icon="edit"
                            iconSize={16}
                            mode={ButtonMode.glass}
                            onClick={onEdit}
                            allowDarkMode
                        />
                    </div>
                    <div className="flex-1 flex-col gap-1 flex overflow-hidden">
                        <h4 className="font-bold break-words">
                            {question.question}
                        </h4>
                        <div
                            className="break-words text-block"
                            dangerouslySetInnerHTML={{
                                __html: question.answer,
                            }}
                        />
                    </div>
                </div>
                <IconsMap.drag size={24} className="cursor-grab" />
            </div>
        </>
    );
};

export default FAQRow;
