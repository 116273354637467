import React from "react";
import { FormField } from "types";
import { useTheme } from "hooks/useTheme";
import { UseFormRegister, FieldValues } from "react-hook-form";
import FormFieldRow from "./FormFieldRow";
import ModalDetachedBox from "components/ui/ModalDetachedBox";
import { useUITheme } from "hooks/useUITheme";

type Props = {
    title?: string;
    description?: string;
    isUI?: boolean;
    fields?: FormField[];
    fetching?: boolean;
    onSubmit: React.FormEventHandler<HTMLFormElement>;
    isOpen: boolean;
    onClose: () => void;
    registerFormField: UseFormRegister<FieldValues>;
};

const FormDialog = ({
    title,
    description,
    fields = [],
    onSubmit,
    isOpen,
    onClose,
    isUI,
    registerFormField,
}: Props) => {
    const pageTheme = useTheme();
    const uiTheme = useUITheme();
    const theme = isUI ? uiTheme : pageTheme;

    return (
        <ModalDetachedBox
            title={title}
            leftIcon="close"
            onClickLeft={onClose}
            isOpen={isOpen}
        >
            <div className="p-4">
                {description ? (
                    <p
                        className="mb-4 leading-normal text-center"
                        style={{
                            color: theme.secondary,
                        }}
                    >
                        {description}
                    </p>
                ) : null}
                <div className="mt-8 flex justify-end">
                    <form
                        className="w-full flex flex-col gap-4"
                        style={{
                            color: theme.primary,
                        }}
                        onSubmit={onSubmit}
                    >
                        <div className="w-full grid grid-cols-4 gap-4">
                            {fields.map(field => (
                                <FormFieldRow
                                    key={field.id}
                                    field={field}
                                    isUI={isUI}
                                    registerFormField={registerFormField}
                                />
                            ))}
                        </div>
                        <button
                            type="submit"
                            className="self-end rounded-xl p-4 font-bold cursor-pointer"
                            style={{
                                backgroundColor: theme.primary,
                                color: theme.inverse,
                            }}
                        >
                            Confirm
                        </button>
                    </form>
                </div>
            </div>
        </ModalDetachedBox>
    );
};

export default FormDialog;
