import type { DragEndEvent, DragStartEvent } from "@dnd-kit/core";
import {
    DndContext,
    DragOverlay,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    SortableContext,
    arrayMove,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS, Transform } from "@dnd-kit/utilities";
import clsx from "clsx";
import Block from "components/profile/Block";
import FileUploaderBlock from "components/profile/Block/FileUploaderBlock";
import { AnimatePresence, motion, useIsPresent } from "framer-motion";
import useBlockReorder from "hooks/useBlockReorder";
import {
    CSSProperties,
    Ref,
    forwardRef,
    useCallback,
    useMemo,
    useRef,
    useState,
} from "react";
import { useSelector } from "react-redux";
import { selectBlockEditor } from "store/blockEditor";
import { HydratedBlock } from "types";

const initialStyles = {
    x: 0,
    y: 0,
    scale: 1,
    opacity: 1,
};

const blockVariants = {
    initial: { scale: 0.95, y: -5, opacity: 0 },
    animate: ({
        transform,
        isDragging,
    }: {
        transform: Transform;
        isDragging: boolean;
    }) =>
        transform
            ? {
                  x: transform.x,
                  y: transform.y,
                  scale: 1,
                  opacity: isDragging ? 0 : 1,
              }
            : initialStyles,
    exit: { scale: 0.9, y: -20, opacity: 0 },
};

const blockVariantsDragOverlay = {
    initialDragOverlay: { scale: 1, zIndex: 0, opacity: 1, filter: "none" },
    animateDragOverlay: {
        scale: 1.02,
        zIndex: 1,
        filter: "brightness(1.1)",
        opacity: 0.8,
    },
    exitDragOverlay: { scale: 1, zIndex: 0, opacity: 1, filter: "none" },
};

const BlockItem = forwardRef(
    (
        props: {
            block: HydratedBlock;
            isPresent?: boolean;
            isDragOverlay?: boolean;
        },
        // needed to suppress framer-motion error when using "popLayout" mode
        forwardedRef: Ref<HTMLDivElement>,
    ) => {
        const { block, isPresent, isDragOverlay } = props;
        const editingBlock = useSelector(selectBlockEditor);
        const [isGrabbing, setIsGrabbing] = useState(false);
        const isSelectedBlock = editingBlock?.id === block.id;

        const setIsGrabbingTrue = useCallback(
            () => setIsGrabbing(true),
            [setIsGrabbing],
        );
        const resetIsGrabbing = useCallback(
            () => setIsGrabbing(false),
            [setIsGrabbing],
        );

        const { attributes, isDragging, listeners, setNodeRef, transform } =
            useSortable({ id: block.id, transition: null });

        const transition = useMemo(
            () => ({
                duration: 0.25,
                easings: {
                    type: "spring",
                    stiffness: 250,
                    damping: 30,
                },
                scale: { duration: 0.25 },
                zIndex: { delay: isDragging ? 0 : 0.25 },
            }),
            [isDragging],
        );

        const style: CSSProperties = {
            transform: CSS.Translate.toString(transform),
            opacity: isDragging ? 0 : 1,
            position: "relative",
        };

        const custom = { isPresent, isDragging, transform };

        return (
            <motion.div
                key={block.id}
                layout
                layoutId={block.id}
                variants={
                    isDragOverlay ? blockVariantsDragOverlay : blockVariants
                }
                initial={isDragOverlay ? "initialDragOverlay" : "initial"}
                animate={isDragOverlay ? "animateDragOverlay" : "animate"}
                exit={isDragOverlay ? "exitDragOverlay" : "exit"}
                custom={custom}
                transition={transition}
                ref={setNodeRef}
                style={style}
                className={clsx("group", {
                    "cursor-grabbing": isGrabbing,
                    "cursor-grab": !isGrabbing,
                })}
                // allow to drag by grabbing anywhere on the block on desktop
                {...listeners}
                {...attributes}
                onPointerDown={setIsGrabbingTrue}
                onPointerUp={resetIsGrabbing}
                onPointerCancel={resetIsGrabbing}
                onPointerLeave={resetIsGrabbing}
                onPointerOut={resetIsGrabbing}
            >
                <Block block={isSelectedBlock ? editingBlock : block} />
            </motion.div>
        );
    },
);
BlockItem.displayName = "DesktopBlockItem";

export function BlocksEditMode() {
    const isPresent = useIsPresent();
    const {
        move,
        localBlocksState,
        blockIds,
        blockInitialIndex,
        setBlockInitialIndex,
    } = useBlockReorder();

    const blocks = localBlocksState;
    const [_, setImage] = useState<Partial<Blob>>();
    const animationRef = useRef<HTMLDivElement>(null);
    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: { distance: 10 },
        }),
        useSensor(TouchSensor, {
            activationConstraint: { delay: 250, tolerance: 5 },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    if (!blocks || !blockIds) return null;

    const handleDragStart = ({ active }: DragStartEvent) => {
        const activeIndex = blocks.findIndex(({ id }) => id === active.id);
        setBlockInitialIndex(activeIndex);
    };

    const handleDragEnd = async ({ active, over }: DragEndEvent) => {
        if (over && active.id !== over?.id) {
            const activeIndex = blocks.findIndex(({ id }) => id === active.id);
            const overIndex = blocks.findIndex(({ id }) => id === over.id);
            move(arrayMove(blocks, activeIndex, overIndex));
        }
    };

    return (
        <DndContext
            sensors={sensors}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={blockIds}
                strategy={verticalListSortingStrategy}
            >
                <FileUploaderBlock changeInputFile={setImage}>
                    <AnimatePresence initial={false} mode="popLayout">
                        {blockIds.map(bid => (
                            <BlockItem
                                key={bid}
                                ref={animationRef}
                                block={
                                    blocks[
                                        blocks.findIndex(({ id }) => id === bid)
                                    ]
                                }
                                isPresent={isPresent}
                            />
                        ))}
                    </AnimatePresence>
                </FileUploaderBlock>
            </SortableContext>
            <DragOverlay>
                <BlockItem
                    block={blocks[blockInitialIndex || 0]}
                    isDragOverlay
                />
            </DragOverlay>
        </DndContext>
    );
}
