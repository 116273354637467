import * as SwitchRadix from "@radix-ui/react-switch";
import { theme_shades } from "utilities/palette";
import { Property } from "types";
import { useUITheme } from "hooks/useUITheme";
import { IconsMap } from "./Icon";

type Props = {
    checked: boolean;
    property?: Property;
    onCheckChange: (checked: boolean) => void;
    allowDarkMode?: boolean;
};
const Switch = (props: Props) => {
    const theme = useUITheme(props.allowDarkMode);

    return (
        <>
            <SwitchRadix.Root
                className=" rounded-full cursor-pointer relative"
                checked={!!props.checked}
                onCheckedChange={props.onCheckChange}
                style={{
                    backgroundColor: props.checked
                        ? theme_shades.light_blue.medium
                        : theme.tertiary,
                    width: 42,
                    height: 25,
                    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                }}
            >
                <SwitchRadix.Thumb
                    className="shadow-[0_2px_2px] shadow-blackA7 bg-white rounded-full translate-x-[2px] data-[state=checked]:translate-x-[19px] will-change-transform transition-transform duration-100 flex justify-center items-center"
                    style={{ width: 21, height: 21 }}
                >
                    {props.checked ? (
                        <IconsMap.check
                            className="w-4 h-3.5 absolute"
                            color={theme_shades.light_blue.medium}
                        />
                    ) : null}
                </SwitchRadix.Thumb>
            </SwitchRadix.Root>
        </>
    );
};

export default Switch;
