import Head from "components/profile/Head";
import { getProfileFont } from "components/settings/options/fontOptions";
import { ScrollContext } from "hooks/ScrollContext";
import useIsScrolling from "hooks/useIsScrolling";
import { useSettings } from "hooks/useSettings";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    selectIsActivePageIsApp,
    setActivePage,
    setProfile,
    setSnackpassStore,
} from "store/profileSlice";
import { Profile, SnackpassStore } from "types";
import Background from "./Background";
import PreviewWrapper from "./PreviewWrapper";
import ErrorPage from "./ErrorPage";
import ArchivedBanner from "./ArchivedBanner";
import Navbar from "./Navbar";
import BlocksLayout from "./BlocksLayout";
import AddBlock from "./AddBlock";
import Footer from "./Footer";
import BlockEditor from "./BlockEditor";
import SnackBranding from "./SnackBranding";

export interface VisitorProps {
    profile?: Profile | null;
    snackpassStore?: SnackpassStore;
    errorMessage?: string;
}

// WARNING: Visitor component should be a dummy component that
//          is independent of user authentication and logic.
//          For example, useUser hook is outside of Visitor component.
//          This way it can be used in both authenticated and unauthenticated pages.
//          Additionally, do not attempt to access profile data here.
//          Instead, defer to using profile related selectors (e.g. selectProfile)
//          outside of the Visitor component as well.
export default function Visitor(props: VisitorProps) {
    const { profile, snackpassStore, errorMessage } = props;
    const router = useRouter();
    const dispatch = useDispatch();
    const { page } = router.query;
    const settings = useSettings();
    const isScrolling = useIsScrolling();

    // set firebase profile data to redux for use globally
    useEffect(() => {
        dispatch(setProfile(profile));
    }, [profile]);

    // need to add page as a dependency to update the active page
    // this sets the active page, which then determines
    // the rest of the block tree for the page
    useEffect(() => {
        dispatch(setActivePage(page));
    }, [page]);

    useEffect(() => {
        dispatch(setSnackpassStore(snackpassStore));
    }, [snackpassStore]);

    const font = getProfileFont(settings.font);
    const isApp = useSelector(selectIsActivePageIsApp);

    if (errorMessage) {
        return <ErrorPage errorMessage={errorMessage} />;
    }

    if (!snackpassStore) {
        return (
            <>
                <Head profile={profile} />
                <ErrorPage errorMessage="Snack account not found for this username." />
            </>
        );
    }
    return (
        <main className={font}>
            <Head profile={profile} />
            <ScrollContext.Provider value={{ isScrolling }}>
                <PreviewWrapper>
                    <Background />
                    <div>
                        <ArchivedBanner />
                        <Navbar />
                        <BlocksLayout />
                        <SnackBranding />
                        {isApp ? null : (
                            <>
                                <AddBlock />
                                <Footer />
                            </>
                        )}
                    </div>
                </PreviewWrapper>
                <BlockEditor />
            </ScrollContext.Provider>
        </main>
    );
}
