import { useUITheme } from "hooks/useUITheme";
import { ChangeEvent } from "react";

interface Props {
    datetime: string;
    onDateTimeChange: (datetime: string) => void;
}

const DateTimeInput = ({ datetime, onDateTimeChange }: Props) => {
    const theme = useUITheme();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onDateTimeChange(e.target.value);
    };

    return (
        <input
            type="datetime-local"
            className={`
                text-left overflow-hidden rounded-lg p-2 border 
                flex flex-row items-center justify-between flex-1 
                cursor-pointer hover:brightness-90 duration-200 h-10
            `}
            style={{
                backgroundColor: theme.quinary,
                borderColor: theme.quaternary,
            }}
            onChange={handleChange}
            value={datetime}
        />
    );
};

export default DateTimeInput;
