import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { clsx } from "clsx";
import Button from "components/ui/Button";
import useEditMode from "hooks/useEditMode";
import { useCallback, useState } from "react";
import { ButtonMode, HydratedBlock } from "types";

type Props = {
    block: HydratedBlock;
    setActivatorNodeRef: any;
    listeners: SyntheticListenerMap | undefined;
    attributes: DraggableAttributes;
};
export function DragHandle(props: Props) {
    const { block, setActivatorNodeRef, listeners, attributes } = props;

    const { editMode } = useEditMode();

    const [isGrabbing, setIsGrabbing] = useState(false);

    const setIsGrabbingTrue = useCallback(
        () => setIsGrabbing(true),
        [setIsGrabbing],
    );
    const resetIsGrabbing = useCallback(
        () => setIsGrabbing(false),
        [setIsGrabbing],
    );

    // handle showing the grab/grabbing cursors to signal to users that pages are
    // grabbable/draggable
    const getGrabCursorClassName = useCallback(() => {
        if (editMode && isGrabbing) {
            return "cursor-grabbing";
        } else if (editMode) {
            return "cursor-grab";
        }
        return "";
    }, [editMode, isGrabbing]);

    const pageUrl = block.properties?.page_url;
    const isHomePage = !pageUrl;
    const showDragHandle = !isHomePage && editMode;

    if (!showDragHandle) {
        return null;
    }

    return (
        <div
            ref={setActivatorNodeRef}
            {...listeners}
            {...attributes}
            onPointerDown={setIsGrabbingTrue}
            onPointerUp={resetIsGrabbing}
            onPointerCancel={resetIsGrabbing}
            onPointerLeave={resetIsGrabbing}
            onPointerOut={resetIsGrabbing}
        >
            <Button
                icon="drag"
                iconSize={20}
                mode={ButtonMode.ghost}
                className={clsx("px-1", getGrabCursorClassName())}
            />
        </div>
    );
}
