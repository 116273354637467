import React from "react";
import { ButtonMode } from "types";
import { CAROUSEL_CONTROLS_HEIGHT } from "utilities/constants";
import Button from "components/ui/Button";
import { ArrowProps } from "./types";

const Arrow = ({ color, icon, onClick }: ArrowProps) => {
    return (
        <Button
            onClick={onClick}
            color={color}
            icon={icon}
            iconSize={20}
            mode={ButtonMode.ghost}
            className={`grow-0 text-[20px] w-[${CAROUSEL_CONTROLS_HEIGHT}px] h-[${CAROUSEL_CONTROLS_HEIGHT}px]`}
        />
    );
};

export default Arrow;
