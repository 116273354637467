import { clsx } from "clsx";
import { Icon, IconsMap } from "components/ui/Icon";
import { useUITheme } from "hooks/useUITheme";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    selectActivePropertyTab,
    selectBlockEditor,
    setActivePropertyTab,
} from "store/blockEditor";
import { BlockType } from "types";
import { PropertyTab, propertyList } from "./properties";

interface TabProps {
    onClick: (tab: PropertyTab) => void;
    isSelected?: boolean;
    tab: PropertyTab;
}

const iconMap: { [key in PropertyTab]: Icon } = {
    [PropertyTab.Block]: "block",
    [PropertyTab.Media]: "image",
    [PropertyTab.MediaStyle]: "paint_roll",
    [PropertyTab.Caption]: "text",
    [PropertyTab.Text]: "paragraph",
    [PropertyTab.Color]: "color_swatch",
    [PropertyTab.Links]: "link",
    [PropertyTab.Social]: "social_outline",
    [PropertyTab.Carousel]: "carousel",
    [PropertyTab.Advanced]: "gear",
    [PropertyTab.Embed]: "code",
    [PropertyTab.FAQ]: "comment",
    [PropertyTab.Form]: "menu",
    [PropertyTab.Bio]: "profile",
    [PropertyTab.Style]: "layout",
    [PropertyTab.Title]: "title",
    [PropertyTab.Overlay]: "brightness",
    [PropertyTab.BackgroundColor]: "color_swatch",
    [PropertyTab.Duplicate]: "add_box",
    [PropertyTab.Delete]: "add_box",
    [PropertyTab.Type]: "block",
    [PropertyTab.Autoplay]: "play",
    [PropertyTab.Layout]: "layout",
};

const Tab = ({ onClick, isSelected, tab }: TabProps) => {
    const theme = useUITheme(true);
    const Icon = IconsMap[iconMap[tab]];

    return (
        <div
            className={clsx(
                "flex items-center flex-row cursor-pointer duration-200 transition-brightness transition-opacity transition-background p-2",
                {
                    "hover:brightness-90": !theme.dark_mode,
                    "bg-hover:!bg-opacity-30 hover:!bg-gray11/20":
                        theme.dark_mode,
                },
            )}
            style={{
                borderRadius: 8,
                backgroundColor: isSelected
                    ? theme.quaternary
                    : theme.background_color,
            }}
            onClick={() => {
                onClick(tab);
            }}
            key={tab}
        >
            <div className="p-2 border rounded-[8px]">
                <Icon color={theme.primary} size={22} />
            </div>
            <span
                className="text-sm whitespace-nowrap ml-3 text-normal"
                style={{
                    color: theme.primary,
                }}
            >
                {tab}
            </span>
        </div>
    );
};

export function PropertyTabs() {
    const block = useSelector(selectBlockEditor);
    const tabs = Object.values(PropertyTab);
    const theme = useUITheme(false);
    const dispatch = useDispatch();

    const _onClickTab = useCallback(
        (tab: PropertyTab) => {
            dispatch(setActivePropertyTab(tab));
        },
        [dispatch],
    );

    const activePropertyTab = useSelector(selectActivePropertyTab);
    const _filterTab = (tab: PropertyTab) => {
        if (!block) return false;

        // Bio property tab directly updates the bio and profile picture, which are not block properties.
        // We need logic that is separate from block properties for this
        const isBioTab =
            block.type === BlockType.bio && tab === PropertyTab.Bio;

        // based on block type, filter out tabs that are not supported
        const blockProperties = propertyList.filter(
            p => p.blocks.includes(block.type) || p.blocks.includes("all"),
        );

        return (
            blockProperties.filter(p => p.tab === tab).length > 0 || isBioTab
        );
    };

    if (activePropertyTab) {
        return null;
    }

    return (
        <div
            className={clsx("flex px-4 disable-scrollbars py-2 flex-col gap-1")}
            style={{
                borderColor: theme.border_color,
            }}
        >
            {tabs.filter(_filterTab).map(tab => {
                const isSelected = activePropertyTab === tab;

                return (
                    <Tab
                        key={tab}
                        onClick={_onClickTab}
                        isSelected={isSelected}
                        tab={tab}
                    />
                );
            })}
        </div>
    );
}
