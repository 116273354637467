import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { CheckIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { IconsMap } from "components/ui/Icon";
import useEditMode from "hooks/useEditMode";
import { useTheme } from "hooks/useTheme";
import { AppType, HydratedBlock, PageType } from "types";

export enum TabActions {
    RENAME = "RENAME",
    USE_PAGE = "USE_PAGE",
    USE_CUSTOM_URL = "USE_CUSTOM_URL",
    USE_ONLINE_ORDER = "USE_ONLINE_ORDER",
    USE_CATERING = "USE_CATERING",
    USE_GIFT_CARDS = "USE_GIFT_CARDS",
    USE_LOCATIONS = "USE_LOCATIONS",
    DELETE = "DELETE",
}

type Props = {
    block: HydratedBlock;
    onAction: (action: TabActions) => void;
};

const Separator = () => {
    const theme = useTheme();
    return (
        <DropdownMenu.Separator
            className="h-[1px]"
            style={{
                background: theme.border_color,
            }}
        />
    );
};
export default function Dropdown(props: Props) {
    const { block, onAction } = props;
    const { editMode } = useEditMode();

    const pageUrl = block.properties?.page_url;
    const isHome = !!!pageUrl;
    const showEditButtons = editMode && !isHome;
    if (!showEditButtons) {
        return null;
    }

    return (
        <div className="flex flex-row items-center">
            <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                    <div
                        className={`cursor-pointer hover:backdrop-brightness-110 transition-all duration-200 p-2 rounded-full`}
                    >
                        <IconsMap.pen className="theme-secondary-text" />
                    </div>
                </DropdownMenu.Trigger>
                <DropdownMenu.Portal>
                    <DropdownMenu.Content
                        className="DropdownMenuContent"
                        sideOffset={20}
                    >
                        {/* <DropdownMenu.Label className="pl-[25px] text-xs leading-[25px] text-mauve11">
                            Types
                        </DropdownMenu.Label> */}
                        <DropdownMenu.CheckboxItem
                            className="DropdownMenuItem"
                            onClick={() => onAction(TabActions.USE_PAGE)}
                            checked={
                                block?.properties?.page_type ===
                                    PageType.PAGE ||
                                !block?.properties?.page_type
                            }
                        >
                            <DropdownMenu.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
                                <CheckIcon />
                            </DropdownMenu.ItemIndicator>
                            Use Page
                            <div className="RightSlot">
                                <IconsMap.page />
                            </div>
                        </DropdownMenu.CheckboxItem>
                        <DropdownMenu.CheckboxItem
                            className="DropdownMenuItem"
                            onClick={() => onAction(TabActions.USE_CUSTOM_URL)}
                            checked={
                                block?.properties?.page_type === PageType.URL
                            }
                        >
                            <DropdownMenu.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
                                <CheckIcon />
                            </DropdownMenu.ItemIndicator>
                            Use Custom Link
                            <div className="RightSlot">
                                <IconsMap.link />
                            </div>
                        </DropdownMenu.CheckboxItem>

                        <DropdownMenu.Sub>
                            <DropdownMenu.SubTrigger className="DropdownMenuItem">
                                Use Snackpass Apps
                                <div className="RightSlot">
                                    <ChevronRightIcon />
                                </div>
                            </DropdownMenu.SubTrigger>
                            <DropdownMenu.Portal>
                                <DropdownMenu.SubContent
                                    className="DropdownMenuContent"
                                    sideOffset={2}
                                    alignOffset={-5}
                                >
                                    <DropdownMenu.CheckboxItem
                                        className="DropdownMenuItem"
                                        onClick={() =>
                                            onAction(
                                                TabActions.USE_ONLINE_ORDER,
                                            )
                                        }
                                        checked={
                                            block?.properties?.page_type ===
                                                PageType.APP &&
                                            block.properties?.app_type ===
                                                AppType.OnlineOrdering
                                        }
                                    >
                                        <DropdownMenu.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
                                            <CheckIcon />
                                        </DropdownMenu.ItemIndicator>
                                        Online Order
                                        <div className="RightSlot">
                                            <IconsMap.laptop />
                                        </div>
                                    </DropdownMenu.CheckboxItem>
                                    <DropdownMenu.CheckboxItem
                                        className="DropdownMenuItem"
                                        checked={
                                            block?.properties?.page_type ===
                                                PageType.APP &&
                                            block.properties?.app_type ===
                                                AppType.GiftCards
                                        }
                                        onClick={() =>
                                            onAction(TabActions.USE_GIFT_CARDS)
                                        }
                                    >
                                        <DropdownMenu.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
                                            <CheckIcon />
                                        </DropdownMenu.ItemIndicator>
                                        Gift Cards
                                        <div className="RightSlot">
                                            <IconsMap.gift />
                                        </div>
                                    </DropdownMenu.CheckboxItem>
                                    <DropdownMenu.CheckboxItem
                                        className="DropdownMenuItem"
                                        onClick={() =>
                                            onAction(TabActions.USE_CATERING)
                                        }
                                        checked={
                                            block?.properties?.page_type ===
                                                PageType.APP &&
                                            block.properties?.app_type ===
                                                AppType.Catering
                                        }
                                    >
                                        <DropdownMenu.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
                                            <CheckIcon />
                                        </DropdownMenu.ItemIndicator>
                                        Catering
                                        <div className="RightSlot">
                                            <IconsMap.bell_outline />
                                        </div>
                                    </DropdownMenu.CheckboxItem>
                                    <DropdownMenu.CheckboxItem
                                        className="DropdownMenuItem"
                                        onClick={() =>
                                            onAction(TabActions.USE_LOCATIONS)
                                        }
                                        checked={
                                            block?.properties?.page_type ===
                                                PageType.APP &&
                                            block.properties?.app_type ===
                                                AppType.Locations
                                        }
                                    >
                                        <DropdownMenu.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
                                            <CheckIcon />
                                        </DropdownMenu.ItemIndicator>
                                        Locations
                                        <div className="RightSlot">
                                            <IconsMap.store />
                                        </div>
                                    </DropdownMenu.CheckboxItem>
                                </DropdownMenu.SubContent>
                            </DropdownMenu.Portal>
                        </DropdownMenu.Sub>

                        <Separator />
                        {/* <DropdownMenu.Label className="pl-[25px] text-xs leading-[25px] text-mauve11">
                            Actions
                        </DropdownMenu.Label> */}
                        <DropdownMenu.CheckboxItem
                            className="DropdownMenuItem"
                            onClick={() => onAction(TabActions.RENAME)}
                        >
                            Rename
                            <div className="RightSlot">
                                <IconsMap.text />
                            </div>
                        </DropdownMenu.CheckboxItem>
                        <DropdownMenu.CheckboxItem
                            className="DropdownMenuItem"
                            onClick={() => onAction(TabActions.DELETE)}
                        >
                            Delete
                            <div className="RightSlot">
                                <IconsMap.trash />
                            </div>
                        </DropdownMenu.CheckboxItem>
                    </DropdownMenu.Content>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>
        </div>
    );
}
