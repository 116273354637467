import * as Dialog from "@radix-ui/react-dialog";
import classNames from "classnames";
import Button from "components/ui/Button";
import { ButtonMode } from "types";
import { Icon } from "./Icon";
import UIButton from "./UIButton";

type Props = {
    isOpen: boolean;
    setIsOpen?: (isOpen: boolean) => void;
    title?: string;
    onClickLeft?: () => void;
    onClickRight?: () => void;
    leftTitle?: string;
    rightTitle?: string;
    leftIcon?: Icon;
    rightIcon?: Icon;
    children?: React.ReactNode;
    fullScreen?: boolean;
    backgroundColor?: string;
};

export default function Modal(props: Props) {
    const {
        isOpen,
        setIsOpen,
        children,
        leftIcon = "no_icon",
        leftTitle = "",
        title = "",
        rightIcon = "no_icon",
        rightTitle = "",
        onClickLeft = () => {},
        onClickRight = () => {},
        fullScreen = true,
        backgroundColor = "unset",
    } = props;

    return (
        <Dialog.Root modal open={isOpen} onOpenChange={setIsOpen}>
            <Dialog.Portal>
                <Dialog.Overlay className="z-30 bg-blackA9 fixed inset-0 data-[state=open]:animate-fadeIn data-[state=closed]:animate-fadeOut" />
                <Dialog.Content className="z-30 flex flex-col justify-end items-center fixed top-0 left-0 right-0 bottom-0 data-[state=open]:animate-modalSlideUp data-[state=closed]:animate-modalSlideDown">
                    <div
                        className={classNames(
                            "bg-white w-full h-full relative",
                            {
                                "max-w-3xl max-h-[98vh] rounded-t-2xl":
                                    !fullScreen,
                            },
                        )}
                    >
                        <div className="flex flex-row justify-between items-center py-2 border-b">
                            {leftTitle || leftIcon !== "no_icon" ? (
                                <div className="flex-1">
                                    <div>
                                        <UIButton
                                            mode={ButtonMode.ghost}
                                            onClick={onClickLeft}
                                            title={leftTitle}
                                            icon={leftIcon}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="flex-1" />
                            )}
                            {title ? (
                                <div className="text-lg text-center font-semibold flex-2">
                                    {title}
                                </div>
                            ) : (
                                <div className="text-lg text-center font-semibold flex-2" />
                            )}
                            {rightTitle || rightIcon !== "no_icon" ? (
                                <div className="flex-1 flex flex-row justify-end">
                                    <div>
                                        <UIButton
                                            mode={ButtonMode.ghost}
                                            onClick={onClickRight}
                                            title={rightTitle}
                                            icon={rightIcon}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="flex-1 flex flex-row justify-end" />
                            )}
                        </div>
                        <div
                            className="h-full w-full overflow-y-auto"
                            style={{
                                backgroundColor,
                            }}
                        >
                            {children}
                        </div>
                    </div>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}
