import { useUITheme } from "hooks/useUITheme";
import { useState } from "react";
import { TwitterPicker, SketchPicker } from "react-color";
import { Property } from "types";
import { Shade, theme_shades } from "utilities/palette";

type Props = {
    property: Property;
    value: any;
    onValueChange: (color: string) => void;
};
export default function ColorPicker(props: Props) {
    const { property, value, onValueChange } = props;
    const theme = useUITheme();

    const [customPicker, setCustomPicker] = useState(false);

    const themeColors = Object.values(theme_shades);
    let colors = themeColors.map(color => color[Shade.subtle]);

    return (
        <div className="flex flex-col">
            {customPicker ? (
                <SketchPicker
                    color={value}
                    onChangeComplete={(color: any) => {
                        onValueChange(color.hex);
                    }}
                />
            ) : (
                <TwitterPicker
                    color={value}
                    onChangeComplete={color => {
                        onValueChange(color.hex);
                    }}
                    colors={colors}
                    triangle="hide"
                />
            )}
            <p
                className="text-center text-sm underline mt-4 cursor-pointer"
                style={{ color: theme.secondary }}
                onClick={() => setCustomPicker(!customPicker)}
            >
                Switch to {customPicker ? "default picker" : "custom picker"}
            </p>
        </div>
    );
}
