import clsx from "clsx";
import React, { Dispatch, SetStateAction, useMemo } from "react";
// @ts-ignore this fixes react-swipeable-views react-18 error but doesn't have proper typing
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { useTheme } from "hooks/useTheme";
import parseColor from "utilities/parseColor";
import { hasMoreSlides, getSliderMetadata } from "./lib";

type DotsProps = {
    length: number;
    activeIndex: number;
    setIndex: Dispatch<SetStateAction<number>>;
};

type DotProps = DotsProps & {
    currentIndex: number;
};

const Dot = ({ currentIndex, activeIndex, setIndex, length }: DotProps) => {
    const theme = useTheme();
    const selectDot = () => setIndex(currentIndex);
    const baseColor = theme.primary;
    const baseColorRGB = parseColor(baseColor);
    const rgbColorInterpolation = `${baseColorRGB.r}, ${baseColorRGB.g}, ${baseColorRGB.b}`;
    const activeColor = `rgba(${rgbColorInterpolation}, 0.8)`;
    const inactiveColor = `rgba(${rgbColorInterpolation}, 0.4)`;

    const moreSlides = hasMoreSlides({
        currentIndex,
        activeIndex,
        length,
    });

    const className = clsx(
        currentIndex === activeIndex
            ? "h-[8px]"
            : moreSlides
            ? "h-[6px]"
            : "h-[8px]",
        currentIndex === activeIndex
            ? "w-[8px]"
            : moreSlides
            ? "w-[6px]"
            : "w-[8px]",
        "rounded-full",
        "mx-[5px]",
        "my-0",
        "cursor-pointer",
    );

    const dotStyles = useMemo(
        (): React.CSSProperties => ({
            // rbg/rbga arbitrary values sometimes don't work with tailwind v3
            background:
                currentIndex === activeIndex ? activeColor : inactiveColor,
        }),
        [activeIndex, currentIndex, activeColor, inactiveColor],
    );

    return (
        <span
            key={currentIndex}
            style={dotStyles}
            className={className}
            onClick={selectDot}
        />
    );
};

const slideContainerStyle: React.CSSProperties = {
    width: "18px",
    height: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

const Dots = ({ length, activeIndex, setIndex }: DotsProps) => {
    const children = [];

    for (let i = 0; i < length; i += 1) {
        children.push(
            <Dot
                key={i}
                currentIndex={i}
                activeIndex={activeIndex}
                setIndex={() => setIndex(i)}
                length={length}
            />,
        );
    }

    const { fixedIndex, sliderPadding } = getSliderMetadata({
        activeIndex,
        length,
    });

    const sliderContainerStyles = useMemo(
        (): React.CSSProperties => ({
            width: `126px`,
            padding: `0px ${sliderPadding * 18}px`,
        }),
        [sliderPadding],
    );

    return (
        <SwipeableViews
            index={fixedIndex}
            // there is no className-like property for the slider container
            // `className` gets applied to the slider's root component, not the container
            style={sliderContainerStyles}
            // passing classes to slideClassName gets overridden by default css in react-swipeable-views
            slideStyle={slideContainerStyle}
            disabled
        >
            {children}
        </SwipeableViews>
    );
};

export default Dots;
