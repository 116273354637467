import Button from "components/ui/Button";
import { Icon, IconsMap } from "components/ui/Icon";
import { useForm } from "react-hook-form";
import Image from "next/image";
import { useState } from "react";
import { ButtonMode, HydratedBlock } from "types";
import ConfirmationDialog from "./ConfirmationDialog";
import FormDialog from "./FormDialog";
import { useTheme } from "hooks/useTheme";
import CountdownTimer from "./CountdownTimer";
import { DEFAULT_BLOCK_HEIGHT } from "utilities/constants";
import useSWR from "swr";
import fetcher from "utilities/fetcher";
import classNames from "classnames";

type Props = {
    block: HydratedBlock;
};

export default function FormBlock({ block }: Props) {
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
    const { handleSubmit, reset, register: registerFormField } = useForm();
    const properties = block.properties;
    const theme = useTheme();

    const { data, mutate } = useSWR(
        `/api/v0.1/forms/data/${block.id}`,
        fetcher,
    );

    if (!properties?.form_title)
        return (
            <div
                className="flex-col flex justify-center items-center rounded-xl w-full h-full transition-all duration-200"
                style={{ height: DEFAULT_BLOCK_HEIGHT }}
            >
                <IconsMap.contact color={theme.secondary} size={48} />
            </div>
        );

    const onSubmit = async (data: any) => {
        await fetch(`/api/v0.1/forms/data/${block.id}`, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(data),
        });
        setIsOpenForm(false);
        setIsOpenConfirmation(true);
        reset();
        mutate();
    };

    return (
        <div
            className={classNames(
                "flex-col flex items-center rounded-xl overflow-hidden transition-all duration-200",
                properties.form_image_url ? "" : "p-2 gap-2",
            )}
        >
            {properties.form_image_url ? (
                <div className="relative w-full h-[240px]">
                    <Image
                        src={properties.form_image_url}
                        alt="image"
                        fill
                        className="object-cover"
                    />
                </div>
            ) : null}
            {properties.form_expired_at ? (
                <div className="absolute top-2 right-2">
                    <CountdownTimer deadline={properties.form_expired_at} />
                </div>
            ) : null}
            <div className="h-full w-full gap-4 flex-col p-4 flex justify-between items-start rounded-xl overflow-hidden text-block">
                <div className="w-full flex flex-col gap-4">
                    {properties.form_title ? (
                        <h1
                            className="text-left w-full"
                            style={{
                                lineHeight: "0.9em",
                                color: theme.primary,
                            }}
                        >
                            {properties.form_title}
                        </h1>
                    ) : null}
                    {properties.form_description ? (
                        <p
                            className="text-left w-full"
                            style={{
                                color: theme.secondary,
                            }}
                        >
                            {properties.form_description}
                        </p>
                    ) : null}
                </div>
            </div>
            <div className="w-full flex flex-row justify-center p-4">
                <Button
                    mode={ButtonMode.ghost}
                    animateScale
                    icon={properties.form_button_icon as Icon}
                    title={properties.form_button_title || "Open"}
                    className="max-w-xs"
                    onClick={() => setIsOpenForm(true)}
                    backgroundColor={theme.background_color}
                    color={theme.primary}
                />
            </div>
            {properties.form_show_entries && data?.length > 0 ? (
                <div
                    className="flex flex-row items-center gap-1 w-full pb-4 justify-center"
                    style={{
                        color: theme.secondary,
                    }}
                >
                    <IconsMap.lightning color={theme.secondary} />
                    <span>{data.length} entered</span>
                </div>
            ) : null}
            <FormDialog
                description={properties.form_description}
                title={properties.form_title}
                fields={properties.form_fields}
                onSubmit={handleSubmit(onSubmit)}
                isOpen={isOpenForm}
                onClose={() => setIsOpenForm(false)}
                registerFormField={registerFormField}
                isUI // TODO: Leo: Use page theme after new themes are merged in
            />
            {properties.form_confirmation_title ? (
                <ConfirmationDialog
                    title={properties.form_confirmation_title}
                    message={properties.form_confirmation_description}
                    confetti_image_url={properties.form_confetti_image_url}
                    isOpen={isOpenConfirmation}
                    onClose={() => setIsOpenConfirmation(false)}
                />
            ) : null}
        </div>
    );
}
