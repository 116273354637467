import clsx from "clsx";
import _ from "lodash";
import React from "react";

import {
    HydratedBlock,
    Theme,
    BlockType,
    BlockBackgroundStyle,
    RichEmbedType,
} from "types";
import { isChildOfParentBlock } from "utilities/blockTypeUtils";

import useBreakpoints from "./useBreakpoints";
import useEditMode from "./useEditMode";
import { useTheme } from "./useTheme";
import { useBlockHeight } from "./useBlockHeight";

export function getBlockClassName(block?: HydratedBlock) {
    const properties = block?.properties;
    //currently text, faq, form and embed has BlockBackgroundStyle
    // theme-border is just box shadow
    if (
        block?.type === BlockType.text ||
        block?.type === BlockType.faq ||
        block?.type === BlockType.form ||
        block?.type === BlockType.embed ||
        block?.type === BlockType.hours
    ) {
        switch (properties?.background_style) {
            case BlockBackgroundStyle.none:
                return "";
            case BlockBackgroundStyle.default:
            case BlockBackgroundStyle.subtle:
                return "theme-tertiary-background";
            case BlockBackgroundStyle.outline:
                return "theme-border";
            default:
                return "theme-tertiary-background";
        }
    }
    return "";
}

export function getBorderRadius(
    theme: Theme,
    block: HydratedBlock,
    isMobile?: boolean,
    fromCarousel?: boolean,
) {
    return 0;
}

function getPadding(block: HydratedBlock, fromCarousel?: boolean) {
    if (fromCarousel) {
        return "p-0";
    }

    let xPadding = "0";

    if (block.properties?.full_width) {
        xPadding = "0";
    } else {
        xPadding = "4";
    }

    const pt = block.properties?.padding_top || "0";
    const pb = block.properties?.padding_bottom || "0";
    return `px-${xPadding} pt-${pt} pb-${pb}`;
}

function getTitlePadding(block: HydratedBlock, isMobile: boolean) {
    return "px-4";
}

type BlockStyle = {
    style?: React.CSSProperties;
    classname?: string;
    parentClassname?: string;
    titleClassname?: string;
};
export function useBlockStyle(
    block?: HydratedBlock,
    fromCarousel?: boolean,
    maxChildHeight?: number,
): BlockStyle {
    const { isMobileView } = useBreakpoints();
    const { editMode } = useEditMode();
    const theme = useTheme();
    const { height } = useBlockHeight(block, maxChildHeight);

    if (!block) {
        return {};
    }

    const blockClassName = getBlockClassName(block);
    const borderRadius = getBorderRadius(
        theme,
        block,
        isMobileView,
        fromCarousel,
    );
    const padding = getPadding(block, fromCarousel);
    const titlePadding = getTitlePadding(block, isMobileView);
    const _isChildOfParentBlock = isChildOfParentBlock(block);

    // works similarly to nextjs Image fill prop
    const fill = clsx("absolute inset-0 h-full w-full flex flex-col");

    const classname = clsx(
        "flex flex-col",
        // fill parent block if current bock is child of a parent
        _isChildOfParentBlock && fill,
        editMode && "transition-[height] duration-300",
        blockClassName,
    );
    // position relative is needed for the cell editor to position correctly
    // TODO: clean this up so we don't accidentally not add parent or title classname to a block
    const parentClassname = clsx(
        "relative flex flex-col flex-grow",
        padding,
        "",
    );
    const titleClassname = clsx(titlePadding, "py-4 font-semibold text-lg");

    switch (block.type) {
        case BlockType.media:
            return {
                style: {
                    borderRadius,
                    WebkitBorderRadius: borderRadius,
                    height,
                    overflow: "hidden",
                },
                classname: clsx(classname, "transform-gpu"),
                parentClassname,
                titleClassname,
            };
        case BlockType.text:
            return {
                style: {
                    borderRadius,
                },
                classname,
                parentClassname,
                titleClassname,
            };
        case BlockType.links:
            return {
                style: {
                    paddingLeft: isMobileView ? 0 : 16,
                    paddingRight: isMobileView ? 0 : 16,
                    paddingTop: 4,
                    paddingBottom: 4,
                },
                classname,
                parentClassname,
                titleClassname,
            };
        case BlockType.social:
            return {
                style: {
                    paddingLeft: isMobileView ? 0 : 16,
                    paddingRight: isMobileView ? 0 : 16,
                    paddingTop: 4,
                    paddingBottom: 4,
                },
                classname,
                parentClassname,
                titleClassname,
            };
        case BlockType.bio:
            return {
                style: {
                    paddingLeft: isMobileView ? 0 : 16,
                    paddingRight: isMobileView ? 0 : 16,
                    borderRadius,
                },
                classname,
                parentClassname,
                titleClassname,
            };
        case BlockType.carousel:
            return {
                style: {
                    height,
                    borderRadius,
                },
                classname,
                parentClassname,
                titleClassname,
            };
        case BlockType.embed:
            return {
                style: {
                    borderRadius,
                    height:
                        block.properties?.rich_embed_type ===
                        RichEmbedType.TikTok
                            ? ""
                            : height,
                    overflow: "hidden",
                },
                classname,
                parentClassname,
                titleClassname,
            };
        case BlockType.form:
            return {
                style: {
                    color: theme.inverse,
                    borderRadius,
                },
                classname: clsx(classname, "transform-gpu backdrop-blur-xl"),
                parentClassname,
                titleClassname,
            };
        case BlockType.page:
            return {
                style: {},
                classname: "",
                parentClassname: "",
                titleClassname: "",
            };

        case BlockType.faq:
            return {
                style: {
                    borderRadius,
                },
                classname,
                parentClassname,
                titleClassname,
            };
        default:
            return {
                style: {},
                classname,
                parentClassname,
            };
    }
}
