import { useEffect } from "react";
import type { MutableRefObject } from "react";

export default function useOnClickOutside<T>(
    ref: MutableRefObject<HTMLDivElement | null>,
    handler: (event: Event) => void,
) {
    useEffect(
        () => {
            const listener = (event: Event) => {
                // media block background color picker
                const excludeElements = [
                    document.querySelector(
                        "#media-block-background-color-picker",
                    ),
                    document.querySelector("#detached-box"),
                    ...Array.from(document.querySelectorAll("#cell-editor")),
                ];
                if (
                    excludeElements &&
                    excludeElements.some(element =>
                        element?.contains(event.target as Node),
                    )
                ) {
                    return;
                }
                // Do nothing if clicking ref's element or descendent elements
                if (
                    !ref.current ||
                    ref.current.contains(event.target as Node)
                ) {
                    return;
                }
                handler(event);
            };

            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);

            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler],
    );
}
