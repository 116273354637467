import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
    ModalType,
    selectModalState,
    setModal as _setModal,
} from "store/modalsSlice";

export function useModal() {
    const dispatch = useDispatch();
    const modalState = useSelector(selectModalState);

    const setModal = (modal: ModalType, visible: boolean) => {
        dispatch(_setModal({ modal, visible }));
    };
    return {
        modalState,
        setModal,
    };
}
