import { useState } from "react";
import type { ChangeEvent, InputHTMLAttributes } from "react";
import useAutoFocus from "hooks/useAutoFocus";
import { useUITheme } from "hooks/useUITheme";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    placeholder?: string;
    initialValue?: number;
    onValueChange?: (value: number) => void;
    autoFocus?: boolean;
}

export function NumberInput(props: Props) {
    const {
        placeholder = "",
        initialValue = 0,
        onValueChange,
        autoFocus = false,
    } = props;
    const uiTheme = useUITheme(true);
    const [value, setValue] = useState(initialValue);

    const inputCallbackRef = useAutoFocus(autoFocus);

    const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const _value = parseInt(e.target.value, 10);
        setValue(_value);
        if (onValueChange) onValueChange(_value);
    };

    return (
        <input
            ref={inputCallbackRef}
            type="number"
            className="h-[40px] w-full flex items-center self-stretch gap-2 px-[10px] py-3 rounded-lg text-base font-normal border border-uiQuaternary active:border-2 active:border-uiPrimary focus:border-2 focus:border-uiPrimary outline-none duration-200 transition-[border-width,border-color] focus:shadow-[0_0_0_1px_rgba(0,0,0,.4)] focus:shadow-[0_0_0_1px_rgba(0,0,0,.4)]"
            value={value}
            onChange={_onChange}
            placeholder={placeholder}
            style={{
                backgroundColor: uiTheme.quinary,
                color: uiTheme.secondary,
            }}
        />
    );
}
