import {
    HorizontalAlignment,
    Properties,
    Theme,
    VerticalAlignment,
} from "types";

export const removeHttp = (url = "") => {
    return url.replace(/^https?\:\/\//i, "");
};

export function cleanUrl(url: string) {
    if (url.startsWith("mailto:") || url.startsWith("tel:")) {
        return url;
    }
    return `https://${removeHttp(url)}`;
}

export const vertAlign: { [key in VerticalAlignment]: string } = {
    top: "flex-start",
    center: "center",
    bottom: "flex-end",
};

export const horizAlign: {
    [key in HorizontalAlignment]: string;
} = {
    left: "flex-start",
    center: "center",
    right: "flex-end",
};

export const textAlignMap: { [key in HorizontalAlignment]: string } = {
    left: "left",
    center: "center",
    right: "right",
};
