import Button from "components/ui/Button";
import { IconsMap } from "components/ui/Icon";
import UIButton from "components/ui/UIButton";
import { useUITheme } from "hooks/useUITheme";
import { ButtonMode, HydratedBlock, Theme, UITheme } from "types";
// example: https://github.com/atlassian/react-beautiful-dnd/blob/master/stories/src/vertical/quote-app.jsxl

const ListItemPreview = ({
    item,
    theme,
}: {
    item: HydratedBlock;
    theme: UITheme;
}) => {
    if (item.type === "media" && item.properties?.media_list?.[0]?.url) {
        return (
            <div
                className="bg-center bg-cover bg-repeat h-full w-full"
                style={{
                    backgroundImage: `url(${item.properties?.media_list[0]?.url})`,
                }}
            />
        );
    }
    return (
        <p
            className="px-4 text-sm"
            style={{
                color: theme.secondary,
            }}
        >
            {item.type} | {item.id.slice(0, 7)}...
        </p>
    );
};

export const CarouselListItem = ({
    item,
    onEditItem,
    onRemoveItem,
    isDragging,
}: {
    item: HydratedBlock;
    onEditItem: (block: HydratedBlock) => void;
    onRemoveItem: (block: HydratedBlock) => void;
    isDragging: boolean;
}) => {
    const theme = useUITheme(true);
    return (
        <>
            <div
                className="px-4 transition-all duration-200 flex flex-row items-center gap-4 justify-between max-w-xl"
                style={{
                    backgroundColor: theme.background_color,
                }}
            >
                <div className="flex flex-row items-center gap-4 flex-1">
                    <IconsMap.drag size={24} color={theme.primary} />
                    <div
                        className="h-16 max-w-xs  rounded-xl my-4 flex justify-center items-center flex-1 overflow-hidden"
                        style={{
                            backgroundColor: theme.quaternary,
                        }}
                    >
                        <ListItemPreview item={item} theme={theme} />
                    </div>
                </div>
                <div className="flex flex-row gap-4">
                    <UIButton
                        icon="trash"
                        mode={ButtonMode.glass}
                        onClick={() => {
                            onRemoveItem(item);
                        }}
                        allowDarkMode
                    />
                    <UIButton
                        icon="edit"
                        mode={ButtonMode.glass}
                        onClick={() => {
                            onEditItem(item);
                        }}
                        allowDarkMode
                    />
                </div>
            </div>
        </>
    );
};
