import { useCallback, useRef } from "react";

export default function useAutoFocus(shouldAutoFocus: boolean = true) {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const inputCallbackRef = useCallback(
        (inputElement: HTMLInputElement) => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);

            if (inputElement && shouldAutoFocus) {
                // blur current active element before focusing on input
                if (
                    document.activeElement instanceof HTMLElement &&
                    document.activeElement !== document.body
                ) {
                    document.activeElement.blur();
                }
                timeoutRef.current = setTimeout(() => {
                    inputElement.focus();
                }, 0);
            }
        },
        [shouldAutoFocus],
    );

    return inputCallbackRef;
}
