import { useSelector } from "react-redux";

import useEditMode from "hooks/useEditMode";
import { selectActivePageBlock, selectBlockTree } from "store/profileSlice";

import EditTabs from "./EditTabs";
import Tab from "./Tab";

export default function Tabs() {
    const tree = useSelector(selectBlockTree);
    const activePageBlock = useSelector(selectActivePageBlock);
    const { editMode } = useEditMode();

    if (editMode) {
        return <EditTabs />;
    }

    return (
        <div className="flex flex-row gap-4 items-center">
            {tree.map(block => (
                <Tab
                    key={block.id}
                    block={block}
                    activePageBlockId={activePageBlock?.id || ""}
                />
            ))}
        </div>
    );
}
