import Button from "components/ui/Button";
import { Icon, IconsMap } from "components/ui/Icon";
import UIButton from "components/ui/UIButton";
import { useTheme } from "hooks/useTheme";
import { useUITheme } from "hooks/useUITheme";
import { ButtonMode, Link } from "types";

function truncateUrl(url: string) {
    if (url.length > 18) {
        return url.slice(0, 18) + "...";
    }
    return url;
}

type SocialLinkRowProps = {
    link: Link;
    index: number;
    linksLength: number;
    onClickDelete: () => void;
    onClickEditUrl: () => void;
    onShowIconPicker: (show: boolean) => void;
    setSelectedLinkIndex: (index: number) => void;
    provided: any;
};

const SocialLinkRow = (props: SocialLinkRowProps) => {
    const {
        link,
        onClickDelete,
        onClickEditUrl,
        onShowIconPicker,
        setSelectedLinkIndex,
        provided,
    } = props;
    const theme = useUITheme(true);
    const Icon = IconsMap[link.icon as Icon];

    const _onClickIcon = () => {
        onShowIconPicker(true);
        setSelectedLinkIndex(props.index);
    };
    return (
        <div className="flex flex-row items-center flex-1 gap-2 cursor-default">
            <div
                className={`border rounded-xl
            flex flex-row justify-between items-center
            flex-1
            overflow-hidden
            max-w-md
      `}
                style={{
                    backgroundColor: theme.background_color,
                    borderColor: theme.tertiary,
                    cursor: "auto",
                }}
                data-rbd-drag-handle-context-id={
                    provided.dragHandleProps?.[
                        "data-rbd-drag-handle-context-id"
                    ]
                }
            >
                <div
                    className="flex items-center w-12 h-12 justify-center cursor-pointer overflow-auto bg-mauve5 hover:brightness-90 duration-200 transition-all"
                    onClick={_onClickIcon}
                    style={{
                        borderColor: theme.quaternary,
                    }}
                >
                    <Icon size={24} />
                </div>
                <div className="flex-1 flex-col flex ml-1">
                    <div
                        className="flex flex-row items-center cursor-pointer hover:brightness-75 duration-200 transition-all"
                        onClick={onClickEditUrl}
                    >
                        <p
                            style={{
                                color: link.url
                                    ? theme.primary
                                    : theme.secondary,
                            }}
                            className="ml-2 overflow-hidden whitespace-nowrap text-sm"
                        >
                            {truncateUrl(link.url) || "url"}
                        </p>
                    </div>
                </div>
            </div>
            <div
                className="flex flex-row gap-4"
                data-rbd-drag-handle-context-id={
                    provided.dragHandleProps?.[
                        "data-rbd-drag-handle-context-id"
                    ]
                }
                style={{ cursor: "auto" }}
            >
                <UIButton
                    icon="trash_outline"
                    iconSize={24}
                    mode={ButtonMode.ghost}
                    onClick={onClickDelete}
                    padding="p-1"
                    allowDarkMode
                />
            </div>
            <IconsMap.drag
                size={24}
                className="cursor-grab"
                color={theme.primary}
            />
        </div>
    );
};

export default SocialLinkRow;
