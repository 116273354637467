import { createContext, useContext } from "react";

interface ScrollContextValue {
    isScrolling: boolean;
}

export const ScrollContext = createContext<ScrollContextValue>({
    isScrolling: false,
});

export function useScrollContext(): ScrollContextValue {
    return useContext(ScrollContext);
}
