import * as SliderRadix from "@radix-ui/react-slider";

export const Slider = ({
    value,
    onValueChange,
    defaultValue = 100,
}: {
    value: number;
    onValueChange: (value: number) => void;
    defaultValue?: number;
}) => {
    return (
        <form className="w-full">
            <SliderRadix.Root
                className="SliderRoot relative flex items-center w-full"
                defaultValue={[defaultValue]}
                max={100}
                step={1}
                aria-label="Slider"
                value={[value]}
                onValueChange={value => {
                    onValueChange(value[0]);
                }}
            >
                <SliderRadix.Track className="SliderTrack relative rounded-full !h-[8px] bg-uiQuaternary">
                    <SliderRadix.Range className="SliderRange absolute rounded-full !h-[8px] bg-uiPrimary" />
                </SliderRadix.Track>
                <SliderRadix.Thumb
                    className="SliderThumb block cursor-pointer"
                    style={{
                        boxShadow:
                            "0px 4px 24px rgba(0, 0, 0, 0.02), 0px 2px 12px rgba(0, 0, 0, 0.04), 0px 1px 4px rgba(0, 0, 0, 0.08)",
                    }}
                />
            </SliderRadix.Root>
        </form>
    );
};
