import { useCallback, useRef } from "react";
import TSParticles from "react-tsparticles";
import type { Container, Engine } from "tsparticles-engine";
import { loadFull } from "tsparticles";
import { generateConfetti } from "./confetti";

type Props = {
    confetti_image_url?: string;
};
export const Particles = (props: Props) => {
    // TODO: add confetti image
    const { confetti_image_url } = props;
    const containerRef = useRef<Container | null>(null);

    const particlesInit = useCallback(async (engine: Engine) => {
        // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    // const particlesInit = useCallback(async (engine: Engine) => {
    //     // this adds the preset to tsParticles, you can safely use the
    //     await loadFireworksPreset(engine);
    // }, []);

    const particlesLoaded = useCallback(
        async (container: Container | undefined) => {
            containerRef.current = container || null;
        },
        [],
    );

    return (
        <TSParticles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            // @ts-ignore
            options={generateConfetti(confetti_image_url)}
        />
    );
};
