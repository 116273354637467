import React from "react";
import { NAVBAR_HEIGHT } from "utilities/constants";
import { useSelector } from "react-redux";
import { selectSnackpassId } from "store/profileSlice";

const GiftCards = () => {
    const storeId = useSelector(selectSnackpassId);

    // for some reason this doesn't load. perhaps permissioning
    return (
        <div
            style={{
                height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
            }}
        >
            <iframe
                src={`https://order.snackpass.co/giftcard?storeID=${storeId}}`}
                title="Online Ordering"
                width="100%"
                height="100%"
            />
        </div>
    );
};

export default GiftCards;
