import Button from "components/ui/Button";
import useEditMode from "hooks/useEditMode";
import { useModal } from "hooks/useModal";
import { useSelector } from "react-redux";
import { ModalType } from "store/modalsSlice";
import BlockTemplateModal from "./BlockTemplateModal";
import TemplateModal from "./TemplateModal";
import { selectBlockEditor } from "store/blockEditor";
import { useDevice } from "hooks/useDevice";
import { motion } from "framer-motion";

export default function AddBlock() {
    const { editMode } = useEditMode();
    const { setModal } = useModal();
    const editingBlock = useSelector(selectBlockEditor);
    const { isMobileDevice } = useDevice();

    if (!editMode) {
        return null;
    }

    const _onAddBlock = async () => {
        setModal(ModalType.AddBlock, true);
    };

    return (
        <div className="p-8 justify-center items-center flex flex-col gap-8">
            <Button
                icon="block"
                onClick={_onAddBlock}
                title="Add Block"
                isWide
            />
            {editingBlock && isMobileDevice ? (
                <motion.div
                    layout
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </motion.div>
            ) : null}
            {/* Commenting out for now until templates are polished */}

            <TemplateModal />
            <BlockTemplateModal />
        </div>
    );
}
