import { BioStyle, BlockType, HydratedBlock, ParentBlockType } from "types";

/**
 * Used for checking if a block or its parent's type is a parent block type
 * usage: parentBlockTypes.includes(block.parent?.type);
 */
export const parentBlockTypes: BlockType[] = Object.values(ParentBlockType);

export function isParentBlock(block?: HydratedBlock) {
    if (!block) return false;
    return parentBlockTypes.includes(block.type);
}

export function isChildOfParentBlock(block?: HydratedBlock) {
    if (!block) return false;
    if (!block.parent?.type) return false;
    return parentBlockTypes.includes(block.parent?.type);
}

export function isPageBlock(block?: HydratedBlock) {
    if (!block) return false;
    return block.type === BlockType.page;
}

export function getRandomBioStyle(): BioStyle {
    const values = Object.values(BioStyle);
    const randomIndex = Math.floor(Math.random() * values.length);
    return values[randomIndex];
}

export function isChildOfBlockInEdit(
    block?: HydratedBlock,
    blockInEdit?: HydratedBlock | null,
) {
    if (!block || !blockInEdit) return false;
    if (block.id === blockInEdit.id) return false;
    if (!block.parent?.type || blockInEdit.type !== BlockType.carousel)
        return false;
    return blockInEdit.children.includes(block);
}
