import Button from "components/ui/Button";
import { Icon, IconsMap } from "components/ui/Icon";
import { useUITheme } from "hooks/useUITheme";
import { ButtonMode, Link } from "types";
import { linkIconOptions } from "../../properties";
import Select from "components/ui/Select";
import UIButton from "components/ui/UIButton";

function truncateUrl(url: string) {
    if (url.length > 10) {
        return url.slice(0, 15) + "...";
    }
    return url;
}

type LinkRowProps = {
    link: Link;
    index: number;
    linksLength: number;
    onClickDelete: () => void;
    onClickEditName: () => void;
    onClickEditUrl: () => void;
    onShowIconPicker: (show: boolean) => void;
    setSelectedLinkIndex: (index: number) => void;
    provided: any;
};

const LinkRow = (props: LinkRowProps) => {
    const {
        link,
        onClickDelete,
        onClickEditName,
        onClickEditUrl,
        onShowIconPicker,
        setSelectedLinkIndex,
        provided,
    } = props;

    const theme = useUITheme(true);
    const Icon = IconsMap[link.icon as Icon];

    const _onClickIcon = () => {
        onShowIconPicker(true);
        setSelectedLinkIndex(props.index);
    };
    return (
        <>
            <div
                className="flex flex-row items-center flex-1 gap-2 cursor-default"
                style={{
                    backgroundColor: theme.background_color,
                }}
            >
                <div
                    className={`
                    border rounded-lg
                    flex flex-row justify-between items-stretch
                    flex-1
                    max-w-md
                    overflow-hidden
                `}
                    style={{
                        backgroundColor: theme.background_color,
                        borderColor: theme.dark_mode
                            ? theme.primary
                            : theme.quaternary,
                        cursor: "auto",
                    }}
                    data-rbd-drag-handle-context-id={
                        provided.dragHandleProps?.[
                            "data-rbd-drag-handle-context-id"
                        ]
                    }
                >
                    <div
                        className="flex items-center w-12 justify-center cursor-pointer overflow-auto bg-mauve5 hover:brightness-90 duration-200 transition-all"
                        onClick={_onClickIcon}
                        style={{
                            borderColor: theme.quaternary,
                        }}
                    >
                        <Icon size={24} color={theme.background_color} />
                    </div>

                    <div className="flex-1 flex-col flex space-y-2 py-2">
                        <div
                            className="flex flex-row items-center py-1 cursor-pointer hover:brightness-75 duration-200 transition-all"
                            onClick={onClickEditName}
                        >
                            <p
                                className="ml-2 text-sm"
                                style={{
                                    color: link.name
                                        ? theme.primary
                                        : theme.secondary,
                                }}
                            >
                                {truncateUrl(link.name) || "name"}
                            </p>
                        </div>
                        <hr
                            style={{
                                color: theme.secondary,
                            }}
                        />
                        <div
                            className="flex flex-row items-center py-1 cursor-pointer hover:brightness-75 duration-200 transition-all"
                            onClick={onClickEditUrl}
                        >
                            <p
                                style={{
                                    color: link.url
                                        ? theme.primary
                                        : theme.secondary,
                                }}
                                className="ml-2 overflow-hidden whitespace-nowrap text-sm"
                            >
                                {truncateUrl(link.url) || "url"}
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    className="flex flex-row"
                    data-rbd-drag-handle-context-id={
                        provided.dragHandleProps?.[
                            "data-rbd-drag-handle-context-id"
                        ]
                    }
                    style={{ cursor: "auto" }}
                >
                    <UIButton
                        icon="trash_outline"
                        iconSize={24}
                        mode={ButtonMode.ghost}
                        onClick={onClickDelete}
                        padding="p-1"
                        allowDarkMode
                    />
                </div>
                <IconsMap.drag
                    size={24}
                    className="cursor-grab"
                    color={theme.primary}
                />
            </div>
        </>
    );
};

export default LinkRow;
