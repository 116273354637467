import { IconsMap } from "components/ui/Icon";
import { useTheme } from "hooks/useTheme";
import { Media } from "types";
import CloudinaryWidget from "components/profile/CloudinaryWidget";
import useBlockEditor from "hooks/useBlockEditor";
import { uuidv4 } from "@firebase/util";
import { useCallback, useEffect, useState } from "react";
import { core_palette } from "utilities/palette";
import { useUITheme } from "hooks/useUITheme";

type ItemProps = {
    media: Media;
    onRemove: (media: Media) => void;
    onUpload: (error: any, res: any) => void;
    setMediaIndex: (index: number) => void;
    index: number;
};

const Item = ({
    index,
    media,
    onUpload,
    onRemove,
    setMediaIndex,
}: ItemProps) => {
    const theme = useUITheme(true);

    return (
        <div className="relative">
            <CloudinaryWidget
                onUpload={(error, res) => {
                    onUpload(error, res);
                }}
                onClick={() => {
                    setMediaIndex(index);
                }}
            >
                <div
                    className="w-16 h-16 rounded-xl bg-cover bg-center bg-no-repeat border relative overflow-hidden hover:brightness-90 cursor-pointer transition-all duration-200"
                    style={{
                        backgroundImage: `url(${
                            media?.thumbnail_url || media?.url
                        })`,
                        borderColor: theme.quaternary,
                    }}
                >
                    <div className="absolute top-0 left-0 w-full h-full justify-center items-center flex bg-black/25">
                        <IconsMap.upload size={24} color="white" />
                    </div>
                </div>
            </CloudinaryWidget>
            <div className="flex pl-4 absolute right-[-6px] top-[-6px] cursor-pointer hover:brightness-90 transition-all duration-200">
                <div
                    className="bg-slate-200 rounded-full p-1"
                    onClick={() => onRemove(media)}
                >
                    <IconsMap.close
                        size={14}
                        color={core_palette.secondary.light}
                    />
                </div>
            </div>
        </div>
    );
};

type AddItemProps = {
    onUpload: (error: any, res: any) => void;
    setMediaIndex: (index: number) => void;
};

const AddItem = ({ onUpload, setMediaIndex }: AddItemProps) => {
    const theme = useUITheme(true);

    return (
        <CloudinaryWidget
            onUpload={onUpload}
            onClick={() => {
                setMediaIndex(-1);
            }}
        >
            <div
                className="h-16 w-16 rounded-xl border hover:backdrop-brightness-95 cursor-pointer transition-all duration-200 flex flex-col gap-1 justify-center items-center p-2"
                style={{
                    backgroundColor: theme.quaternary,
                    borderColor: theme.quaternary,
                }}
            >
                <IconsMap.plus_outline size={24} color={theme.primary} />
            </div>
        </CloudinaryWidget>
    );
};

type Props = {
    media_list?: Media[];
};

export default function MediaEditor(props: Props) {
    const { media_list = [] } = props;
    const { updateProperty } = useBlockEditor();
    const [newUpload, setNewUpload] = useState<Media | null>(null);
    const [mediaIndex, setMediaIndex] = useState(0);
    const theme = useUITheme(true);

    const _onUpload = useCallback(async (error: any, res: any) => {
        const newMedia: Media = {
            id: uuidv4(),
            url: res.info.secure_url,
            type: res.info.resource_type,
            format: res.info.format,
            active: true,
            thumbnail_url: res.info.thumbnail_url,
        };

        if (res.info.duration) {
            newMedia.duration = res.info.duration;
        }

        try {
            const response = await fetch(
                `/api/v0.1/cloudinary/metadata?resourceId=${res.info.public_id}`,
            );
            const metadata = await response.json();
            newMedia.color = metadata.data.colors[0][0];
        } catch (e) {} // Do not set color if color could not be fetched

        setNewUpload(newMedia);
    }, []);

    // Must do this weird way because if i pass in any function to cloudinary widget,
    // none of the variables update (such as media list)
    // consider moving off of cloudinary widget
    useEffect(() => {
        if (!!!newUpload) {
            return;
        }
        if (mediaIndex === -1) {
            updateProperty("media_list", [...media_list, newUpload]);
        } else {
            updateProperty("media_list", [
                ...media_list.map((m, i) => {
                    if (i === mediaIndex) {
                        return newUpload;
                    }
                    return m;
                }),
            ]);
        }

        setNewUpload(null);
    }, [newUpload, mediaIndex]);

    const _onRemove = (media: Media) => {
        updateProperty(
            "media_list",
            media_list.filter(m => m.id !== media.id),
        );
    };

    return (
        <div className="flex justify-start gap-2">
            {media_list.map((media, index) => {
                return (
                    <Item
                        key={media.id}
                        index={index}
                        media={media}
                        onUpload={_onUpload}
                        setMediaIndex={setMediaIndex}
                        onRemove={_onRemove}
                    />
                );
            })}

            <AddItem setMediaIndex={setMediaIndex} onUpload={_onUpload} />
        </div>
    );
}
