import Button from "components/ui/Button";
import { Icon } from "components/ui/Icon";
import { ButtonMode, HydratedBlock, Link as LinkType } from "types";
import { cleanUrl } from "../lib";
import { useTabPath } from "components/profile/Navbar/hooks/useTabPath";
import { useRouter } from "next/router";

type Props = {
    link: LinkType;
    block: HydratedBlock;
};

export default function LinkTile(props: Props) {
    const { link, block } = props;
    const getTabPath = useTabPath();
    const { properties } = block;
    const isDefaultOrderHereBtn = link.url === "order";
    const buttonMode = properties?.button_mode || ButtonMode.outline;
    const router = useRouter();
    const isReservedApp = router.pathname === "/[username]/reserved-app"; // meaning snack site being web viewed on custom branded app
    const isOOLink = link.url.includes("order.snackpass.co");
    // add space so height stays consistent
    const title = link?.name + " ";

    const onClickHandler = () => {
        // if the link is an order link and the user is on the CBA, open the order tab
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    type: "openOnlineOrdering",
                }),
            );
        }
    };

    return (
        <Button
            title={title}
            icon={link.icon as Icon}
            mode={buttonMode}
            newTab={!isDefaultOrderHereBtn}
            animateScale
            href={
                isOOLink && isReservedApp
                    ? undefined
                    : isDefaultOrderHereBtn
                    ? getTabPath(link?.url)
                    : cleanUrl(link?.url)
            }
            onClick={isOOLink && isReservedApp ? onClickHandler : undefined}
            htmlComponent={isOOLink && isReservedApp ? undefined : "a"}
            className="w-full"
        />
    );
}
