import { useEffect, useState } from "react";
import moment from "moment";
import { useTheme } from "hooks/useTheme";

interface Props {
    deadline: string;
}

const CountdownTimer = ({ deadline }: Props) => {
    const [displayTime, setDisplayTime] = useState("");

    useEffect(() => {
        const updateTime = setInterval(() => {
            const duration = moment.duration(moment(deadline).diff(moment()));
            if (duration.asMilliseconds() <= 0) {
                setDisplayTime("expired!");
            } else {
                const days = duration.days() > 0 ? `${duration.days()}d` : "";
                const hours =
                    duration.hours() > 0 ? `${duration.hours()}h` : "";
                const minutes =
                    duration.minutes() > 0 ? `${duration.minutes()}m` : "";
                const seconds =
                    duration.seconds() > 0 ? `${duration.seconds()}s` : "";
                const displayTime = [days, hours, minutes, seconds].join(" ");
                setDisplayTime(displayTime);
            }
        });

        return () => clearInterval(updateTime);
    }, [deadline]);

    return (
        <div className="inline-block p-2 rounded-lg text-center theme-tertiary-background theme-primary-text w-[8em]">
            {displayTime || "Expired!"}
        </div>
    );
};

export default CountdownTimer;
