import { Icon } from "components/ui/Icon";
import Select from "components/ui/Select";
import { formIconOptions } from "../properties";

interface Props {
    icon?: Icon;
    onIconChange: (icon: Icon) => void;
}

const IconInput = ({ icon, onIconChange }: Props) => {
    return (
        <Select
            options={formIconOptions}
            onChangeValue={value => onIconChange(value as Icon)}
            value={icon || "no_icon"}
        />
    );
};

export default IconInput;
