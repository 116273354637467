import { clsx } from "clsx";
import { IconsMap } from "components/ui/Icon";
import { useTheme } from "hooks/useTheme";
import { autoQualityImage } from "utilities/cloudinaryUtils";
import StoreLogo from "./StoreLogo";
import { BioData } from "./types";

type Props = {
    hideCover: boolean;
    hideBio: boolean;
    hideLogo: boolean;
    hideName: boolean;
    mobileView: boolean;
    data: BioData;
};

export default function StyleOne({
    hideCover,
    hideBio,
    hideLogo,
    hideName,
    mobileView,
    data,
}: Props) {
    const theme = useTheme();

    const coverStyle = hideCover
        ? {}
        : {
              backgroundImage: `url(${autoQualityImage(data.cover_url)})`,
              borderRadius: theme.block_radius,
          };

    return (
        <div
            className={clsx("flex-1 text-center justify-center flex flex-col", {
                " pb-4": !hideCover,
                "pt-2 pb-6": hideCover && !hideBio,
            })}
        >
            <div
                className={clsx({
                    "w-full bg-cover bg-center relative": !hideCover,
                    "h-[360px]": !hideCover && !mobileView,
                    "h-[180px]": !hideCover && mobileView,
                    "flex mx-2": hideCover,
                })}
                style={coverStyle}
            >
                {!data.cover_url && !hideCover && (
                    <div
                        className={clsx(
                            "flex justify-center items-center w-full theme-tertiary-background",
                            {
                                "h-[360px]": !hideCover && !mobileView,
                                "h-[180px]": !hideCover && mobileView,
                            },
                        )}
                        style={{
                            borderRadius: theme.block_radius,
                        }}
                    >
                        <IconsMap.image
                            className="theme-secondary-text"
                            size={48}
                        />
                    </div>
                )}
                {!hideLogo && (
                    <div
                        className={clsx({
                            "absolute bottom-0 left-0 right-0 translate-y-[50%]":
                                !hideCover,
                            "flex-1": hideCover,
                        })}
                    >
                        <StoreLogo
                            imageUrl={data.profile_url}
                            borderRadius={theme.button_radius}
                        />
                    </div>
                )}
            </div>
            <div
                className={clsx("flex justify-center flex-col px-4", {
                    "mt-14": !hideCover && !hideLogo && mobileView,
                    "mt-20": !hideCover && !hideLogo && !mobileView,
                    "mt-6": !hideCover && hideLogo,
                })}
            >
                {!hideName && (
                    <p className="text-2xl font-bold my-2 theme-primary-text">
                        {data.name}
                    </p>
                )}
                {!hideBio && (
                    <p className="text-sm my-2 px-4 theme-secondary-text">
                        {data.description}
                    </p>
                )}
            </div>
        </div>
    );
}
