import { constructCloudinaryUrl } from "next-cloudinary";

import { HydratedBlock, Media, ObjectFit } from "types";

export const Video = ({
    block,
    media,
}: {
    block: HydratedBlock;
    media: Media;
}) => {
    const videoSrc = constructCloudinaryUrl({
        options: {
            src: media.url,
            width: 960, // dynamic width would be ideal but it's not supported
            quality: "80",
        },
    }).replace("/image/upload/", "/video/upload/"); // constructCloudinaryUrl won't build a url to a video so build to image and swap string
    /* muted must be after autoplay to work! */
    return (
        <video
            className={`h-full w-full`}
            style={{
                filter: `brightness(${block.properties?.brightness}%)`,
                WebkitFilter: `brightness(${block.properties?.brightness}%)`,
                borderRadius: "inherit",
                objectFit: (block.properties?.object_fit || ObjectFit.cover) as
                    | "cover"
                    | "contain"
                    | "fill"
                    | "none"
                    | "scale-down",
                backgroundColor: block.properties?.media_background_color
                    ? block.properties?.media_background_color
                    : media.color,
            }}
            src={videoSrc}
            autoPlay
            loop
            muted
            playsInline
        />
    );
};
