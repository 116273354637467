import { useTheme } from "hooks/useTheme";
import { clsx } from "clsx";
import useEditMode from "hooks/useEditMode";
import { useDevice } from "hooks/useDevice";
import { IconsMap } from "components/ui/Icon";
import { BackgroundType } from "types";
import { core_palette } from "utilities/palette";

type Props = {
    dimension?: number;
    borderRadius?: number;
    imageUrl: string;
    small?: boolean;
    rounded?: boolean;
};

export default function StoreLogo({
    dimension,
    borderRadius,
    imageUrl,
    small,
    rounded,
}: Props) {
    const theme = useTheme();
    const { mobilePreviewMode } = useEditMode();
    const { isMobileDevice } = useDevice();
    const mobileView = mobilePreviewMode || isMobileDevice;

    if (!imageUrl) {
        return (
            <div className="flex justify-center">
                <div
                    className={clsx("flex border-2 theme-tertiary-background", {
                        "rounded-full": rounded,
                        "w-[96px] h-[96px]": mobileView,
                        "w-[144px] h-[144px]": !mobileView,
                    })}
                    style={{
                        borderColor: theme.background_color,
                        width: dimension,
                        height: dimension,
                        background:
                            theme.background_type !== BackgroundType.minimal
                                ? core_palette.secondary.light
                                : theme.tertiary,
                        borderRadius: `${borderRadius}px`,
                    }}
                >
                    <div className="m-auto">
                        <IconsMap.image
                            className="theme-secondary-text"
                            size={small ? 24 : 48}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex justify-center">
            <div
                className={clsx("bg-cover bg-center bg-no-repeat border-2", {
                    "rounded-full": rounded,
                    "w-[96px] h-[96px]": mobileView,
                    "w-[144px] h-[144px]": !mobileView,
                })}
                style={{
                    backgroundImage: `url(${imageUrl})`,
                    borderColor: theme.background_color,
                    width: dimension,
                    height: dimension,
                    borderRadius: `${borderRadius}px`,
                }}
            />
        </div>
    );
}
