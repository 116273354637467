import { IconsMap } from "components/ui/Icon";
import { useUITheme } from "hooks/useUITheme";
import { Question } from "types";
import BlockEditorDialog from "../../BlockEditorDialog";
import TextEditor from "../TextEditor";

interface Props {
    show: boolean;
    onHide: () => void;
    question?: Question;
    onChangeQuestion: (question: string) => void;
    onChangeAnswer: (answer: string) => void;
}

const QuestionEditor = ({
    show,
    onHide,
    question,
    onChangeQuestion,
    onChangeAnswer,
}: Props) => {
    const theme = useUITheme();

    const handleChangeQuestion = () => {
        const newQuestion = window.prompt("Question: ", question?.question);
        if (newQuestion !== null) {
            onChangeQuestion(newQuestion);
        }
    };

    if (!question) {
        return null;
    }

    return (
        <BlockEditorDialog show={show} onHide={onHide}>
            <div className="w-full px-8 pt-4 flex flex-col justify-center">
                <div className="flex flex-row items-center mb-6 gap-4">
                    <p className="text-sm">Question:</p>
                    <div
                        onClick={handleChangeQuestion}
                        className={`
            text-right overflow-hidden rounded-lg p-2 border 
            flex flex-row items-center justify-between flex-1 w-1/2
            cursor-pointer hover:brightness-95 duration-200 border-[${theme.border_color}] bg-[${theme.background_color}] h-10
            `}
                    >
                        <p className="text-sm overflow-hidden whitespace-nowrap flex-1 text-left w-full">
                            {question.question}
                        </p>
                        <IconsMap.chevron_right color={theme.secondary} />
                    </div>
                </div>
                <TextEditor
                    value={question.answer}
                    onValueChange={onChangeAnswer}
                    className="max-w-full"
                />
            </div>
        </BlockEditorDialog>
    );
};

export default QuestionEditor;
