import { ButtonMode, MediaBlock, Properties } from "types";
import { core_palette } from "utilities/palette";
import { cleanUrl } from "components/profile/Block/lib";
import Button from "components/ui/Button";

type Props = {
    properties: Properties & MediaBlock;
};

export function MediumCaption(props: Props) {
    const { properties } = props;

    // TODO: if image is bright we want black and if dark we want white for contrast
    const darkCaption = false;
    const mode = darkCaption ? "dark" : "light";
    const primary = core_palette.primary[mode];
    const backgroundColor = darkCaption
        ? "rgba(0,0,0,.8)"
        : "rgba(255,255,255,.8)";
    const borderColor = core_palette.quaternary[mode];

    return (
        <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-end p-4">
            <div
                className={`flex flex-row justify-around items-center transform-gpu backdrop-blur-md rounded-lg max-w-md overflow-hidden z-20`}
                style={{ backgroundColor }}
            >
                {properties.caption_title_text ? (
                    <div className="p-1.5 px-4">
                        <p
                            className="font-medium text-left text-sm"
                            style={{
                                color: primary,
                            }}
                        >
                            {properties.caption_title_text}
                        </p>
                    </div>
                ) : null}
                {properties.button_text ? (
                    <div
                        className="py-1.5 px-4 hover:bg-gray-900/10 duration-200 transition-colors h-full border-l flex flex-row items-center justify-center cursor-pointer"
                        style={{
                            borderColor: properties.caption_title_text
                                ? borderColor
                                : "transparent",
                        }}
                    >
                        <Button
                            mode={ButtonMode.solid}
                            title={properties.button_text}
                            textClassName="text-center whitespace-nowrap"
                            color={primary}
                            className="py-0 px-0 w-full h-full"
                            style={{
                                backgroundColor: "transparent",
                                color: primary,
                            }}
                            href={cleanUrl(properties.button_url || "")}
                            htmlComponent="a"
                            newTab
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
}
