type ReducerState = {
    fileList: Blob[];
    inDropZone: boolean;
};

export enum ReducerActionType {
    addToDropZone = "AddToDropZone",
    addToFileList = "AddToFileList",
    clearFileList = "ClearFileList",
}

export type ReducerAction =
    | { type: ReducerActionType.addToDropZone; inDropZone: boolean }
    | { type: ReducerActionType.addToFileList; files: Blob[] }
    | { type: ReducerActionType.clearFileList };

const reducer = (state: ReducerState, action: ReducerAction): ReducerState => {
    switch (action.type) {
        case ReducerActionType.addToDropZone:
            return { ...state, inDropZone: action.inDropZone };
        case ReducerActionType.addToFileList:
            return {
                ...state,
                fileList: state.fileList.concat(action.files),
            };
        case ReducerActionType.clearFileList:
            return { ...state, fileList: [] };
        default:
            return state;
    }
};

export default reducer;
