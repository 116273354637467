import { useUITheme } from "hooks/useUITheme";
import ModalDetachedBox from "components/ui/ModalDetachedBox";
import UIButton from "components/ui/UIButton";

// dialog that pops up over block editor

type Props = {
    show: boolean;
    onHide: () => void;
    children: React.ReactNode;
};

export default function BlockEditorDialog(props: Props) {
    const { show, onHide, children } = props;
    const theme = useUITheme();

    return (
        <ModalDetachedBox isOpen={show}>
            <div
                className="flex flex-col items-center justify-center rounded-lg py-4 max-w-2xl w-full mx-auto"
                style={{
                    backgroundColor: theme.background_color,
                }}
            >
                {children}
                <div className="mt-8">
                    <UIButton title="Done" className="w-32" onClick={onHide} />
                </div>
            </div>
        </ModalDetachedBox>
    );
}
