import React from "react";
import { useSelector } from "react-redux";
import { selectSnackpassStore } from "store/profileSlice";

export const AddressBlock = () => {
    const snackpassStore = useSelector(selectSnackpassStore);
    const geolocation = snackpassStore?.geolocation.coordinates;
    const googleMapsApiKey = "AIzaSyD2DcMDY4tjcwYOqZeltGTz_u4xPJnfxN4";
    const location = geolocation ? `${geolocation[1]},${geolocation[0]}` : "";

    const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(
        location,
    )}&zoom=14&size=600x200&maptype=roadmap&markers=color:red%7C${encodeURIComponent(
        location,
    )}&key=${googleMapsApiKey}`;

    const fullAddress = snackpassStore?.address || "";
    const [streetName, cityStateZip] = fullAddress.split(", ");

    return (
        <div className="max-w-4xl mx-auto flex flex-row py-8 items-center justify-center w-full theme-tertiary-background">
            <div className="flex flex-col items-start w-full p-4">
                <div
                    className="w-full h-80 rounded-t-xl bg-cover bg-center"
                    style={{ backgroundImage: `url(${mapUrl})` }}
                />
                <br />
                <div>
                    <p>{streetName}</p>
                    <p className="text-sm text-gray-500">{cityStateZip}</p>
                </div>
            </div>
        </div>
    );
};
