import Button from "components/ui/Button";
import useBlockEditor from "hooks/useBlockEditor";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
    selectBlockEditor,
    setBlockEditor,
    setFromCarousel,
} from "store/blockEditor";
import { selectProfileId } from "store/profileSlice";
import { BlockType, ButtonMode, HydratedBlock } from "types";
import { CarouselListItem } from "./CarouselListItem";
import { useUITheme } from "hooks/useUITheme";
import UIButton from "components/ui/UIButton";
// example: https://github.com/atlassian/react-beautiful-dnd/blob/master/stories/src/vertical/quote-app.jsxl

type Props = {
    childrenBlocks?: HydratedBlock[];
};
export function DragAndDropList(props: Props) {
    const { childrenBlocks = [] } = props;
    const uiTheme = useUITheme(true);
    const { updateProperty, refetchBlockFromServer } = useBlockEditor();
    const block = useSelector(selectBlockEditor);
    const pid = useSelector(selectProfileId);
    const dispatch = useDispatch();
    const carouselBlockId = block?.id || "";

    function onDragEnd(result: any) {
        const newChildren = [...childrenBlocks];
        const [removed] = newChildren.splice(result.source.index, 1);
        newChildren.splice(result.destination.index, 0, removed);
        updateProperty("children", newChildren);
    }

    function onDragStart() {
        if (window.navigator.vibrate) {
            window.navigator.vibrate(100);
        }
    }

    const _onEditItem = (child: HydratedBlock) => {
        dispatch(setBlockEditor(child));
        dispatch(setFromCarousel(carouselBlockId));
    };

    const _onRemoveItem = async (child: HydratedBlock) => {
        const res = window.confirm(
            `Are you sure you want to delete this carousel block?`,
        );
        if (!res) return;
        await fetch(`/api/v0.1/profiles/${pid}/blocks/${child.id}`, {
            method: "DELETE",
        });

        refetchBlockFromServer(carouselBlockId);
    };

    const _addBlock = async () => {
        if (block?.type !== BlockType.carousel) {
            alert("Save carousel first!");
            return;
        }

        await fetch(`/api/v0.1/profiles/${pid}/blocks`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                parent: carouselBlockId,
                type: BlockType.media,
            }),
        });
        refetchBlockFromServer(carouselBlockId);
    };

    return (
        <>
            <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                <Droppable droppableId="carousel">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="transition-all duration-200"
                        >
                            <span style={{ display: "none" }}>
                                {provided.placeholder}
                            </span>
                            {childrenBlocks.map((item, index) => (
                                <Draggable
                                    draggableId={item?.id}
                                    index={index}
                                    key={item.id}
                                >
                                    {(provided: any, snapshot: any) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <CarouselListItem
                                                item={item}
                                                onEditItem={_onEditItem}
                                                onRemoveItem={_onRemoveItem}
                                                isDragging={snapshot.isDragging}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            <div
                                className="p-4 justify-center flex flex-row"
                                style={{
                                    opacity: snapshot.isDraggingOver ? 0 : 1,
                                }}
                            >
                                <UIButton
                                    iconSize={24}
                                    mode={ButtonMode.solid}
                                    icon="block"
                                    onClick={_addBlock}
                                    title="Add Block To Carousel"
                                    isWide
                                    allowDarkMode
                                />
                            </div>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
}
