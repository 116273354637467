import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectSnackpassStore } from "store/profileSlice";
import { isStoreOpen } from "@snackpass/time";
import { DateTime } from "luxon";
import { HourRange, formatLegacySpecialHours, getHourRanges } from "./utils";

export const HoursBlock = () => {
    const snackpassStore = useSelector(selectSnackpassStore);
    const hours = snackpassStore?.hours;
    const [currentDay, setCurrentDay] = useState<string>(
        DateTime.local().toFormat("ccc"),
    ); // Get the day of the week. 1 is Monday and 7 is Sunday
    const [isOpen, setIsOpen] = useState(false);
    const allHourRanges = useMemo(
        () => (hours ? getHourRanges(hours) : []),
        [hours],
    );
    const todayHours: HourRange[] = [];
    const otherDayHours: HourRange[] = [];

    allHourRanges.forEach(range => {
        if (range.day === currentDay) {
            todayHours.push(range);
        } else {
            otherDayHours.push(range);
        }
    });

    useEffect(() => {
        const checkIsOpen = () => {
            if (!hours) {
                return;
            }
            const isOpen = isStoreOpen(formatLegacySpecialHours(hours))();
            setIsOpen(isOpen);
        };

        checkIsOpen();
        const timer = setInterval(() => {
            setCurrentDay(DateTime.local().toFormat("ccc"));
            checkIsOpen();
        }, 60 * 1000);

        return () => {
            clearInterval(timer);
        };
    }, [hours]);

    return (
        <div className="mb-4 flex justify-center flex-col items-center">
            <div>
                <p>
                    <span
                        className={`${
                            isOpen ? "text-green-600" : "text-red-600"
                        }`}
                    >
                        {isOpen ? "Open" : "Closed"}
                    </span>
                    <br />
                    {todayHours.map((range, i) => {
                        return (
                            <span key={i}>
                                {range.day}: {range.time}{" "}
                                <span className="text-sm">
                                    {range.isSpecial ? "(Special Hours)" : null}
                                </span>
                            </span>
                        );
                    })}
                </p>
                <details className="cursor-pointer">
                    <summary>All Hours</summary>
                    {otherDayHours.map((range, index) => {
                        return (
                            <p key={index}>
                                {range.day}: {range.time}
                                <span className="text-sm">
                                    {range.isSpecial ? "(Special Hours)" : null}
                                </span>
                            </p>
                        );
                    })}
                </details>
            </div>
        </div>
    );
};
