import { useState, useEffect } from "react";
import { IconsMap } from "components/ui/Icon";
import useEditMode from "hooks/useEditMode";
import Dialog from "components/ui/Dialog";
import { useTabActions } from "../hooks/useTabActions";

export default function AddPageButton() {
    const { editMode } = useEditMode();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pageName, setPageName] = useState("");
    const [pageUrl, setPageUrl] = useState("");
    const { onCreate } = useTabActions();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        setPageUrl(`${pageName.toLowerCase().replace(/[^a-z0-9]/g, "-")}`);
    }, [pageName]);

    const _onSubmit = async () => {
        setLoading(true);
        try {
            await onCreate({ pageName, pageUrl });
            setIsModalOpen(false);
        } catch (error) {
            if (error instanceof Error) {
                setErrorMessage(error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    if (!editMode) {
        return null;
    }

    return (
        <>
            <div
                className="flex flex-row items-center"
                onClick={() => {
                    setIsModalOpen(true);
                }}
            >
                <div
                    className={`cursor-pointer transition-all duration-200 p-2 rounded-full hover:backdrop-brightness-110`}
                >
                    <IconsMap.plus className="theme-secondary-text" />
                </div>
            </div>
            <Dialog
                isOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                title="Page Name"
                placeholder="Page name"
                onSubmit={_onSubmit}
                loading={loading}
                value={pageName}
                onValueChange={(value: string) => setPageName(value)}
                error={errorMessage}
                customComponent={
                    pageUrl ? (
                        <>
                            Your page url will be <strong>{pageUrl}</strong>
                        </>
                    ) : null
                }
            />
        </>
    );
}
