import { clsx } from "clsx";

import {
    ButtonMode,
    HorizontalAlignment,
    MediaBlock,
    Properties,
    VerticalAlignment,
} from "types";

import useBreakpoints from "hooks/useBreakpoints";

import { core_palette } from "utilities/palette";

import {
    cleanUrl,
    horizAlign,
    textAlignMap,
    vertAlign,
} from "components/profile/Block/lib";
import Button from "components/ui/Button";
import classNames from "classnames";
import { DEFAULT_BG_IMAGE } from "utilities/theme";

type Props = {
    properties: Properties & MediaBlock;
};

export function LargeCaption(props: Props) {
    const { isMobileView } = useBreakpoints();

    const { properties } = props;
    const {
        alignment_horizontal = HorizontalAlignment.left,
        alignment_vertical = VerticalAlignment.top,
    } = properties;

    const isOutOfBoxMediaBlock =
        properties.media_list?.length === 1 &&
        properties.media_list[0].url === DEFAULT_BG_IMAGE;
    return (
        <div
            className={classNames(
                "absolute top-0 left-0 w-full h-full flex-col items-end justify-end",
                {
                    "p-16": !isMobileView,
                    "p-8": isMobileView,
                },
            )}
            style={{
                display: "flex",
                // INFO: Default alignment to top left if no selection.
                alignItems: horizAlign[alignment_horizontal],
                justifyContent: vertAlign[alignment_vertical],
                textAlign: textAlignMap[
                    alignment_horizontal
                ] as HorizontalAlignment,
                flexDirection: "column",
            }}
        >
            <p
                className={clsx("font-extrabold mb-4", {
                    "text-white": !isOutOfBoxMediaBlock,
                    "text-black": isOutOfBoxMediaBlock,
                    "text-5xl": isMobileView,
                    "text-6xl": !isMobileView,
                })}
                style={{
                    wordBreak: "break-word",
                    textShadow:
                        "3px 2px 18px #1b0a0a, 2px 9px 3px rgba(0,0,0,0.15), -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000",
                }}
            >
                {properties.caption_title_text}
            </p>
            {properties.caption_subtitle_text && (
                <p className="text-white max-w-4xl">
                    {properties.caption_subtitle_text}
                </p>
            )}
            {properties.button_text ? (
                // z-20 z-index 20 is added on all buttons on caption layer to allow clicking while having left and right click overlay in place
                <div className="z-20">
                    <br />
                    <Button
                        mode={ButtonMode.solid}
                        color={core_palette.primary.light}
                        backgroundColor={core_palette.primary.dark}
                        title={properties.button_text}
                        textClassName="text-center"
                        style={{
                            minWidth: 56,
                        }}
                        hasShadow
                        href={cleanUrl(properties.button_url || "")}
                        htmlComponent="a"
                        newTab
                    />
                </div>
            ) : null}
        </div>
    );
}
