// This is a pattern that we will use often in this project
// if it's desktop, show the DetachedBox
// if it's mobile, show the Modal

import useBreakpoints from "hooks/useBreakpoints";
import DetachedBox from "./DetachedBox";
import { Icon } from "./Icon";
import Modal from "./Modal";
import { useDevice } from "hooks/useDevice";

type Props = {
    isOpen: boolean;
    setIsOpen?: (isOpen: boolean) => void;
    children: React.ReactNode;
    title?: string;
    onClickLeft?: () => void;
    onClickRight?: () => void;
    leftTitle?: string;
    rightTitle?: string;
    leftIcon?: Icon;
    rightIcon?: Icon;
    description?: string;
};
export default function ModalDetachedBox(props: Props) {
    const { isDesktopDevice } = useDevice();

    if (isDesktopDevice) {
        return <DetachedBox {...props} />;
    }

    return <Modal {...props} />;
}
