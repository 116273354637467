import { useCallback, useRef, useState } from "react";
import type { SheetRef } from "react-modal-sheet";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import useBlockEditor from "hooks/useBlockEditor";
import { ModalType, selectModalState, setModal } from "store/modalsSlice";
import {
    clearBlockEditor,
    selectActivePropertyTab,
    selectBlockEditor,
    selectBlockUpdates,
    selectFromCarousel,
    setActivePropertyTab,
} from "store/blockEditor";

import DesktopBlockEditor from "./DesktopBlockEditor";
import { selectSettingsUpdates } from "store/settingsEditor";
import { HeaderText } from "./properties";

const FULLY_CLOSED_INDEX = 1;

export default function BlockEditor() {
    const sheetRef = useRef<SheetRef>(null);
    const snapTo = (i: number) => sheetRef.current?.snapTo(i);
    const dispatch = useDispatch();
    const [fetching, setFetching] = useState(false);
    const { save, returnToCarousel } = useBlockEditor();
    const blockEditorTimer = useRef<NodeJS.Timeout | null>(null);
    const modalState = useSelector(selectModalState);
    const activePropertyTab = useSelector(selectActivePropertyTab);
    const block = useSelector(selectBlockEditor);
    const updates = useSelector(selectBlockUpdates);
    const settingsUpdates = useSelector(selectSettingsUpdates);
    const fromCarousel = useSelector(selectFromCarousel);
    const headerText = activePropertyTab
        ? activePropertyTab
        : fromCarousel
        ? HeaderText.Headers.EditCarouselItem
        : HeaderText.Headers.EditBlock;

    const handleDismiss = useCallback(() => {
        if (blockEditorTimer.current) {
            clearTimeout(blockEditorTimer.current);
        }

        blockEditorTimer.current = setTimeout(() => {
            dispatch(
                setModal({ modal: ModalType.BlockEditor, visible: false }),
            );
            dispatch(clearBlockEditor());
        }, 300);
    }, [dispatch]);

    const handleGetBack = useCallback(async () => {
        if (activePropertyTab && !fromCarousel) {
            // close the property row and go back to the property tabs
            dispatch(setActivePropertyTab(undefined));
            return;
        }
        if (fromCarousel) {
            if (updates.length > 0 || settingsUpdates.length > 0) {
                await save();
            }
            // save the carousel changes and go back to carousel items list
            returnToCarousel();
        }
    }, [
        activePropertyTab,
        dispatch,
        fromCarousel,
        returnToCarousel,
        save,
        settingsUpdates.length,
        updates.length,
    ]);

    const handleClose = useCallback(async () => {
        // handleClose will be called when user click close btn, click outside or drag down to dismiss bottom sheet
        if (updates.length > 0 || settingsUpdates.length > 0) {
            if (
                window.confirm(
                    "The block has been modified. Do you want to save the changes?",
                )
            ) {
                await save();
                toast.success(`Changes saved`);
            }
        }

        snapTo(FULLY_CLOSED_INDEX);
        handleDismiss();
    }, [handleDismiss, save, settingsUpdates.length, updates.length]);

    const handleSave = useCallback(async () => {
        setFetching(true);
        // handleSave will be called only when user click save btn
        try {
            if (updates.length > 0 || settingsUpdates.length > 0) {
                await save();
                toast.success(`Changes saved`);
            }
        } catch (err: any) {
            toast.error(
                `${err.statusText}: Unable to save the changes - ${err.message}. Please try again.`,
            );
        } finally {
            snapTo(FULLY_CLOSED_INDEX);
            handleDismiss();
            setFetching(false);
        }
    }, [handleDismiss, save, settingsUpdates.length, updates.length]);

    const isOpen = modalState.blockEditor;

    if (!block) {
        return null;
    }

    return (
        <>
            {/* // needed for cloudinary upload widget to work */}
            <style jsx global>
                {`
                    body {
                        pointer-events: auto !important;
                    }
                `}
            </style>

            <DesktopBlockEditor
                headerText={headerText}
                isOpen={isOpen}
                handleGetBack={handleGetBack}
                handleClose={handleClose}
                handleSave={handleSave}
                fetching={fetching}
            />
        </>
    );
}
