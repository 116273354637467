type SliderMetadataProps = {
    activeIndex: number;
    length: number;
};

type MoreSlidesProps = SliderMetadataProps & {
    currentIndex: number;
};

const MAX_NUMBER_OF_DOTS = 7;

/**
 * Determines if there are more than the MAX_NUMBER_OF_DOTS in order to signal that to the user
 * in this case it shows either the last or first dot as smaller than the rest to let
 * the user know there are more slides in either direction
 */
export const hasMoreSlides = ({
    currentIndex,
    activeIndex,
    length,
}: MoreSlidesProps) => {
    // Make pagination dot smaller if it is at extermities and there are more images to show after, or before
    let moreSlides = false;

    // Case where activeIndex is smaller than 4
    if (
        currentIndex === MAX_NUMBER_OF_DOTS - 1 &&
        activeIndex < 4 &&
        length > MAX_NUMBER_OF_DOTS
    ) {
        moreSlides = true;
    } else if (
        currentIndex === length - MAX_NUMBER_OF_DOTS &&
        activeIndex > length - 5 &&
        length > MAX_NUMBER_OF_DOTS
    ) {
        // Case where activeIndex is larger than the number of slides - 1
        moreSlides = true;
    } else if (
        /**
         * Check for case where there are more than MAX_NUMBER_OF_DOTS slides, and the current and active
         * indices are within the range to show more slides before or after the current one.
         *
         * Given that MAX_NUMBER_OF_DOTS is 7 and length/number of slides is 13, "x" and "y" siginify points addressed
         * by the conditional below, where one "x" is activeIndex and "y" is currentIndex, and x1 and y1 together
         * represent one condition.
         *
         * x1 = index 3
         * y1 = index 6
         * x2 = index 7
         * y2 = index 4
         *
         *           activeIndex window (indices 3 to 7)
         *                       |-----------|
         *                 o o o x1 y2 o y1 x2 o o o o o
         *                          |-----|
         *           currentIndex window (indices 4 to 6)
         */
        (currentIndex > 4 || currentIndex < length - 5) &&
        activeIndex > 3 &&
        activeIndex < length - 4 &&
        (activeIndex >= currentIndex + 3 || activeIndex <= currentIndex - 3)
    ) {
        moreSlides = true;
    }

    return moreSlides;
};

/**
 * Sets the carousel slider's index to fixedIndex and calculates sliderPadding so that the
 * activeIndex generally stays in the center while swiping/clicking through slides
 */
export const getSliderMetadata = ({
    activeIndex,
    length,
}: SliderMetadataProps) => {
    const numberOfDots =
        length > MAX_NUMBER_OF_DOTS ? MAX_NUMBER_OF_DOTS : length;
    let sliderPadding = (numberOfDots - 1) / 2;

    let fixedIndex = activeIndex;
    if (activeIndex < 3) {
        fixedIndex = 3;
    } else if (activeIndex > length - 4) {
        fixedIndex = length - 4;
    }

    if (length < MAX_NUMBER_OF_DOTS + 1) {
        fixedIndex = 0;
        sliderPadding = (MAX_NUMBER_OF_DOTS - length) / 2;
    }

    return { fixedIndex, sliderPadding };
};
