import { clsx } from "clsx";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
// @ts-ignore this fixes react-swipeable-views react-18 error but doesn't have proper typing
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { virtualize, autoPlay } from "react-swipeable-views-utils";

import { useScrollContext } from "hooks/ScrollContext";
import { getBorderRadius } from "hooks/useBlockStyle";
import useBreakpoints from "hooks/useBreakpoints";
import { useTheme } from "hooks/useTheme";
import { selectBlockEditor } from "store/blockEditor";
import { HydratedBlock } from "types";
import {
    CAROUSEL_CONTROLS_HEIGHT,
    DEFAULT_IN_VIEW_OPTIONS,
} from "utilities/constants";

import Block from "../Block";
import Arrow from "./Arrow";
import Dots from "./Dots";
import { useDevice } from "hooks/useDevice";

const EnhancedSwipeableViews = autoPlay(virtualize(SwipeableViews));

type Props = {
    block: HydratedBlock;
};

const carouselSpeedMap = {
    slow: 5500,
    medium: 4500,
    fast: 4000,
};

export default function Carousel(props: Props) {
    const { block } = props;
    const [index, setIndex] = useState(0);
    const [transitionReason, setTransitionReason] = useState<
        "swipe" | "focus" | undefined
    >();
    const editingBlock = useSelector(selectBlockEditor);

    const { ref: carouselRef, inView } = useInView(DEFAULT_IN_VIEW_OPTIONS);
    const { isScrolling } = useScrollContext();
    const { isMobileView } = useBreakpoints();
    const { isDesktopDevice } = useDevice();
    const theme = useTheme();

    const children = block.children || [];
    const isEditing = editingBlock?.id === block.id;
    const shouldAutoplay = !isEditing && !!block.properties?.autoplay;
    const carouselSpeedMapIndex = block.properties?.autoplay_speed ?? "medium";
    const autoplaySpeed = carouselSpeedMap[carouselSpeedMapIndex];
    const borderRadius = getBorderRadius(theme, block, isMobileView);

    useEffect(() => {
        if (editingBlock && block.children) {
            const editingIndex = block.children.findIndex(
                block => block.id === editingBlock?.id,
            );
            if (editingIndex !== -1) {
                setIndex(editingIndex);
            }
        }
    }, [block.children, editingBlock]);

    const handlePrevPress = () => {
        setTransitionReason("swipe");
        setIndex(i => {
            const prevIndex = i - 1;
            const lastIndex = children.length - 1;
            return i > 0 ? prevIndex : lastIndex;
        });
    };

    const handleNextPress = () => {
        setTransitionReason("swipe");
        setIndex(i => {
            const nextIndex = i + 1;
            const lastIndex = children.length - 1;
            return lastIndex > i ? nextIndex : 0;
        });
    };

    // override react-swipeable-views default border-radius styling
    const slideContainerStyle = useMemo(
        () => ({
            borderRadius,
            transform: "translate3d(0,0,0)",
            WebkitTransform: "translate3d(0,0,0)",
            display: "flex",
            flexGrow: 1,
        }),
        [borderRadius],
    );
    const containerStyle = { flexGrow: 1 };

    const carouselSpringConfig = useMemo(
        () => ({
            // the animation speed when pressing next or swiping should
            // be faster than autoplay animation speed
            duration: transitionReason === "swipe" ? "0.3s" : "0.8s",
            easeFunction: "cubic-bezier(0, 0.35, 0.6, 1)",
            delay: "0s",
        }),
        [transitionReason],
    );

    const handleChangeIndex = (
        index: number,
        _?: number,
        // metadata from react-swipeable-views with a reason why a slide transitioned
        meta?: { reason: "swipe" | "focus" },
    ) => {
        setIndex(index);
        setTransitionReason(meta?.reason);
    };

    const childrenHeights = isMobileView
        ? block.children.map(c => c.properties?.height_mobile || 0)
        : block.children.map(c => c.properties?.height_desktop || 0);
    const maxChildHeight = Math.max(...childrenHeights, 0);

    const blockSlide = ({ index }: { index: number }) => {
        const block = children[index];

        return (
            <Block
                key={block?.id}
                block={block}
                fromCarousel
                maxChildHeight={maxChildHeight}
            />
        );
    };

    return (
        <div className="h-full flex flex-col" ref={carouselRef}>
            <EnhancedSwipeableViews
                index={index}
                onChangeIndex={handleChangeIndex}
                autoplay={shouldAutoplay && inView && !isScrolling}
                interval={autoplaySpeed}
                slideCount={children.length}
                style={slideContainerStyle}
                containerStyle={containerStyle}
                slideClassName={clsx("flex disable-scrollbars", {
                    "theme-tertiary-background": isEmpty(block.children),
                })}
                slideRenderer={blockSlide}
                springConfig={carouselSpringConfig}
                // how many images to have prerendered in either direction
                overscanSlideAfter={5}
                overscanSlideBefore={5}
                // hysteresis determines how far should the user swipe to switch slides, default is 0.6
                hysteresis={1}
            />
            <div
                // pointer-events-auto allows carousel controls to be used in both edit/non-edit mode
                className={`flex items-center justify-between pointer-events-auto h-[${CAROUSEL_CONTROLS_HEIGHT}px]`}
                onPointerDownCapture={e => e.stopPropagation()}
            >
                <Arrow
                    onClick={handlePrevPress}
                    icon={"arrow_left"}
                    color={theme.primary}
                />
                <Dots
                    length={children.length}
                    activeIndex={index}
                    setIndex={setIndex}
                />
                <Arrow
                    onClick={handleNextPress}
                    icon={"arrow_right"}
                    color={theme.primary}
                />
            </div>
        </div>
    );
}
