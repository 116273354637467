import WebsiteEmbed from "./WebsiteEmbed";
import YouTubeEmbed from "./YouTubeEmbed";
import { BlockBackgroundStyle, HydratedBlock, RichEmbedType } from "types";
import validateURL from "utilities/validateURL";
import { useTheme } from "hooks/useTheme";
import _ from "lodash";
import { IconsMap } from "components/ui/Icon";
import TiktokEmbed from "./TiktokEmbed";
import useBreakpoints from "hooks/useBreakpoints";
import { useBlockHeight } from "hooks/useBlockHeight";

const matchVideoId = (url: string) => {
    const match = /(watch\?v=)[^"&?\/\s]*/.exec(url);
    if (match) {
        return match[0].replace("watch?v=", "");
    }

    const matchShortened = /(youtu\.be\/)[^"&?\/\s]*/.exec(url);
    if (matchShortened) {
        return matchShortened[0].replace("youtu.be/", "");
    }

    return "";
};

interface Props {
    block: HydratedBlock;
}

const EmbedBlock = ({ block }: Props) => {
    const { properties } = block;

    const theme = useTheme();
    const { isMobileView } = useBreakpoints();
    const { height } = useBlockHeight();

    if (!properties?.embed_url || !validateURL(properties.embed_url)) {
        return (
            <div
                className="w-full flex justify-center items-center"
                style={{
                    height,
                }}
            >
                <IconsMap.code size={48} className="theme-secondary-text" />
            </div>
        );
    }

    if (
        properties?.rich_embed &&
        properties?.rich_embed_type === RichEmbedType.YouTube
    ) {
        return <YouTubeEmbed videoId={matchVideoId(properties?.embed_url)} />;
    }

    if (
        properties?.rich_embed &&
        properties?.rich_embed_type === RichEmbedType.TikTok
    ) {
        return <TiktokEmbed url={properties?.embed_url || ""} />;
    }

    return (
        <WebsiteEmbed
            height={
                (isMobileView
                    ? properties?.height_mobile
                    : properties?.height_desktop) || 0
            }
            url={properties.embed_url}
            title={properties?.embed_title}
            description={properties?.embed_description}
            imageUrl={properties?.embed_image_url}
            faviconUrl={properties?.embed_favicon_url}
            imageColor={properties?.embed_image_color}
            isSubtleBackground={
                properties?.background_style === BlockBackgroundStyle.subtle ||
                properties?.background_style === BlockBackgroundStyle.default ||
                _.isNil(properties?.background_style)
            }
            blockRadius={theme.block_radius}
        />
    );
};

export default EmbedBlock;
