import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import { selectUsername } from "store/profileSlice";
import { useCallback } from "react";

export const useTabPath = () => {
    const router = useRouter();
    const profileUsername = useSelector(selectUsername);
    const isAdmin = router.pathname.slice(0, 6) === "/admin";
    const adminPath = isAdmin ? "/admin" : "";

    const getTabPath = useCallback(
        (page?: string) => {
            const adminProfilePath = `${adminPath}/${profileUsername}`;

            if (!page) {
                return `${adminProfilePath}`;
            }

            const tabPath = `${adminPath}/${profileUsername}/${page}`;
            return tabPath;
        },
        [adminPath, profileUsername],
    );

    return getTabPath;
};
