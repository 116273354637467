import { HeaderText } from "components/profile/BlockEditor/properties";
import { useUITheme } from "hooks/useUITheme";
import { ButtonMode } from "types";
import UIButton from "components/ui/UIButton";

interface Props {
    headerText: string;
    isOpen: boolean;
    handleClose: () => Promise<void>;
    handleGetBack: () => Promise<void>;
    handleSave: () => Promise<void>;
    fetching: boolean;
}

const BlockEditorHeader = ({
    headerText,
    isOpen,
    handleClose,
    handleGetBack,
    handleSave,
    fetching,
}: Props) => {
    const theme = useUITheme(true);
    const shouldShowCloseAndSaveBtn =
        headerText === HeaderText.Headers.EditBlock;
    if (!isOpen) return null;

    return (
        <div
            className="flex space-between w-full sticky top-0 left-0 right-0 z-10 border-b"
            style={{
                backgroundColor: theme.background_color,
            }}
        >
            <UIButton
                mode={ButtonMode.ghost}
                onClick={
                    !shouldShowCloseAndSaveBtn ? handleGetBack : handleClose
                }
                icon={
                    !shouldShowCloseAndSaveBtn
                        ? "circle_left_arrow"
                        : "circle_close"
                }
                grow={false}
                borderRadius="8px"
                className="m-1 !p-2"
                allowDarkMode
            />
            <div
                className="mx-auto self-center font-semibold"
                style={{
                    color: theme.primary,
                }}
            >
                {headerText}
            </div>
            <UIButton
                mode={ButtonMode.ghost}
                onClick={handleSave}
                icon="circle_check_fill"
                grow={false}
                borderRadius="8px"
                className="m-1 !p-2"
                allowDarkMode
                fetching={fetching}
            />
        </div>
    );
};

export default BlockEditorHeader;
