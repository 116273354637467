const ReactQuill =
    typeof window === "object" ? require("react-quill") : () => false;
import { CSSProperties } from "react";
import "react-quill/dist/quill.snow.css";

type Props = {
    onValueChange: (value: string) => void;
    value: string;
    className?: string;
    style?: CSSProperties;
};

export default function TextEditor({
    value,
    onValueChange,
    className,
    style,
}: Props) {
    return (
        <div className={className} style={{ height: "55vh", ...style }}>
            <ReactQuill
                style={{ height: "50vh" }}
                theme="snow"
                value={value}
                onChange={onValueChange}
            />
        </div>
    );
}
