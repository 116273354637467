import Image from "next/image";

import { HydratedBlock, Media } from "types";

export const OptimizedImage = ({
    block,
    media,
}: {
    block: HydratedBlock;
    media: Media;
}) => {
    const objectFit =
        block.properties?.object_fit === "contain" ? "contain" : "cover";

    return (
        <Image
            className="w-full h-full"
            src={media.url}
            alt=""
            fill // fill because we don't know the width and height
            priority // priority because we don't want to lazy load
            sizes="(max-width: 720px) 95vw, 60vw"
            style={{
                filter: `brightness(${block.properties?.brightness}%)`,
                WebkitFilter: `brightness(${block.properties?.brightness}%)`,
                borderRadius: "inherit",
                objectFit,
                backgroundColor: block.properties?.media_background_color
                    ? block.properties?.media_background_color
                    : media.color,
            }}
        />
    );
};
