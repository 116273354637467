export const fireworks = {
    preset: "fireworks",
    background: {
        color: "rgba(0, 0, 0, 0)",
        opacity: 0,
    },
};

export const generateConfetti = (image?: string) => {
    return {
        fullScreen: {
            zIndex: 1,
        },
        emitters: {
            life: {
                count: 4,
                duration: 0.1,
                delay: 0.1,
            },
            position: {
                x: 50,
                y: 100,
            },
            rate: {
                quantity: 60,
                delay: 0.1,
            },
        },
        particles: {
            color: {
                value: ["#1E00FF", "#FF0061", "#E1FF00", "#00FF9E"],
            },
            move: {
                decay: 0.05,
                direction: "top",
                enable: true,
                gravity: {
                    enable: true,
                },
                outModes: {
                    top: "none",
                    default: "destroy",
                },
                speed: {
                    min: 50,
                    max: 100,
                },
            },
            number: {
                value: 0,
            },
            opacity: {
                value: 1,
            },
            rotate: {
                value: {
                    min: 0,
                    max: 360,
                },
                direction: "random",
                animation: {
                    enable: true,
                    speed: 30,
                },
            },
            tilt: {
                direction: "random",
                enable: true,
                value: {
                    min: 0,
                    max: 360,
                },
                animation: {
                    enable: true,
                    speed: 30,
                },
            },
            size: {
                value: 4,
                animation: {
                    enable: true,
                    startValue: "min",
                    count: 1,
                    speed: 16,
                    sync: true,
                },
            },
            roll: {
                darken: {
                    enable: true,
                    value: 25,
                },
                enlighten: {
                    enable: true,
                    value: 25,
                },
                enable: true,
                speed: {
                    min: 5,
                    max: 15,
                },
            },
            wobble: {
                distance: 30,
                enable: true,
                speed: {
                    min: -7,
                    max: 7,
                },
            },
            shape: {
                type: [
                    "circle",
                    "square",
                    "triangle",
                    "polygon",
                    "character",
                    image ? "image" : "",
                ],
                options: {
                    polygon: [
                        {
                            sides: 5,
                        },
                        {
                            sides: 6,
                        },
                    ],
                    character: {
                        fill: true,
                        font: "Verdana",
                        style: "",
                        weight: 400,
                        particles: {
                            size: {
                                value: 8,
                            },
                        },
                        value: ["🥳", "🎉", "🎈", "💞", "🦄", "⭐️", "✨"],
                    },
                    image: image
                        ? [
                              {
                                  src: image
                                      ? image
                                      : "https://res.cloudinary.com/ddhetmqtt/image/upload/v1677698224/logo_round_2_yn3e83.png",
                                  width: 32,
                                  height: 32,
                                  particles: {
                                      size: {
                                          value: 16,
                                      },
                                  },
                              },
                          ]
                        : [],
                },
            },
        },
        responsive: [
            {
                maxWidth: 1024,
                options: {
                    particles: {
                        move: {
                            speed: {
                                min: 33,
                                max: 66,
                            },
                        },
                    },
                },
            },
        ],
    };
};
