import { Dispatch, SetStateAction } from "react";

import { HydratedBlock } from "types";

export function ClickOverlay({
    activeIndex,
    setActiveIndex,
    length,
}: {
    activeIndex: number;
    setActiveIndex: Dispatch<SetStateAction<number>>;
    length: number;
}) {
    if (length <= 1) {
        return null;
    }

    return (
        // Added z-10 classname for z-index 10 to bring click overlay layer before the image layer.
        // Note that z-20 is applied to the buttons on the caption layer.
        // More on z-index doc: https://developer.mozilla.org/en-US/docs/Web/CSS/z-index
        <div className="absolute w-full h-full z-10 cursor-pointer">
            <div
                className="absolute inset-y-0 left-0 w-1/2 h-full z-10"
                onClick={() => {
                    activeIndex > 0
                        ? setActiveIndex(activeIndex - 1)
                        : setActiveIndex(length - 1);
                }}
            ></div>
            <div
                className="absolute inset-y-0 right-0 w-1/2 h-full z-10"
                onClick={() => {
                    activeIndex < length - 1
                        ? setActiveIndex(activeIndex + 1)
                        : setActiveIndex(0);
                }}
            ></div>
        </div>
    );
}
