import FormFieldRow from "components/profile/Block/FormBlock/FormFieldRow";
import Button from "components/ui/Button";
import { IconsMap } from "components/ui/Icon";
import Switch from "components/ui/Switch";
import { useUITheme } from "hooks/useUITheme";
import { ButtonMode, FormField } from "types";

interface Props {
    field: FormField;
    onDelete: () => void;
    onEdit: (newField: FormField) => void;
    onToggleRequired: (required: boolean) => void;
    provided: any;
}

const FormFieldEditorRow = ({
    field,
    onDelete,
    onEdit,
    onToggleRequired,
    provided,
}: Props) => {
    const theme = useUITheme();

    return (
        <div className="flex flex-row items-center flex-1 gap-4 cursor-default">
            <div
                className="flex flex-row gap-4 !cursor-auto"
                data-rbd-drag-handle-context-id={
                    provided.dragHandleProps?.[
                        "data-rbd-drag-handle-context-id"
                    ]
                }
            >
                <Button
                    icon="trash"
                    iconSize={16}
                    mode={ButtonMode.outline}
                    onClick={onDelete}
                    color={theme.primary}
                />
                <Button
                    icon="edit"
                    iconSize={16}
                    mode={ButtonMode.outline}
                    onClick={onEdit}
                    color={theme.primary}
                />
            </div>
            <div
                className="p-2 border rounded-xl grid grid-cols-4 justify-between items-center gap-4 flex-1 overflow-hidden !cursor-auto"
                style={{
                    backgroundColor: theme.background_color,
                    borderColor: theme.tertiary,
                }}
                data-rbd-drag-handle-context-id={
                    provided.dragHandleProps?.[
                        "data-rbd-drag-handle-context-id"
                    ]
                }
            >
                <FormFieldRow field={field} isUI preview />
            </div>
            <div className="flex flex-row gap-4">
                <p>Required: </p>
                <Switch
                    checked={field.required}
                    onCheckChange={onToggleRequired}
                />
            </div>
            <IconsMap.drag size={24} className="cursor-grab" />
        </div>
    );
};

export default FormFieldEditorRow;
