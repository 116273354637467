import { Cloudinary } from "@cloudinary/url-gen";
import { CloudinaryImage as RawCloudImage } from "@cloudinary/url-gen/assets/CloudinaryImage";
import { fill } from "@cloudinary/url-gen/actions/resize";
import { scale } from "@cloudinary/url-gen/actions/resize";
import { crop } from "@cloudinary/url-gen/actions/resize";
import { Quality } from "@cloudinary/url-gen/qualifiers/quality";

import RoundCornersAction from "@cloudinary/transformation-builder-sdk/actions/roundCorners/RoundCornersAction";

const cloud = new Cloudinary({
    cloud: {
        cloudName: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
    },
});

const urlKey = `/${process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME}/image/upload/`;

export const isCloudinaryImage = (url: string): boolean => {
    return url.indexOf(urlKey) !== -1;
};

// Takes a full URL to the Cloudinary source image and returns a CloudinaryImage object
// To transform the image use the stupid Cloudinary chaining API:
// https://cloudinary.com/documentation/react_integration
// https://cloudinary.com/documentation/react_image_transformations#chaining_transformations
export const CloudinaryImage = (url: string): RawCloudImage => {
    if (!isCloudinaryImage(url)) {
        throw new Error("Not a Cloudinary URL");
    }
    const cloudinaryUrlStartLocation = url.indexOf(urlKey);
    const cloudinaryUniqueIdStartLocation =
        cloudinaryUrlStartLocation + urlKey.length;
    const publicId = url.substring(cloudinaryUniqueIdStartLocation);
    return cloud.image(publicId);
};

export const resize = {
    fill,
    scale,
    crop,
};

export const actions = {
    roundCorners: new RoundCornersAction(),
};

export const qualifiers = {
    quality: Quality,
};

export const autoQualityImage = (url: string): string => {
    if (!isCloudinaryImage(url)) return url;

    return CloudinaryImage(url).quality(qualifiers.quality.auto()).toURL();
};
