import { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import {
    useCustomCompareCallback,
    useCustomCompareEffect,
} from "use-custom-compare";
import { shallowEqual } from "fast-equals";
import usePrevious from "./usePrevious";
import { HydratedBlock } from "types";
import { selectBlockTree, selectProfileId } from "store/profileSlice";

type BlockReorderReturn = {
    move: (newOrder: HydratedBlock[]) => void;
    localPagesState?: HydratedBlock[];
    pageIds?: string[];
    pageInitialIndex: number;
    setPageInitialIndex: Dispatch<SetStateAction<number>>;
};

export default function usePageReorder(): BlockReorderReturn {
    const tree = useSelector(selectBlockTree, shallowEqual);
    const pid = useSelector(selectProfileId);

    const [pageInitialIndex, setPageInitialIndex] = useState(-1);
    const [localPagesState, setLocalPagesState] = useState(tree);
    const pageIds = localPagesState?.map(p => p.id);
    const previousLocalPages = usePrevious(localPagesState);

    useCustomCompareEffect(
        () => {
            // prevent this hook + reorder animations from re-running after a reorder
            if (shallowEqual(tree, previousLocalPages)) return;

            // sync any changes to tree (e.g. resizing, creating, deleting, etc.) with local pages state
            setLocalPagesState(tree);
        },
        [tree, previousLocalPages],
        (prev, next) => shallowEqual(prev, next),
    );

    const move = useCustomCompareCallback(
        (newOrder: HydratedBlock[]) => {
            if (shallowEqual(tree, newOrder)) return;

            // sync pages UI locally first
            setLocalPagesState(newOrder);

            const page = tree?.[pageInitialIndex];
            const pageTargetIndex = newOrder.findIndex(b => b.id === page?.id);

            // then make request to update server
            void fetch(`/api/v0.1/profiles/${pid}/blocks/${page?.id}/move`, {
                method: "POST",
                headers: { "content-type": "application/json" },
                body: JSON.stringify({
                    targetParentId: null,
                    targetIndex: pageTargetIndex,
                }),
            });
        },
        [pid, pageInitialIndex, tree],
        (prev, next) => shallowEqual(prev, next),
    );

    return {
        move,
        localPagesState,
        pageIds,
        pageInitialIndex,
        setPageInitialIndex,
    };
}
