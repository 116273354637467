import { HydratedBlock } from "types";
import { Caption } from "components/profile/Block/MediaBlock/Caption";
import { useStories } from "./useStories";
import { StoryBars } from "./StoryBars";
import { Content } from "./Content";
import { ClickOverlay } from "./ClickOverlay";

type Props = {
    block: HydratedBlock;
};

export default function MediaBlock(props: Props) {
    const { block } = props;
    const properties = block.properties;

    const media_list = properties?.media_list || [];
    const { activeIndex, setActiveIndex, slideStartTime, duration } =
        useStories(media_list);

    const media = media_list[activeIndex];

    return (
        <>
            <ClickOverlay
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                length={media_list.length}
            />
            <Content media={media} block={block} />
            <Caption block={block} />
            <StoryBars
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                slideStartTime={slideStartTime}
                length={media_list.length}
                duration={duration}
            />
        </>
    );
}
