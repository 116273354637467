import { useTheme } from "hooks/useTheme";
import { TikTokEmbed } from "react-social-media-embed";

interface Props {
    url: string;
}

const TiktokEmbed = ({ url }: Props) => {
    const theme = useTheme();

    return (
        <div className="flex flex-col items-center p-4 theme-tertiary-background">
            <TikTokEmbed url={url} />
        </div>
    );
};

export default TiktokEmbed;
