import { useDevice } from "hooks/useDevice";
import useEditMode from "hooks/useEditMode";
import { useSelector } from "react-redux";
import { selectBlockEditor } from "store/blockEditor";
import { selectProfile } from "store/profileSlice";
import { selectNewSettings } from "store/settingsEditor";
import { BioStyle, BlockType, HydratedBlock } from "types";
import StyleFive from "./StyleFive";
import StyleFour from "./StyleFour";
import StyleOne from "./StyleOne";
import StyleThree from "./StyleThree";
import StyleTwo from "./StyleTwo";
import { BioData } from "./types";

type Props = {
    block: HydratedBlock;
};

export default function BioBlock({ block }: Props) {
    const { properties } = block;
    const bioStyle = properties?.bio_style || BioStyle.style_1;
    const hideBio = !!properties?.hide_bio;
    const hideName = !!properties?.hide_name;
    const hideCover = !!properties?.hide_cover;
    const hideLogo = !!properties?.hide_logo;
    const { mobilePreviewMode } = useEditMode();
    const { isMobileDevice } = useDevice();
    const mobileView = mobilePreviewMode || isMobileDevice;
    const profileData = useSelector(selectProfile);
    const editingBlock = useSelector(selectBlockEditor);
    const newSettings = useSelector(selectNewSettings);
    // Preview that shows in the block editor while editing the bio block
    const isEditPreview = editingBlock && editingBlock.type === BlockType.bio;

    // @ts-ignore
    let data: BioData | null = isEditPreview ? newSettings : profileData;
    // const useCustomFields = !!properties?.bio_use_custom_fields;
    const useCustomFields = true;

    if (!data) return null;

    if (useCustomFields) {
        data = {
            ...data,
            name: properties?.bio_custom_title || "",
            description: properties?.bio_custom_description || "",
            cover_url: properties?.bio_custom_cover_url || "",
            profile_url: properties?.bio_custom_profile_url || "",
        };
    }
    switch (bioStyle) {
        case BioStyle.style_1:
            return (
                <StyleOne
                    hideName={hideName || !data.name}
                    hideBio={hideBio || !data.description}
                    hideCover={hideCover}
                    hideLogo={hideLogo}
                    mobileView={mobileView}
                    data={data}
                />
            );
        case BioStyle.style_2:
            return (
                <StyleTwo
                    hideName={hideName || !data.name}
                    hideBio={hideBio || !data.description}
                    hideCover={hideCover}
                    hideLogo={hideLogo}
                    mobileView={mobileView}
                    data={data}
                />
            );
        case BioStyle.style_3:
            return (
                <StyleThree
                    hideName={hideName || !data.name}
                    hideBio={hideBio || !data.description}
                    hideCover={hideCover}
                    hideLogo={hideLogo}
                    mobileView={mobileView}
                    data={data}
                />
            );
        case BioStyle.style_4:
            return (
                <StyleFive
                    hideName={hideName || !data.name}
                    hideBio={hideBio || !data.description}
                    hideCover={hideCover}
                    hideLogo={hideLogo}
                    data={data}
                />
            );
        default:
            return (
                <StyleOne
                    hideName={hideName || !data.name}
                    hideBio={hideBio || !data.description}
                    hideCover={hideCover}
                    hideLogo={hideLogo}
                    mobileView={mobileView}
                    data={data}
                />
            );
    }
}
