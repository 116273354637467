import clsx from "clsx";
import { HeaderText } from "components/profile/BlockEditor/properties";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useUITheme } from "hooks/useUITheme";
import { useRef } from "react";
import BlockEditorHeader from "./BlockEditorHeader";
import { PropertyRows } from "./PropertyRows";
import { PropertyTabs } from "./PropertyTabs";

interface DesktopEditorProps {
    headerText: string;
    isOpen: boolean;
    handleClose: () => Promise<void>;
    handleGetBack: () => Promise<void>;
    handleSave: () => Promise<void>;
    fetching: boolean;
}

const DesktopBlockEditor = ({
    headerText,
    isOpen,
    handleClose,
    handleGetBack,
    handleSave,
    fetching,
}: DesktopEditorProps) => {
    const theme = useUITheme(true);
    const ref = useRef<HTMLDivElement | null>(null);
    useOnClickOutside(ref, handleClose);

    if (!isOpen) return null;

    return (
        <div
            ref={ref}
            className={clsx(
                "fixed bottom-[78px] right-0 shadow-lg outline-none rounded-[12px] mr-4 w-[344px] h-[400px] will-change-[transform,opacity] overflow-y-scroll disable-scrollbars font-sans",
                {
                    "animate-slideUpAndFade": isOpen,
                },
            )}
            style={{
                background: theme.background_color,
                borderColor: theme.border_color,
            }}
        >
            <BlockEditorHeader
                headerText={headerText}
                isOpen={isOpen}
                handleClose={handleClose}
                handleGetBack={handleGetBack}
                handleSave={handleSave}
                fetching={fetching}
            />
            <div
                // cannot do items-center normal otherwise carousel gets messed up
                className="justify-around flex flex-col"
            >
                <div className="w-full">
                    <PropertyTabs />
                </div>
            </div>
            <PropertyRows />
        </div>
    );
};

export default DesktopBlockEditor;
